import firebase from 'firebase/app';
import 'firebase/database';
import HConfig from '../helpers/Config';

// Initalize and export Firebase.
// const config = {V1
//   apiKey: "AIzaSyBVPTKIpIf2cltPJ6EC5lkfZzGjMLHyNyQ",
//   authDomain: "solid-6804c.firebaseapp.com",
//   databaseURL: "https://solid-6804c.firebaseio.com",
//   projectId: "solid-6804c",
//   storageBucket: "solid-6804c.appspot.com",
//   messagingSenderId: "177003207212"
// };

const config = {
  apiKey: "AIzaSyDpYW61-DYApKTY54GVqCEd6P_C9NjmlU0",
  authDomain: "solidv2-4f492.firebaseapp.com",
  databaseURL: "https://solidv2-4f492-default-rtdb.firebaseio.com",
  projectId: "solidv2-4f492",
  storageBucket: "solidv2-4f492.appspot.com",
  messagingSenderId: "525127300451",
  appId: "1:525127300451:web:d475f543a2e287d7400f56",
  measurementId: "G-027BWNTZSY"
};

const F = firebase.initializeApp(config);

const FHelper = {
  logError(obj){
    FHelper.log('error',obj);
  },
  log(path,obj){
    try {
      var objString = JSON.stringify(obj);
      var objOpimize = {};
      try {
        objOpimize = JSON.parse(objString.replace(/:null/g,":\"null\""));  
      } catch (error) {
        console.warn('FHelper log error 1:',error,obj);
        objOpimize = objString;
      }
            
      var _d = new Date();
      let _pathLog = path || 'custom';
      if(HConfig.fnList.getIsDevMode()){
        _pathLog = 'dev_' +_pathLog;
      }
      let _nameUser = HConfig.fnList.getCurrentUserName() || 'no_user';
      let _currentDate = _d.toJSON().slice(0,10).replace(/-/g,'_');
      let _currentHour = _d.toJSON().slice(11,19).replace(/:/g,'_')+'__'+_d.getTime();
      let _path = `log/${FHelper.getHostName()}/${_pathLog}/${_nameUser}/` + _currentDate + '/' + _currentHour;
      F.database().ref(_path).set({
        data: objOpimize,
        url: window.location.href,
      }).catch((error)=>{
        console.error("Error firebase:",error)
      });
    } catch (error) {          
      console.warn('FHelper log error 2:',error,obj);
    }
  },
  logToken(email,token){
    try {
      let _path = 'log/tokens/' + email;
      F.database().ref(_path).set({
        data: Math.random().toString(36).substr(2, 5) + token,
        time: new Date().toISOString(),
        name: HConfig.fnList.getCurrentUserName(),
      }).catch((error)=>{
        console.error("Error firebase:",error)
      });
    } catch (error) {
      console.warn('FHelper logToken error:',error);
    }
  },
  getHostName(){
    let _hostName = window.location.hostname.replace(/[^\w\s]/gi, '') || 'nohost';
    return _hostName;
  },
  logPage({child,firstPath}={}){
    try {  
      let _hashName = window.location.hash.replace(/\//g, '-sss-');
      _hashName = _hashName.replace(/\?.*/g,'');
      _hashName = _hashName.replace(/[^\w\s\-]/gi, '');
      let _path = `${firstPath!=null?firstPath:'logPage'}/${FHelper.getHostName()}/${_hashName}`;
      if(_path.indexOf('data-table-setting-single')>-1){
        _path = _path.replace('data-table-setting-single','data-table-setting-single/');
      }
      else if(_hashName.lastIndexOf('-sss-')>5){
        _path = _path.substr(0,_path.lastIndexOf('-sss-'));
      }
      // else if(_path.indexOf('project-project-pipeline')>-1){
      //   _path = _path.replace(/project-project-pipeline.*/g,'-project-project-pipeline');
      // }
      // else if(_path.indexOf('project-project-maintenance')>-1){
      //   _path = _path.replace(/project-project-maintenance.*/g,'-project-project-maintenance');
      // }
      // else if(_path.indexOf('project-site-daily-s')>-1){
      //   _path = _path.replace(/project-site-daily-s.*/g,'-project-site-daily-s');
      // }
      // else if(_path.indexOf('staff-evaluation-staff')>-1){
      //   _path = _path.replace(/staff-evaluation-staff.*/g,'-staff-evaluation-staff');
      // }
      // else if(_path.indexOf('time-tracking-detail-4-one-staff')>-1){
      //   _path = _path.replace(/staff-evaluation-staff.*/g,'-time-tracking-detail-4-one-staff');
      // }
      if(child){
        _path = _path+`/${child}`;
      }
      F.database().ref(_path).once('value').then(function(snapshot){
        let _count = 1;
        if (snapshot.exists()) {
          // console.warn(snapshot.val());
          _count = snapshot.val().count +1;
        }
        else {
          // console.log("No data available");
        }
        F.database().ref(_path).set({
          count: _count,
          lasttime: new Date().toISOString(),
        });
      }).catch((error)=>{
        console.error("Error firebase:",error)
      });      
    } catch (error) {
      console.warn('FHelper logPage error:',error);
    }
  },
  clear(path){
    try {
      F.database().ref(path).remove((v)=>{
        console.warn("clear:",path,v);
      }).catch((error)=>{
        console.error("Error firebase:",error)
      });
    } catch (error) {
      console.warn('FHelper clear error:',error);
    }
  },  
}

window._FHelper = FHelper;
export {
  F,
  FHelper,
}

/**
 * 
-all-configs
-config-permission-all
-config-projectsetting
-config-staff
-config-wpg
-crm-crm-opty-v2
-data-table-setting-single
-evm-approve-status_v2
-home-page
-operation
-plan_week_pm
-plan_week_v2
-project-evm-v1-1-PLATINUM20GLOBAL203F
-project-p-kpi-v2-A1-1-SAMSUNG20TANG2025
-project-project-maintenance-PLO208F
-project-project-pipeline-AZ HCM 18F
-project-requirement-docs-SWANPARK-20MARKETING
-project-site-daily
-project-site-daily-s
-project-site_photos
-project-site_photos-PUBLIC20BANK20BAU20CAT
-report-plantask-monthly_byproject
-report-plantask-monthly_bystaff
-report-portfolio-maintenance
-report-weekly-issue-by-project
-solid-crm-r
-solid-standards-dashboard
-staff-evaluation-staff_v2-09fc79cc-d9bc-4447-9fe9-6972e1ae3994
-stafflist-evaluationR
-system-warning-message
-time-tracking-detail-4-one-staff-Bao20Thien20gs
-timesheet-report-all
-welcome
 */