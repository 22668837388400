import { MyPageHelpers } from "@macashipo/mlib"
//khac voi source mobile
import React from 'react'
const PageWithUI = React.lazy(() => import('./Types/WithMyUI'));
const PageWithMyCard = React.lazy(() => import('./Types/WithMyCard'));
const PageWithMyLayout = React.lazy(() => import('./Types/WithMyLayout'));
const PageTable_ListCard = React.lazy(() => import('./Types/Table_ListCard'));
const PageTable_GridCard = React.lazy(() => import('./Types/Table_GridCard'));
// const PageSolid_SelectProject = React.lazy(() => import('./TypesSolid/Solid_SelectProject'));
// const PageTable_Category = React.lazy(() => import('./Types/Table_Category'));
// const PageMyDashboard = React.lazy(() => import('./Types/MyDashboard'));
// const PageTable_Block = React.lazy(() => import('./Types/Table_Block'));
// const PageTable_List = React.lazy(() => import('./Types/Table_List'));
// const PageTable_List_V2 = React.lazy(() => import('./Types/Table_List_v2'));
// const PageTable_Block_V2 = React.lazy(() => import('./Types/Table_Block_v2'));
// const PageSolidV3 = React.lazy(() => import('./TypesSolid/Solid_V3/index'));
// const PageBookingCalendar = React.lazy(() => import('./Types/BookingCalendar'));

const List = {
  with_myui:PageWithUI ,
  with_mycard: PageWithMyCard ,
  with_mylayout: PageWithMyLayout ,
  table_listcard:PageTable_ListCard ,
  table_gridcard:PageTable_GridCard ,    
  // table_category:PageTable_Category ,
  // mydashboard:PageMyDashboard ,
  // table_block:PageTable_Block ,
  // table_list:PageTable_List ,
  // table_list_v2:PageTable_List_V2 ,
  // table_block_v2:PageTable_Block_V2 ,
  // solid_selectproject:PageSolid_SelectProject ,
  // solid_selectproject:PageSolid_SelectProject ,
  // solid_v3:PageSolidV3 ,
  // booking_calendar:PageBookingCalendar ,
}

MyPageHelpers.addTypesFromListLazy(List);
export default MyPageHelpers;