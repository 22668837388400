import React, { Component } from 'react';
class MyLoading extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  componentDidMount(){
  }

  render() {
    // console.warn('render MyLoading:',this.props);
    return (
      <div>...</div>
    )
  }
}

export default MyLoading;
