const PushNotifyChrome = {
  HConfig: null,
  permission: null,
  subscription: null,
  init(HConfig,{publicKey,fileWorker}={}){
    PushNotifyChrome.HConfig = HConfig;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register(fileWorker || 'service-worker.js');
      navigator.serviceWorker.ready
      .then(function(registration) {
        // Use the PushManager to get the user's subscription to the push service.
        return registration.pushManager.getSubscription()
        .then(async function(subscription) {
          // If a subscription was found, return it.
          console.warn("subscription:",subscription);
          if (subscription) {
            PushNotifyChrome.subscription = subscription;
            return subscription;
          }
          // Get the server's public key
          const vapidPublicKey = publicKey;
          const convertedVapidKey = PushNotifyChrome.urlBase64ToUint8Array(vapidPublicKey);

          // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
          // send notifications that don't have a visible effect for the user).
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey
          });
        });
      }).then(function(subscription) {
          // Send the subscription details to the server using the Fetch API.
        console.warn("then subscription:",subscription);   
        PushNotifyChrome.subscription = subscription;   
        //register subscription      
      }).catch((error)=>{
        console.warn("catch subscription:",error);  
      });
    }    
  },
  urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
   
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
   
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },
  askPermission() {
    return new Promise(function(resolve, reject) {
      const permissionResult = Notification.requestPermission(function(result) {
        resolve(result);
      });
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    })
    .then(function(permissionResult) {
      if (permissionResult !== 'granted') {
        console.warn('We weren\'t granted permission.');
      }
    });
  }
}
export default PushNotifyChrome;