window.showMyTooltip = function(e){
    // console.warn("e:",e);
    let _titleTooltip = e.getAttribute("data-tooltip");
    let _rect = e.getBoundingClientRect()
    window.eee = e;
    // alert('z');
    let _eTooltip = document.getElementById('m-tooltip-by-fn');
    if(_eTooltip){
      _eTooltip.innerText = _titleTooltip;
      let _top = _rect.y-2*_rect.height + document.documentElement.scrollTop;
      _eTooltip.parentNode.parentNode.style = `left: ${_rect.x}px; top: ${_top}px;z-index: 9999;`;
    }
    else{
      let _top = _rect.y-2*_rect.height + document.documentElement.scrollTop;
      var node = document.createElement("div");
      var node2 = document.createElement("div");
      var node3 = document.createElement("div");node3.className="m-tooltip m-tooltip-placement-top";node3.style=`left: ${_rect.x}px; top: ${_top}px;z-index: 9999;`;
      var node4 = document.createElement("div");node4.className="m-tooltip-content";
      var node5a = document.createElement("div");node5a.className="m-tooltip-arrow";
      var node5b = document.createElement("div");node5b.className="m-tooltip-inner";node5b.innerText=_titleTooltip;node5b.id="m-tooltip-by-fn";
      node4.appendChild(node5a);node4.appendChild(node5b);
      node3.appendChild(node4);
      node2.appendChild(node3);
      node.appendChild(node2);
      document.body.appendChild(node);
    }      
    // console.warn("show:",_eTooltip);
    window._justShowMyTooltip = true;
    MyToolTip.init();
}

const MyToolTip={
    initedEvent: false,
    init(){        
        if(MyToolTip.initedEvent===false){
            // Detect all clicks on the document
            document.addEventListener("click", function(event) {
                // If user clicks inside the element, do nothing
                if (event.target.closest("#m-tooltip-by-fn")) return;
                // If user clicks outside the element, hide it!
                // box.classList.add("js-is-hidden");            
                if(window._justShowMyTooltip===false){
                    let _eTooltip = document.getElementById('m-tooltip-by-fn');
                    // console.warn("_eTooltip:",_eTooltip);
                    if(_eTooltip){
                        _eTooltip.parentNode.parentNode.style.display = 'none';
                    } 
                }
                if(window._justShowMyTooltip){
                    window._justShowMyTooltip = false;
                }                       
            });
        }        
        MyToolTip.initedEvent = true;
    }
}
export default MyToolTip;