import React from 'react'
import MyUITypes from './MyUITypes';
import { MyUIHelpers } from "@macashipo/mlib"
import MyUI from './MyUI';
import ErrorBoundary from '../../components2/MyCore/ErrorBoundary';
const MyUIAppHelpers = {
  addType: MyUIHelpers.addTypes,
  getContents(props){
    if(props){
      return props.Contents;
    }
  },
  getStyle(props){
    if(props){
      return props.Style;
    }
  },
  addListUI(listObj){
    // console.warn("addListUI:",listObj);
    if(listObj){
      let _keys = Object.keys(listObj);
      for(let k of _keys){
        if(MyUIHelpers.Types[k]==null){
          MyUIHelpers.addTypes(k,(props)=>{
            // console.warn("addListUI addType:",k,props);
            return (
              <ErrorBoundary configObj={listObj[k]}>
                <MyUI {...props} type="o" configObj={listObj[k]}/>
              </ErrorBoundary>
            )            
          })
        }
        else{
          console.warn("addListUI exist key:",k);
        }
        
      }
    }
  },
}

export default MyUIAppHelpers;