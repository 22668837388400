import React from 'react'
class MyUI_Tag extends React.Component {    
  render(){
    const {style,className,children,m} = this.props;
    const Tag = this.props.tag;
    return (
      <Tag style={style} className={className} {...m}>
        {children}
      </Tag>
    )
  } 
}

export default MyUI_Tag