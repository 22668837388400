import React from 'react';
const TheLayout = React.lazy(() => import('./layouts/index'));
const LoginPage = React.lazy(() => import('../../v1/views/Login/Login'));

const routes = [
  { path: '/login', exact: true, name: 'Login', component: LoginPage },
  { path: '/login/:id', exact: true, name: 'Login', component: LoginPage },
  { path: '/', exact: false, name: 'Blank', component: TheLayout },  
];

const routes_private = [
  // { path: '/welcome', exact: true, name: 'Welcome', component: V1_Welcome },//Test V1
  // { path: '/project/defect/:id', exact: true, name: 'ProjectDefect', component: V1_ProjectDefect },//Test V1
];


const routes_dev = [
  // ...RoutesDev
]

const routes_simulate = [
]

const routes_test = [
]

export {
  routes,
  routes_private,
  routes_dev,
  routes_simulate,
  routes_test
}
