import React from 'react';
// import { Popover } from '../../../mreact/core';
// import HH from '../../../helpers/H';
import { HText } from '../../../apps/Helpers';
import { Popover } from '../../../apps/Libs';
import InputControl from '../../../views/Components/InputEditor/CustomControl';

class CellType_ComponentAddable extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
  }

  _getFieldName=()=>{
    const {row,fieldName,configEditIcon} = this.props;
    if(configEditIcon && configEditIcon.fieldName){
      return configEditIcon.fieldName;
    }
    return fieldName;
  }

  _getType=()=>{
    const {configEditIcon} = this.props;
    if(configEditIcon && configEditIcon.type){
      return configEditIcon.type;
    }
    return "text";
  }

  _renderDefautForm=()=>{
    const {row} = this.props;
    let _fieldName = this._getFieldName();
    let _valueDefault = "";
    let _type = this._getType();
    if(row && _fieldName && row[_fieldName]){
      _valueDefault = row[_fieldName];
    }
    return (
      <div>
        <InputControl
          ref={r=>{this._cInputNew=r;}}
          type={_type} 
          value={_valueDefault}
        />
      </div>
    )
  }

  _renderForm=()=>{
    return this._renderDefautForm();
  }

  _onSubmit=(hideCallback)=>{
    const {onSubmit} = this.props;
    let _fieldName = this._getFieldName();
    if(this._cInputNew!=null){
      let _newValue = this._cInputNew.getValue();
      hideCallback && hideCallback();
      // let _data = {};
      // _data[fieldName] = _newValue;     
      onSubmit && onSubmit(_fieldName,_newValue);
    }
  }

  render() {
    const me = this;
    let _titlePopover = this.props.title || HText.get('btn_edit');
    return (
      <Popover placement="left" title={_titlePopover} onVisibleChange={(visible)=>{
        // console.log("visible",visible);
        if(visible){
          setTimeout(() => {
            if(me && me._cInputNew){
              me._cInputNew.focus();
            }
          }, 200);
        }        
      }} overlay={
        me._renderForm()
        } trigger="click" showButton okText={HText.get('btn_ok')} cancelText={HText.get('btn_close')} onOk={
          (hideCallback)=>{            
            me._onSubmit(hideCallback);
          }
        } onCancel={()=>{console.log('Cancel')}}>
        <div className="mct-status-edit" onClick={()=>{
        }}>
          <i className="fa fa-edit"/>
        </div>
      </Popover>
    )
  }
}

export default CellType_ComponentAddable;