import CellHelper from './App/CellHelper';
import MyTableHeader from '../components/MyTableHeader/MyTableHeader'
const TableHelper = {
  buildCols({component,extraData,fieldId="Id",fnRequestUpdate,fnRequestDelete,sourceList,fnList,options={}}){
    // console.log('buildCols:',extraData);
    let _cols = [];
    if(extraData!=null && extraData.Columns!=null){
      if(options.addExtraColumns!=null){
        for(let _key of Object.keys(options.addExtraColumns)){
          let _newCol = options.addExtraColumns[_key];
          extraData.Columns[_key] = _newCol;
          // if(_newCol.Index>=0){
          //   extraData.Columns.splice(_newCol.Index,0,_newCol);
          // }
        }
        // console.log('buildCols with addExtraColumns:',extraData);
      }
      if(options.customExtraColumns!=null){
        for(let e of Object.keys(options.customExtraColumns)){
          if(extraData.Columns[e]){
            let _type = extraData.Columns[e].Type;
            extraData.Columns[e] = Object.assign(extraData.Columns[e],options.customExtraColumns[e]);
            extraData.Columns[e].Type = Object.assign(extraData.Columns[e].Type,_type);
          }          
        }
        // console.log('customExtraColumns: ',extraData.Columns);
      }
      let _arrColumns = Object.keys(extraData.Columns);
      // console.log('buildCols:',extraData);
      for(let i=0;i<_arrColumns.length;i++){
        let _fieldName = _arrColumns[i];
        let _item = extraData.Columns[_fieldName];
        // console.log('col:',_fieldName,_item);
        let _col;
        if(_fieldName==fieldId){
          let _hidden = true;
          if(options.showID!=null && options.showID==true){
            _hidden = false;
          }
          if(_hidden==true){
            _col = {
              width:'40px', hidden: true, isKey:true, dataField: fieldId, header: 'ID',
            }
          }
          else{
            _col = {
              width:_item.Width || '40px', hidden: false, isKey:true, dataField: fieldId, header: _item.Header||_fieldName,
            }
          }
        }
        else{
          if(options.excludeFieldName!=null && options.excludeFieldName.indexOf(_fieldName)>-1){
            continue;
          }
          if(_item.CanShow==true){
            _col = {
              width: _item.Width||'100px', 
              dataField: _fieldName, 
              header: _item.Header||_fieldName,
              colProps: {},
            }

            if(options.columnClassName!=null){
              _col.columnClassName = options.columnClassName;
            }
            if(options.defaultFormat!=null){
              _col.dataFormat = options.defaultFormat;
            }
            else{
              _col.dataFormat = CellHelper.DataFormat.readonly;
            }

            /*
            Parse Json Header
            */
            
            if(_item.Header && _item.Header.startsWith('{')){
              try {
                _item._HeaderObj = JSON.parse(_item.Header);
                let _headerObj = _item._HeaderObj;
                if(_headerObj){
                  _col.header = _headerObj.title || _headerObj.header || '';
                  if(_headerObj.type){
                    _col.colProps.componentHeader = (header,fieldName)=>{
                      return (
                        <MyTableHeader type={_headerObj.type} header={header} fieldName={fieldName}
                          headerObj={_headerObj} extraData={extraData}
                        />
                      )
                    }
                  }
                  if(_headerObj.canSort!=null && typeof _headerObj.canSort == 'boolean'){
                    _col.colProps.dataSort = _headerObj.canSort;
                  }
                  if(_headerObj.canPrint===false){
                    _col.columnClassName = _col.columnClassName||'' + ' no-print-col';
                    _col.className = _col.className||'' + ' no-print-col';
                  }
                  if(_headerObj.Cls!=null){
                    _col.className = _col.className||'' + ' ' + _headerObj.Cls;
                  }
                  if(_headerObj.CCls!=null){
                    _col.columnClassName = _col.columnClassName||'' + ' ' + _headerObj.CCls;
                  }
                }
              } catch (error) {
                console.warn('parse Json Header error:',error)
              }
            }
            
            if(_item.CanSort!=null){
              _col.colProps.dataSort = _item.CanSort;
            }
            if(_item.CanPrint===false){
              _col.columnClassName = _col.columnClassName||'' + ' no-print-col';
              _col.className = _col.className||'' + ' no-print-col';
            }
            if(_item.Cls!=null){
              _col.className = _col.className||'' + ' ' + _item.Cls;
            }
            if(_item.CCls!=null){
              _col.columnClassName = _col.columnClassName||'' + ' ' + _item.CCls;
            }            

            //autowidth
            if(_item.MWAS && _item.MWASD){
              let _w = window.innerWidth>_item.MWAS?'100%':_item.MWASD+'px';
              _col.width = _w;
            }            
            
            if(_item.CanEdit==true || _item.CanEdit==false){
              if(options.customProps!=null && options.customProps['_Arr']!=null){
                let _regex = options.customProps['_Arr'].regex;
                let _test = _regex.test(_fieldName);
                if(_test==true){
                  _col.colProps = Object.assign(_col.colProps,options.customProps['_Arr'].props);
                }
              }
              if(options.customProps!=null && options.customProps[_fieldName]!=null){
                _col.colProps = Object.assign(_col.colProps,options.customProps[_fieldName]);
              }

              if(options.customType!=null && options.customType[_fieldName]!=null){
                _item.Type = Object.assign(_item.Type,options.customType[_fieldName]);
                console.log('_item.Type:',_item.Type,options.customType);
              }

              let _formatExtraData = {
                fieldName: _fieldName,
                fnRequestUpdate: fnRequestUpdate,
                fnRequestDelete: fnRequestDelete,
                extraData: extraData,
                sourceList: sourceList||{},
                fnList: fnList||{},
              }
              _formatExtraData = Object.assign(_formatExtraData,_item);
              _col.colProps.formatExtraData = _formatExtraData;

              if(_item.Type!=null){
                if(CellHelper.DataFormat[_item.Type.type]!=null){
                  _col.dataFormat = CellHelper.DataFormat[_item.Type.type];
                }
              }
            }
          }
        }
        // _col.colProps.formatExtraData = {
        // } 
        if(_col!=null){
          if(_item.Index!=null){
            if(_cols.length>_item.Index){
              _cols.splice(_item.Index,0,_col);
            }
            else{
              // _cols.unshift(_col);
              _cols.push(_col);
            } 
          }
          else{
            _cols.push(_col);
          }
        }
      }
      if(options.customAction!=null){
        options.customAction.colProps = {
          formatExtraData: {
            fieldName: '_ActionDelete',
            fnRequestUpdate: fnRequestUpdate,
            fnRequestDelete: fnRequestDelete,
            extraData: extraData,
            sourceList: sourceList||{},
            fnList: fnList||{},
          }
        }
        _cols.push(
          options.customAction
        )
      }
      else if(_cols.length>0 && options.canDelete==true){
        _cols.push(
          {
            width:'35px', dataSort: false, dataField:'_ActionDelete', header: '',
            dataFormat: CellHelper.DataFormat.delete,
            columnClassName: 'no-print-col',
            colProps: {
              formatExtraData: {
                fieldName: '_ActionDelete',
                fnRequestUpdate: fnRequestUpdate,
                fnRequestDelete: fnRequestDelete,
                extraData: extraData,
                sourceList: sourceList||{},
                fnList: fnList||{},
              }
            }
          },
        )
      }
    }
    return _cols;
  },
}

export default TableHelper;