import React from 'react';
import {
  Button,
  Popover,
  MyTableCellHelpersV2,
  HConstant,HText,
} from '../_import'

class CellType_ComponentBtnDeleteRow extends React.Component {
  render() {
    const {row,extra,styleBtn} = this.props;     
    let _fnList = MyTableCellHelpersV2.getFnList(this);
    let _title = MyTableCellHelpersV2.getTextFromTableConfigApplyRow(this,{fnList:_fnList,key:HConstant.TableConfig.deleteTitle,defaultValue:HText.get("btn_delete")});
    let _content = MyTableCellHelpersV2.getTextFromTableConfigApplyRow(this,{fnList:_fnList,key:HConstant.TableConfig.deleteContent,defaultValue:HText.get("confirm_delete")});    
    let _canDeleteRow = MyTableCellHelpersV2.getCanDeleteRow(this,{fnList:_fnList});
    if(_canDeleteRow){
      return (
        <Popover placement="topRight" title={_title}
          overlay={<div>{_content}</div>} trigger="click" showButton okText={HText.get("btn_ok")} cancelText={HText.get("btn_close")} 
          onOk={()=>{
            MyTableCellHelpersV2.runFnRequestDelete(this,{row,fnList:_fnList})
          }} 
          onCancel={()=>{console.log('Cancel')}}>
          <Button color="danger" outline style={{border:'0px',...styleBtn}} onKeyPress={(ev)=>{
              if(ev.key === 'Enter'){
                MyTableCellHelpersV2.runFnRequestDelete(this,{row,fnList:_fnList})
              }
            }}><i className="fa fa-trash"/></Button>
        </Popover>
      )
    }
    return (
      <div></div>
    )
  }
}

export default CellType_ComponentBtnDeleteRow;