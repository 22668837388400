import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {HashRouter, BrowserRouter, Route, Switch,Redirect} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';

import MyDialog from '../v1/components/MyDialog';
import MyModalAlert from '../v1/components/MyModal/MyModalAlert';
import MyModal from '../v1/components/MyModal/MyModal';
// import MyLoadingScreen from './v1/components/MyLoading/MyLoadingScreen';
// import MyErrorScreen from './v1/components/MyLoading/MyErrorScreen';
// import RouterHelpers from '../v1/views/Components/Router/RouterHelpers';
import MyModalPhotoV1 from '../v1/components/MyModal/MyModalPhoto'
import ModalPhotoViewerV1 from '../v1/components/ModalPhotoViewer'
import MyLoadingAppV1 from '../v1/components/Loading/LoadingScreen'
import MyPage from '../v1/components2/MyPage/MyPage'
import MyLoadingComponent from '../v1/components2/MyLoading/MyLoadingComponent'

// Styles
// Import Font Awesome Icons Set
import '../scss/fontAwesome/font-awesome.min.css';
// Import Simple Line Icons Set
import '../scss/fontSimpleLine/simple-line-icons.css';
// Import Main styles for this application
import '../scss/style.scss'
// Temp fix for reactstrap
import '../scss/core/_dropdown-menu-right.scss'
import 'react-toastify/dist/ReactToastify.css';

import '../scss/tailwindcss-use.css'

// Containers
// import Full from '../v1/containers/Full/'
// import FullPublic from '../v1/containers/Full/FullPublic'

// Views
// import Login from '../v1/views/Login/'

import HConfig from '../v1/helpers/Config';
import HGA from '../v1/libs/GA'

import HUI from '../v1/helpers/UI'
import HConstant from '../v1/helpers/Constant';
import {Error as HError} from '../v1/helpers/Error'
import HRouter from '../helpers/Route'
import { HCF } from '@macashipo/mlib';
import { KUI } from 'src/helpers/K';

console.warn("run App.js")
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    HConfig.fnList.getAuthIsLogged() ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)

class Analytics extends React.Component {
  componentDidMount() {
    // window.addEventListener("hashchange", ()=>{
    //   console.warn("hash change")
    // }, false);
    HGA.trackingPageView();
    console.warn('history:',this.props.history);
    window.globalHistory = this.props.history
    if(this.props.history && this.props.history.listen){
      this.props.history.listen((state)=>{
        console.log('History on change:',state);
        HGA.trackingPageView(state.pathname);
      })
    }    
  }

  render() {
    return (
      <>
      </>
    );
  }
}

class App extends Component {
  constructor(props){
    super(props);
    let _root = document.getElementById('root');
    if(_root){
      let _theme = HConfig.fnList.getConfig('theme');
      if(_theme){
        _theme.split(" ").forEach((v,i)=>{_root.classList.add(v);})
      }      
    }
    let _initClassBody = HConfig.fnList.getConfig(HConstant.AppConfig.initClassBody);
    if(_initClassBody){
      _initClassBody.split(" ").forEach((v,i)=>{window.document.body.classList.add(v);})
    }
    console.warn("add forceUpdateApp")
    HCF.setCF(KUI.fnForceUpdateRoutes,()=>{
      this.forceUpdate();
      console.warn("forceUpdateApp")
    })
  }
  state={
    errorFound: false
  }
  componentDidCatch(error, info) {
    console.warn("errrror:",error,info);
    this.setState({
      errorFound: true
    });
    HError.errorUI(error,{
      error: error,
      error_stack: error != null ? error.stack : '',
      info: info
    })
  }
  render() {
    if(!HConfig.fnList.checkValidConfigApp()){
      return (
        <MyPage configPage={{UIType:'error',Config:'{"msg":"Please contact Admin solve this problem","title":"Missing Config App"}'}}/>
      )
    }
    else if (this.state.errorFound) {
      return (
        <MyPage configPage={{UIType:'error'}}/>
      )
    }
    const TagRouter = HConfig.fnList.getConfig('isUseHash')===false?BrowserRouter:HashRouter;
    console.warn("TagRouter:",TagRouter,HConfig);
    return (
      <>
        <ToastContainer position="top-right" autoClose={5000} style={{zIndex: 1999}}/>
        <Analytics history={HConfig.fnList.getGlobalHistory()}/>
        <TagRouter>
          <React.Suspense fallback={<MyLoadingComponent />}>
            <Switch>
              {
                HRouter.renderRouter(HConfig.Routers)
              }
            </Switch>
            {/* <Switch>
              <Route exact path="/login" name="Login Page" component={Login}/>
              <Route exact path="/login/:id" name="Login Page" component={Login}/>
              { RouterHelpers.renderPublicListRoutes(RouterHelpers.getPublicRoutes(),FullPublic) }
              <PrivateRoute path="/" name="Home" component={Full}/>
            </Switch> */}
          </React.Suspense>
        </TagRouter>
        <MyModal />
        <MyModal type={MyModal.Helpers.types.fromComponent} />
        <MyModalAlert />
        <MyDialog />
        {/* <MyLoadingApp /> */}
        <MyModalPhotoV1 />
        <ModalPhotoViewerV1 />        
        <MyModal type={MyModal.Helpers.types.iframefull} />
        <MyLoadingAppV1 ref={(r)=>{
          HUI.LoadingScreen.init(r);//LoadingScreenV1
        }}/>
      </>
    );
  }
}

export default App;