import {ToastContainer, toast} from 'react-toastify';
import {HCF} from "@macashipo/mlib"
import {KUI} from "src/helpers/K"
import MyModalAlert from '../components/MyModal/MyModalAlert';
import HText from './Text';

const UI = {
  HConfig: null,
  initUI(Config){
    UI.HConfig = Config;
  },
  getHConfig(){
    if(UI.HConfig){
      return UI.HConfig;
    }
  },
  showMsg(msg,objDefault={}){
    const HConfig = UI.getHConfig();
    if(HConfig && msg){     
      let _obj; 
      if(typeof msg=="string"){
        if(msg.startsWith("{")){
          try {
            _obj = JSON.parse(msg);
          } catch (error) {
            console.warn("Parse error:",msg);
          }
        }
        if(_obj==null){
          if(objDefault.type=='error'){
            // UI.Toast.showError(msg);
            let _type = 'error';
            let _msg = msg;
            if(msg.startsWith("[Warning]")){
              _type = 'warning';
              _msg = msg.replace("[Warning]","")
            }
            UI.Toast.show(_type,_msg);
          }
          else if(objDefault.type=='warning'){
            UI.Toast.show(objDefault.type,msg);
          }
          else{
            // UI.Toast.showSuccess(msg);
            let _type = 'success';
            let _msg = msg;
            if(msg.startsWith("[Warning]")){
              _type = 'warning';
              _msg = msg.replace("[Warning]","")
            }
            else if(msg.startsWith("[Error]")){
              _type = 'error';
              _msg = msg.replace("[Error]","")
            }
            else if(msg.startsWith("[Alert]")){
              _msg = msg.replace("[Alert]","")
              UI.Alert.showMsg(HConfig.fnList.getConfig('title'),_msg,null,{
              });
              return;
            }
            UI.Toast.show(_type,_msg);
          }          
          return;
        }        
      }
      else if(typeof msg=="object"){
        _obj = msg;
      }
      // console.warn("obj:",_obj);
      if(_obj && _obj.msg){
        _obj = Object.assign({},objDefault,_obj);
        let _ui = _obj.ui || 'toast';//toast,alert,sytem
        if(_ui=='toast'){
          UI.Toast.show(_obj.type || 'success',_obj.msg,_obj.optionsToast);
        }
        else if(_ui=='alert'){
          UI.Alert.showMsg(_obj.title||HConfig.fnList.getConfig('title'),_obj.msg,null,{
            type: _obj.type
          });
        }
        else if(_ui=='system'){
          alert(_obj.msg);
        }    
      }
    }
  },
  Toast:{
    TYPE:{
      success: 'success',
      warn: 'warn',
      warning: 'warning',
      info: 'info',
      error: 'error',
    },
    show(type,msg,opts={}){
      if(msg){
        const options = {
          hideProgressBar: true,
          pauseOnHover: true,
          autoClose: 1500,
          ...opts
        };
        switch(type){
          case UI.Toast.TYPE.success:
            toast.success(msg,options);break;
          case UI.Toast.TYPE.warn:
            toast.warn(msg,options);break;
          case UI.Toast.TYPE.warning:
            toast.warn(msg,options);break;
          case UI.Toast.TYPE.info:
            toast.info(msg,options);break;
          case UI.Toast.TYPE.error:
            toast.error(msg,options);break;
        }
      }      
    },
    showSuccess(msg){
      UI.showMsg(msg,{type:"success"});
      // if(msg){
      //   if(msg.startsWith("[Warning]")){
      //     UI.Toast.show(UI.Toast.TYPE.warn,msg.replace("[Warning]",""));
      //   }
      //   else if(msg.startsWith("[Error]")){
      //     UI.Toast.show(UI.Toast.TYPE.error,msg.replace("[Error]",""));
      //   }
      //   else{
      //     UI.Toast.show(UI.Toast.TYPE.success,msg);
      //   }        
      // }  
    },
    showWarn(msg){
      UI.showMsg(msg,{type:"warning"});
      // UI.Toast.show(UI.Toast.TYPE.warn,msg);
    },
    showInfo(msg){
      UI.showMsg(msg,{type:"info"});
      // UI.Toast.show(UI.Toast.TYPE.info,msg);
    },
    showError(msg){
      UI.showMsg(msg,{type:"error"});
      // if(msg){
      //   if(msg.startsWith("[Warning]")){
      //     UI.Toast.show(UI.Toast.TYPE.warn,msg.replace("[Warning]",""));
      //   }        
      //   else{
      //     UI.Toast.show(UI.Toast.TYPE.error,msg);
      //   }        
      // }
    },
  },
  Alert:{
    show(title,msg,arrButton,opts){
      if(MyModalAlert!=null && MyModalAlert.Helper){
        MyModalAlert.Helper.show(title,msg,arrButton,opts);
      }
    },
    showMsg(title,msg,okCallBack,opts){
      UI.Alert.show(title,msg,[{
        title: HText.get('btn_close'),
        callback: okCallBack,
        color:'link',
        autoClose: true,
      }],opts)
    },
    showMsgQuestion(title,msg,okCallBack,cancelCallback,isAutoCloseOK){
      let _isAutoCloseOK = isAutoCloseOK!=null?isAutoCloseOK:true;
      UI.Alert.show(title,msg,[{
          title: HText.get('btn_close'),
          callback: cancelCallback,
          color:'link',
          autoClose: true,
        },{
          title: HText.get('btn_ok'),
          callback: okCallBack,
          color:'primary',
          autoClose: _isAutoCloseOK,
        }
      ])
    },
  },
  LoadingScreen: {
    cComponent: null,
    init(component){
      // console.log('init loading screen');
      UI.LoadingScreen.cComponent=component;
    },
    show(){
      // document.getElementById('loading_screen').style.visibility = 'visible';
      // console.log('show:',this.cComponent);
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.showLoading();
      }
    },
    showLoadingWithText(msg=""){
      // document.getElementById('loading_screen').style.visibility = 'visible';
      // console.log('show:',this.cComponent);
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.showLoading({msg});
      }
    },
    hide(){
      // document.getElementById('loading_screen').style.visibility = 'hidden';
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.hide();
      }
    },
    showError(msg,callback){
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.showError(msg,callback);
      }
    },
    showCustomContent(customContent){
      if(UI.LoadingScreen.cComponent!=null){
        UI.LoadingScreen.cComponent.showCustomContent(customContent);
      }
    },
  },
}
if(window._M){
  window._M.HUI = UI;
}

HCF.setCF(KUI.fnShowToast,(msg="", options={}, opts={})=>{
  let _type = options.type || UI.Toast.TYPE.success
  UI.Toast.show(_type,msg,options)
})
HCF.setCF(KUI.fnshowDialog,(configDialog={}, opts={})=>{
  if(configDialog && configDialog.msg){
    UI.Alert.showMsg(configDialog.title,configDialog.msg, configDialog.cbOK, {})
  }
})
HCF.setCF(KUI.fnShowLoadingScreen,(opts={})=>{
  if(UI.LoadingScreen.cComponent!=null){
    if(opts.hide===true){
      UI.LoadingScreen.cComponent.hide();
    }
    else if(opts.error, opts.cbTryAgain){
      UI.LoadingScreen.cComponent.showError(opts.error,opts.cbTryAgain);
    }
    else{
      UI.LoadingScreen.cComponent.showLoading();
    }    
  }
})
export default UI;