import React from 'react'
import classnames from 'classnames';
import HConfig from '../../helpers/Config';
import Global from '../../helpers/Global';
import Options from '../../helpers/Options';
import M from '../../libs/M';
import UI from '../../helpers/UI';
import MyModal from '../MyModal/MyModal';
import MyDialog from '../MyDialog';
import ComponentAddable from './Types/_addable';
import ComponentEdit from './Types/_edit';
import ComponentLink from './Types/_link';
import ComponentFileIcon from './Types/_fileIcon';
import {Tooltip,Popover} from '../../mreact/core';
import HH from '../../helpers/H';
import HLink from '../../helpers/Link';
import HUtils from '../../helpers/Utils';
import HColor from '../../helpers/Color'
// import HEE from '../../helpers/EventEmitter';
import {HEE} from "@macashipo/mlib";
import HConstant from '../../helpers/Constant'
import ComponentBtnDeleteRow from './Types/_btnDeleteRow'
import {Button} from '../../_importComponent'
import MyButtonHelpers from '../MyButton/MyButtonHelpers'
import ComponentCustomUICell from './Types/_customUICell'
import { fnListUI } from "src/helpers/fnList"
import V from "src/helpers/V"
import './CellTypeStyles.css';
/**
 * CellTypeHelper, các hàm hỗ trợ
 *
 * @class CellTypeHelper
 * @static
 * @namespace CellTypeHelper
 * 
 */
const CellTypeHelper = {
  /**
   * Lấy object more trong `extra.Type`
   * 
   * @method getMoreInType
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra và row
   * 
  */
  getMoreInType({extra,row}){
    let _more = {};
    if(extra!=null){
      let _type = extra.Type;
      try {
        if(_type!=null && _type.more){
          _more = JSON.parse(_type.more);
        }
      } catch (error) { console.warn('Parser json more in Type error:',error,_type);}
    }
    return _more;
  },
  /**
   * Lấy danh sách hàm có trong extra.fnList
   * 
   * @method getFnList
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra và row
   * 
  */
  getFnList({extra,row,more}){
    let _fnList = {};
    if(extra!=null){
      _fnList = extra.fnList||{};
      let _more = more || CellTypeHelper.getMoreInType({extra,row});
      return {
        ..._fnList,
        ...{
          fncGetRow: ()=>{
            return row;
          },
          fncGetMore: ()=>{
            return _more;
          },
          fncGetConfigByKeyInMore: (key)=>{
            return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more:_more,key});
          },
          fncGetQuery: ()=>{
            return CellTypeHelper.More.getQuery({extra,row,more:_more});
          },
          fncNeedReload: (response)=>{
            return CellTypeHelper.More.needReload({extra,row,more:_more,response})
          }
        }
      }
    }
    return _fnList;
  },
  /**
   * Lấy thông tin format `more.format` có trong more của `extra.Type`
   * 
   * @method getFormatInMore
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra và row
   * 
  */
  getFormatInMore({extra,row}){
    let _more = CellTypeHelper.getMoreInType({extra,row});
    if(_more!=null){
      return _more.format;
    }
  },
  /**
   * Lấy thông tin Option Text `more.otF` có trong more của `extra.Type`
   * 
   * @method getOptionTextFieldInMore
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra và row
   * 
  */
  getOptionTextFieldInMore({extra,row}){
    let _more = CellTypeHelper.getMoreInType({extra,row});
    if(_more!=null){
      return _more.otF;
    }
  },
  /**
   * Lấy thông tin CanShow của row
   * 
   * @method getCanShow
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row, defaultValue
   * 
  */
  getCanShow({extra,row,defaultValue=true,fieldName,more}){
    let _more = more || CellTypeHelper.getMoreInType({extra,row});
    let _canShow = defaultValue;
    if(_more){      
      if(_more.canShow==false){
        _canShow = false;
      }
      else if(_more.fCanShow && row[_more.fCanShow]!=null && row[_more.fCanShow]==false){
        _canShow = false;
      }
      else if(_more.hideOfIds && _more.hideOfIds.indexOf(row.Id)>-1){
        _canShow = false;
      }
    }
    // console.warn("getCanShow:",row,_canShow,_more);
    return _canShow;
  },
  /**
   * Lấy thông tin Can Show Modal của row
   * 
   * @method getCanShowModal
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row, defaultValue
   * 
  */
  getCanShowModal({extra,row,defaultValue=true,fieldName,more,configModal}){    
    let _more = more || CellTypeHelper.getMoreInType({extra,row});
    let _configModal = configModal || CellTypeHelper.More.getConfigModal({extra,row,more:_more});
    let _canShowModal = defaultValue;
    if(_configModal){      
      if(_configModal.canShowModal==false){
        _canShowModal = false;
      }
      // console.warn("getCanShowModal:",_configModal,_configModal.fCanShowModal,row[_configModal.fCanShowModal]);
      if(_configModal.fCanShowModal && row[_configModal.fCanShowModal]===false){
        _canShowModal = false;
      }
    }
    return _canShowModal;
  },
  getTableConfig({extra,row,more,fnList,key,defaultValue}={}){
    // let _more = more || CellTypeHelper.getMoreInType({extra,row});
    let _value = defaultValue;
    let _fnList = fnList || CellTypeHelper.getFnList({extra,row});
    if(_fnList && _fnList.fnGetTableConfig){
      _value = _fnList.fnGetTableConfig(key,defaultValue);
    }
    return _value;
  },
  getTextFromTableConfigApplyRow({extra,row,more,fnList,key,defaultValue}={}){
    let _value = CellTypeHelper.getTableConfig({extra,row,more,fnList,key,defaultValue});
    if(_value!=null && _value.indexOf("[")>-1){
      _value = HUtils.SquareBracket.replace(_value,row);
    }
    return _value;
  },
  getTextApplyRow({text,row}={}){
    let _value = text;
    if(row!=null && _value!=null && _value.indexOf("[")>-1){
      _value = HUtils.SquareBracket.replace(_value,row);
    }
    return _value;
  },
  /**
   * Lấy thông tin CanEdit của row
   * 
   * @method getCanEdit
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row, defaultValue
   * 
  */
  getCanEdit({extra,row,defaultValue=true,fieldName}){
    return V.Cell_GetCanEdit({extra,row,fieldName})    
  },
   /**
   * Lấy config by key lần lượt ở more, TableConfig, AppConfig
   * 
   * @method getConfigTrueByAllLevel
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có key
   * 
  */
  getConfigTrueByAllLevel({key, extra,row,more}={}){
    const _configInTableConfig = CellTypeHelper.getTableConfig({extra,row,more,key});
    if(more && more[key] === true){
      // console.warn("more:",more)
      return true;
    }
    else if(_configInTableConfig === true){
      // console.warn("_configInTableConfig:",_configInTableConfig)
      return true;
    }
    else if(HConstant.gc(key)===true){
      // console.warn("AppConfig:",HConfig)
      return true;
    }
    return false;
  },
  /**
   * Lấy tên class có trong `extra.ClassName` hoặc `more.className`
   * 
   * @method getExtraClassName
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row, more
   * 
  */
  getExtraClassName({extra,row,more}){
    let _className = "";
    if(CellTypeHelper.getConfigTrueByAllLevel({key: HConstant.AppConfig.showClassFieldOnCell, extra,row,more})){
        let _fieldName = HUtils.Obj.get(extra, "fieldName");
        return `dataFieldId-${_fieldName}`
    }
    if(extra!=null){
      if(extra.ClassName){
        return extra.ClassName;
      }
    }
    if(more && more.className){
      return more.className;
    }
    return _className;
  },
  /**
   * Lấy nội dung tooltip trong `more.tooltip` hoặc `row[more.tooltipF]`
   * 
   * @method getExtraTooltip
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row
   * 
  */
  getExtraTooltip({extra,row,more}){
    let _more = more || CellTypeHelper.getMoreInType({extra,row});
    if(_more!=null){
      if(_more.fTooltip && row[_more.fTooltip]){
        return row[_more.fTooltip];
      }
      else if(_more.tooltip){
        return _more.tooltip;
      }      
      else if(_more.tooltipF && row[_more.tooltipF]){//ko dung kieu suffix F nữa
        return row[_more.tooltipF];
      }
    }
  },
  getExtraTooltipProps({extra,row,more}){
    let _more = more || CellTypeHelper.getMoreInType({extra,row});
    if(_more!=null){
      if(_more.fTooltipProps && row[_more.fTooltipProps]){
        return row[_more.fTooltipProps];
      }
      else if(_more.tooltipProps){
        return _more.tooltipProps;
      }      
    }
  },
  /**
   * Lấy style từ extra.Style
   * - Bổ sung màu nền từ `extra.Type.bgF` (có thể sử dụng mã màu trực tiếp từ bgF). 
   * - Bổ sung thêm style từ `more.style`
   * - Bổ sung thêm style từ `row.StyleRow`
   * 
   * @method getExtraStyle
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row, custom
   * 
  */
  getExtraStyle({extra,row,custom,defaultStyle,disabled}){
    let _style = defaultStyle || {};
    if(extra!=null){
      let _styleEx = extra.Style;
      if(_styleEx!=null){
        if(M.isString(_styleEx)==true){
          try {
            _style = Object.assign(_style,JSON.parse(_styleEx));
          } catch (error) {
            console.log('getExtraStyle error:',_style,error);
          }
        }
        else if(M.isObject(_styleEx)==true){
          _style = Object.assign(_style,_styleEx);
        }
      }
      if(extra.Type!=null && row!=null){
        if(extra.Type.bgF!=null){
          if(row[extra.Type.bgF]!=null){
            let _color = HColor.getTextWB(row[extra.Type.bgF]);
            _style = Object.assign(_style,{background:row[extra.Type.bgF],color:_color});
          }
          else if(extra.Type.bgF.startsWith('#') || extra.Type.bgF.startsWith('linear-gradient')){
            let _color = HColor.getTextWB(extra.Type.bgF);
            _style = Object.assign(_style,{background:extra.Type.bgF,color:_color});
          }
        }
        if(extra.Type.colorF!=null){
          if(row[extra.Type.colorF]!=null){
            _style = Object.assign(_style,{color:row[extra.Type.colorF]});
          }
          else if(extra.Type.colorF.startsWith('#')){
            _style = Object.assign(_style,{color:extra.Type.colorF});
          }
        }        
      }
      let _more = CellTypeHelper.getMoreInType({extra,row});
      // console.log('_more:',_more);
      if(_more){
        try {
          if(_more.style){
            let _styleInMore = _more.style || {};
            if(_styleInMore){
              _style = Object.assign(_style,_styleInMore);
            }
          }
          let _fColor = _more.fColor || _more.colorF;
          if(_fColor!=null){
            if(row[_fColor]!=null){
              _style = Object.assign(_style,{color:row[_fColor]});
            }
          }
        } catch (error) {
          
        }
      }
    }
    // console.log('getExtraStyle:',extra,_style);
    if(row && row.StyleRow && typeof row.StyleRow == 'object'){
      _style = Object.assign(_style,row.StyleRow);
    }
    if(row && row.UI_StyleRow){
      if(typeof row.UI_StyleRow == 'object'){
        _style = Object.assign(_style,row.UI_StyleRow);
      }
      else if(typeof row.UI_StyleRow == 'string'){
        try {
          let _styleRow = JSON.parse(row.UI_StyleRow);
          if(_styleRow){
            _style = Object.assign(_style,_styleRow);
          }
        } catch (error) {
          console.warn('Error parse UI_StyleRow',error);
        }
      }      
    }
    if(custom){
      _style = Object.assign(custom,_style);
    }

    //check disable
    if(disabled!=null){
      if(_style.backgroundColor==null && _style.background==null && _style.backgroundImage==null){
        if(disabled==true){
          _style.background = "transparent";
        }
        else{
          _style.background = "white";
        }
      }
    }
    return _style;
  },
  getIsHasExpandInCell({cell,row,extra,more}={}){
    if(more && (more.configExpandArea || more.hasExpandArea)){
      let _configExpandArea = CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,key:"configExpandArea"});
      if(more.hasExpandArea || (_configExpandArea && _configExpandArea.show===true)){
        return true;
      }
    }
    return false;
  },
  getStyleContainer({cell,row,extra,more}={}){
    let _style = {};       
    // console.warn("getStyleContainer",more,more.hasExpandArea);
    if(more && (more.configExpandArea || more.hasExpandArea)){
      let _configExpandArea = CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,key:"configExpandArea"});
      if(more.hasExpandArea || (_configExpandArea && _configExpandArea.show===true)){//tuong duong more.hasExpandArea
        let _fnList = CellTypeHelper.getFnList({extra,row}); 
        let _currentLevelDetail = HConstant.rf(_fnList,HConstant.UIFnTableDetail.fntdGetExpandLevel,[]);
        if(_currentLevelDetail!=null){
          let _paddingLeft = 20;
          if(_configExpandArea && _configExpandArea.paddingLevel!=null){
            _paddingLeft = _configExpandArea.paddingLevel;
          }
          _style.paddingLeft = `${_currentLevelDetail*(_paddingLeft)}px`;
          _style.position = "relative";
          _style.background = "transparent";
          _style.display = "flex";
          _style.flexDirection = "row";
        }
      }
    }    
    if(more && more.styleContainer){
      _style = Object.assign(_style,more.styleContainer);
    }
    return _style;
  },
  prepareRowExpand({extra,row,more,objExpand}={}){
    if(row){
      if(row['_isExpandMulti'] == null){
        row['_isExpandMulti'] = {};
      }
      if(row['_configMulti'] == null){
        row['_configMulti'] = {};
      }
    }
    if(objExpand){
      let _key = objExpand.key || objExpand.screenCode || 'nokey';
      if(row['_configMulti'][_key]==null){
        row['_configMulti'][_key] = objExpand;  
        row['_configMulti'][_key]._init = true;        
        //check default exand
        let _defaultShowExpand = false;
        if(row.UI_DefaultShowExpand==true){
          _defaultShowExpand = true;
        }
        if(objExpand.defaultShowExpand!=null){
          _defaultShowExpand = objExpand.defaultShowExpand;
        }
        if(row.UI_ConfigExpand && row.UI_ConfigExpand.DefautShowExpand){
          if(row.UI_ConfigExpand.DefautShowExpand.indexOf(_key)>-1){
            _defaultShowExpand = true;
          }
        }
        row['_isExpandMulti'][_key] = _defaultShowExpand;
      }
    }
  },
  getClassNameContainer({cell,row,extra,more}={}){
    // return "";
  },
  /**
   * Lấy source list từ field source trong Options List: `extra.sourceList[Type.source]`
   * - [Chưa xong]
   * 
   * @method getSourceSelect
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row
   * 
  */
  getSourceList({extra,row}){
    let _sourceList = {};
    if(extra && extra.sourceList){
      return extra.sourceList;
    }
    else{
      let _fnList = CellTypeHelper.getFnList({extra});
      if(_fnList && _fnList.fnGetOptions){
        return _fnList.fnGetOptions();
      }
    }
    return _sourceList;
  },
  getSourceSelect({extra,row}){
    let _sourceOption = [];let _type = extra.Type;
    let _sourceList = CellTypeHelper.getSourceList({extra,row});
    // console.log('getSourceSelect:',row,extra);

    //Check source have .
    if(_type.source!=null && _type.source.indexOf('.')>0){
      let _f1 = _type.source.split('.')[0];
      let _f2 = _type.source.split('.')[1];
      let _sf = _type.sourceField;
      let _valueSourceField = row[_sf];

      if(_sourceList[_f1]!=null  && _sourceList[_type.source]==null){//&& extra.sourceList[_f2]!=null
        let _obj = M.arrayObj2ObjWithKey(_sourceList[_f1],'Value');
        if(_obj!=null)
        {
          let _keys = Object.keys(_obj);
          let _newObj = {};
          for(let i of _keys){
            _newObj[i] = _obj[i][_f2];
          }
          _sourceList[_type.source] = _newObj;
          console.log('getSourceSelect:',extra);
        }
      }
      if(_sourceList[_type.source]!=null && _valueSourceField!=null){
        _sourceOption = _sourceList[_type.source][_valueSourceField];
        // console.log('getSourceSelect option 1:',_sourceOption);
      }
    }
    else if(_type.source!=null && _type.source.startsWith("[")==true){
      try {
        _sourceOption = JSON.parse(_type.source);
      } catch (error) {
        console.warn("Parse Source option error:",_type);
      }
    }
    else if(_type.source!=null && _type.source.startsWith("f")==true){
      let _fieldName = _type.source.slice(1,_type.source.length);
      if(row[_fieldName]!=null && Array.isArray(row[_fieldName])){
        _sourceOption = row[_fieldName];
      }      
    }
    else if(_type.source!=null && _sourceList!=null){
      _sourceOption = _sourceList[_type.source] || [];
    }

    if(_type.type=='select2color' || _type.type=='pickcolor'){
      let _s = _sourceList[_type.source];
      let _newFieldSource = _type.source+'_Select2Color';
      if(_sourceList[_newFieldSource]!=null){
        _sourceOption = _sourceList[_newFieldSource];
      }
      else if(_s!=null){
        _sourceList[_newFieldSource] = _s.map((e,i)=>{
          return {
            value: e.ColorCode,
            label: e.Name,
          }
        });
        _sourceOption = _sourceList[_newFieldSource];
      }
    }

    if(_type.sourceList!=null){
      try {
        extra.Type.sourceListParser = JSON.parse(_type.sourceList);
        _sourceOption = extra.Type.sourceListParser;
      } catch (error) { console.warn('parser json type source list error:',error);}
    }
    return _sourceOption;
  },
  /**
   * Lấy ExtraData Columns của 1 field
   * 
   * @method getExtraColumn
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row, fieldName
   * 
  */
  getExtraColumn({extra,row,fieldName}){
    if(extra && extra.extraData && extra.extraData.Columns){
      return extra.extraData.Columns[fieldName];
    }
  },
  /**
   * Lấy Config Modal từ more
   * 
   * @method getConfigModalFromMore
   * @memberof CellTypeHelper
   * @param {Object} opts Gồm có extra, row, fieldName
   * 
  */
  getConfigModalFromMore(more){
    let _configModal = {};
    if (more && more.modal){
      _configModal = more.modal;
    }
    return _configModal;
  },    
  ExtraData:{
    getCanShowOfFieldName(extraData,fieldName){
      if(extraData && fieldName && extraData.Columns && extraData.Columns[fieldName] && extraData.Columns[fieldName].CanShow!=null){
        return extraData.Columns[fieldName].CanShow;
      }
      return false;
    },
    getCanEditOfFieldName(extraData,fieldName){
      if(extraData && fieldName && extraData.Columns && extraData.Columns[fieldName] && extraData.Columns[fieldName].CanEdit!=null){
        return extraData.Columns[fieldName].CanEdit;
      }
      return false;
    },
  },
  More:{
    parseResponse({extra,row,more,fnList,response}={}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      if(response && response.Data){
        if(response.Data.FullPath){
          let _data = response.Data;
          if (_more.autoOpenLink==true){
            // window.open(_data.FullPath,'_blank');
            HLink.openExternalUrl(_data.FullPath,_data.FullPathNewWindow===false?"_self":"_blank")
          }
          else{
            MyDialog.Helper.show({
              title: 'Link',
              msg: (
                <div style={{wordWrap:'break-word'}}>
                  <div>{_data.FullPathInfo}</div>
                  <a href={_data.FullPath} target={_data.FullPathNewWindow===false?"_self":"_blank"} download={_data.FullPathDownload===true?true:false}>{_data.FullPath}</a>
                </div>
              )
            });
          }
        }
      }
    },
    needReload({extra,row,more,fnList,forceReloadTable,response}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _fnList = fnList || CellTypeHelper.getFnList({extra,row});
      if(_more.needReloadPage===true){
        window.location.reload();
        // console.warn("needReload page:",forceReloadTable,_more);
        return;
      }

      //
      console.warn("forceReloadTable", forceReloadTable, more)
      const _needSendMsg = HUtils.Obj.get(_more,HConstant.ConfigInMore.needSendMsg);
      if(_needSendMsg !== null){
        if(typeof(_needSendMsg) == "string"){
          console.warn("cell emit", _needSendMsg, response)
          HEE.emit(_needSendMsg, {response})
        }
        else if(Array.isArray(_needSendMsg)){
          for(let i=0; i<_needSendMsg.length; i++){
            HEE.emit(_needSendMsg[i], {response})
          }
        }
      }

      //
      if(HUtils.Obj.get(_more,HConstant.ConfigInMore.needCloseModal)===true){
        MyModal.Helper.hide()
      }
      //ReExpand
      if(_more && _more.needReExpand===true && _fnList && _fnList.fnGetExpandList){
        let _cExpandList = _fnList.fnGetExpandList();
        if(_cExpandList && _cExpandList[row.Id] && _cExpandList[row.Id].reExpand){
          _cExpandList[row.Id].reExpand();
        }
      }

      if(_more && _more.needReloadOptionsAndList==true){
        if(_fnList && _fnList.fnReloadOptionsAndList){
          _fnList.fnReloadOptionsAndList();
          return;
        }
      }

      if(_more && _more.needReloadRow==true && response && response.Data){
        CellTypeHelper.updateRowWithData({
          row,
          data:response.Data,
          fieldId: "Id"
        });
        if(_fnList && _fnList.fnForceUpdateTable){
          _fnList.fnForceUpdateTable();
        }
      }
      
      if(forceReloadTable===true || _more.needReload===true || _more.needReloadList===true || _more.needReloadTable===true){
        // console.warn("needReload Table:",forceReloadTable,_more);
        if(_fnList && _fnList.fnReloadTable){
          _fnList.fnReloadTable();
        }
      }      
    },
    getVersion({extra,row,more,df,key}={}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _version = HConfig.fnList.getConfig(key) || df;
      if(_more && _more.version){
        _version = _more.version;
      }
      return _version;
    },
    getConfigByKeyInMore({extra,row,more,df,key}={}){
      let _configByKeyInMore = df;
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      if(_more && key){
        if(_more[key]){
          _configByKeyInMore = _more[key];
        }        
        if(key.length>1){
          let _fKey = `f${key.charAt(0).toUpperCase() + key.slice(1)}`;
          if(row[_fKey]){
            _configByKeyInMore = row[_fKey];
          }
        }
        
      }      
      return _configByKeyInMore;
    },
    getConfigPagination({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||{},key:HConstant.ConfigInMore.configPagination});
      /**
       * {
       *  using: true,// check if need
       *  usingApi: false, // check if using api pagination
       *  pageSize: 50 
       * }
       */
    },
    getConfigApi({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||{},key:HConstant.ConfigInMore.configApi});
      /**
       * {
       *  using: true, // check if need
       *  request:{
       *    method: 'POST'
       *    url:  '',
       *    path: '',
       *    name: '',
       *  },
       *  query:{} // query
       * }
       */
    },
    getConfigEditIcon({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||{},key:HConstant.ConfigInMore.configEditIcon});
      /**
       * {
       *  using: true, //check if false
       *  fieldName: 'ortherFieldName', //defaut that column
       *  type: 'text', // type 
       * }
       */
    },
    getConfigLinkIcon({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||{},key:HConstant.ConfigInMore.configLinkIcon});
      /**
       * {
       *  using: true, //check if false
       *  fieldName: 'ortherFieldName', //defaut that column
       *  type: 'text', // type 
       * }
       */
    },
    getConfigFileIcon({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||{},key:HConstant.ConfigInMore.configFileIcon});
      /**
       * {
       *  using: true, //check if false
       *  fieldName: 'ortherFieldName', //defaut that column
       *  type: 'text', // type 
       * }
       */
    },
    getConfigIcon({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||null,key:"icon"});
      /** object hoac string. string -> className
       * {
       *  style
       *  height
       *  width
       *  size
       *  className
       * }
       */
      // let _configIcon = {};
      // if (more && more.icon && typeof more.icon==="object"){
      //   // style, height, width, size, className
      //   _configIcon = more.icon;
      // }
      // return _configIcon;
    },
    getConfigAddable({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||{},key:HConstant.ConfigInMore.configAddable});
    },
    getNumDecimal({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||1,key:HConstant.ConfigInMore.numDecimal});
    },
    getNeedMultip({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||false,key:HConstant.ConfigInMore.needMultip});
    },
    getConfirmMsg({extra,row,more,df}){
      let _confirmMsg = df || "";
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      if (_more && _more.confirmMsg!=null){
        _confirmMsg = _more.confirmMsg;
      }
      if (_more && _more.fConfirmMsg){
        _confirmMsg = row[_more.fConfirmMsg];
      }
      return _confirmMsg;
    },
    getConfirmTitle({extra,row,more,df}){
      return CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,df:df||"Confirm",key:HConstant.ConfigInMore.titleConfirm});
    },
    getFormat({extra,row,more,df}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _format = df;
      if(_more.format){
        _format = _more.format;
      }
      return _format;
    },
    getDateFormat({extra,row,more,df}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _format = df || HConfig.dateFormat;
      if(_more.dateFormat){
        _format = _more.dateFormat;
      }
      else if(_more.format){
        _format = _more.format;
      }
      // console.log("date Format:",_format);
      return _format;
    },
    getSeparatorChar({extra,row,more,df}){
      if(more && more.separatorChar){
        return more.separatorChar;
      }
    },
    getColor({extra,row,more,df}){ // more.color more.fColor
      let _color = df || "transparent";
      if(more.color){
        _color = more.color;
      }
      if(more.fColor && row[more.fColor]){
        _color = row[more.fColor];
      }
      return _color;
    },
    getTextColor({extra,row,more,df}){ // more.textColor more.fTextColor
      let _color = df || "black";
      if(more.textColor){
        _color = more.textColor;
      }
      if(more.fTextColor && row[more.fTextColor]){
        _color = row[more.fTextColor];
      }
      return _color;
    },    
    getConfigControl({extra,row,more}){
      let _configControl = {};
      if (more && more.control && typeof more.control==="object"){
        _configControl = more.control;
      }
      return _configControl;
    },    
    getConfigPopover({extra,row,more}){
      let _configPopover = {};
      if (more && more.popover && typeof more.popover==="object"){
        _configPopover = more.popover;
      }
      return _configPopover;
    },
    getConfigRightBottom({extra,row,more,df}){
      let _configRightBottom = df || {};
      if (more && more.configRightBottom && typeof more.configRightBottom==="object"){
        _configRightBottom = more.configRightBottom;
      }
      return _configRightBottom;
    },
    getConfigMergeRow({extra,row,more,df}){
      let _configMergeRow = df || {};
      if (more && more.configMergeRow && typeof more.configMergeRow==="object"){
        _configMergeRow = more.configMergeRow;
      }
      return _configMergeRow;
    },
    getConfigOverlay({extra,row,more,df}){
      let _configOverlay = df || {};
      if (more && more.configOverlay && typeof more.configOverlay==="object"){
        _configOverlay = more.configOverlay;
      }
      return _configOverlay;
    },
    getMorePropsPopover({extra,row,more,df,configPopover}){
      let _props = df || {};
      let _configPopover = configPopover || CellTypeHelper.More.getConfigPopover({extra,row,more});
      if(_configPopover.props){
        _props = Object.assign(_props,_configPopover.props);
      }
      return _props;
    },
    getConfigModal({extra,row,more}){
      return V.Cell_GetConfigModal({extra,row,more})
    },
    getConfigButton({extra,row,more}){
      let _configButton = {};
      if (more && more.button && typeof more.button==="object"){
        _configButton = more.button;
      }
      return _configButton;
    },
    getMorePropsButton({extra,row,more,df,content}){
      let _props = df || {};
      if(_props.outline==null){ _props.outline=false};
      if(_props.color==null){ _props.color="primary"};
      if(_props.className==null){ _props.className="has-wrap"};
      if(_props.style==null){ _props.style={}};
      if(content===MyButtonHelpers.sTitleEmpty){
        _props.style.visibility = "hidden";
      }
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      if(_more && _more.fVisible){        
        if(row[_more.fVisible]===false || row[_more.fVisible]==null || row[_more.fVisible]==""){
          _props.style.visibility = "hidden"
        }
      }
      if(_more.styleByValueField && _more.styleByValueField.field){
        // console.warn("stylebyValueField",_more);
        let _v = row[_more.styleByValueField.field];
        let _objSwitch = _more.styleByValueField.switch;
        if(_objSwitch){
          if(_v && _objSwitch[_v]){
            _props.style = Object.assign(_props.style,_objSwitch[_v]);
          }
          else if(_objSwitch.default){
            _props.style = Object.assign(_props.style,_objSwitch.default);
          }
        }
      }
      if (_more && _more.button && typeof _more.button==="object"){     
        let _configButton = _more.button;
        if(_configButton.outline!=null){_props.outline=_configButton.outline};
        if(_configButton.color!=null){_props.color=_configButton.color};
        if(_configButton.className!=null){_props.outline=_configButton.className};
        if(_configButton.style!=null){_props.style=Object.assign(_props.style,_configButton.style)};
        if(_configButton.fColor && row[_configButton.fColor]){
          if(HColor.validButtonsColor(row[_configButton.fColor])){
            _props.color = row[_configButton.fColor];
          }
          else if(_props.style){
            _props.style.color = row[_configButton.fColor];
          }          
        }                
        if(_configButton.fDisabled!=null){//
          if(typeof _configButton.fDisabled=="boolean"){
            _props.disabled = _configButton.fDisabled;
          }
          else if(typeof row[_configButton.fDisabled]=="boolean"){
            _props.disabled = row[_configButton.fDisabled];
          }          
        }
        if(_configButton.fNotDisabled!=null){
          if(typeof _configButton.fNotDisabled=="boolean"){
            _props.disabled = !_configButton.fNotDisabled;
          }
          else if(typeof row[_configButton.fNotDisabled]=="boolean"){
            _props.disabled = !row[_configButton.fNotDisabled];
          }          
        }
        if(_configButton.props){
          _props = Object.assign(_props,_configButton.props);
        }
      }
      // console.warn("props:",_props);
      return _props;
    },
    getTitleOfModal({extra,row,cell,more,configModal,df,forceValue}){
      if(forceValue){
        return forceValue;
      }
      let _title = df;      
      if(configModal.title){
        _title = configModal.title;
      }
      if(configModal.fTitle && row[configModal.fTitle]){
        _title = row[configModal.fTitle];
      }
      // console.warn("title:",_title);
      return _title;
    },
    getTextDisplayInButton(opts={}){
      opts.df = MyButtonHelpers.sTitleEmpty;
      return CellTypeHelper.More.getTextDisplay(opts)
    },
    getTextDisplay({extra,row,cell,more,df,icon,title,hasIconAndText,options,optionValue}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _display = df || "";
      let _icon = null;
      // console.warn("getTextDisplay:",_more,_display);
      if(_more){
        if(_more.fDisplay && row[_more.fDisplay]){
          _display = row[_more.fDisplay];
        }
        else if(_more.fShow && row[_more.fShow]){
          _display = row[_more.fShow];
        }
        else if(options && optionValue){
          _display = Options.getTextDisplayOfValueFromOptions(options,optionValue) || cell;
        }
        else if(title){
          _display = title;
        }
        else if(cell){
          _display = cell;
        }

        if(icon){
          _icon = (
            <i className={`fa ${icon}`} />
          )
        }
        else if(_more.fIcon && row[_more.fIcon]){
          _icon = (
            <i className={row[_more.fIcon]} />
          )
        }

        if(_icon){
          if(hasIconAndText===true){
            _display = (
              <div>
                {_icon} {_display}
              </div>
            )
          }
          else{
            _display = _icon;
          }
        }        
      }
      return _display;
    },
    getRequestMethod({extra,row,more}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _method = "POST";
      if(_more && _more.method){
        _method = _more.method;
      }
      return _method;
    },
    getConfigBadge({extra,row,more,df}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _configBadge = df || {};
      if(_more.configBadge && typeof _more.configBadge =="object"){
        _configBadge = Object.assign(_configBadge,_more.configBadge);
      }
      else if(_more.badge && typeof _more.badge =="object"){//ko dung nua -> chuyen sang _more.configBadge
        _configBadge = Object.assign(_configBadge,_more.badge);
      }
      return _configBadge;
    },
    getConfigFile({extra,row,more,df}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _configFile = df || {};
      if(_more.file && typeof _more.file =="object"){
        _configFile = Object.assign(_configFile,_more.file);
      }
      return _configFile;
    },    
    getConfigRequest({extra,row,more}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _request = {
        method: CellTypeHelper.More.getRequestMethod({extra,row,more:_more}),
      }
      if(_more.apiUrl){
        _request.url = _more.apiUrl;
      }
      else if(_more.url){//Dang dung o type api
        _request.url = _more.url;
      }
      if(_request.url){
        if(_request.url.indexOf("[")>-1){
          _request.url = HUtils.SquareBracket.replace(_request.url,row);
        }
      }

      if(_request.url==null){
        if(_more.apiPath){
          _request.path = _more.apiPath;
        }
        else{
          let _fnList = CellTypeHelper.getFnList({extra,row});
          let _fnGetApiPath = _fnList.fnGetApiPath;
          if(_fnGetApiPath){
            _request.path = _fnGetApiPath();
          }        
        }      
        if(_more.apiName){
          _request.name = _more.apiName;
        } 
        if(_more.apiNameAdd){//Dung o cac type addable
          _request.name = _more.apiNameAdd;
        } 
        
        if(_request.name){
          if(_request.name.indexOf("[")>-1){
            _request.name = HUtils.SquareBracket.replace(_request.name,row);
          }
        }
      }        
      return _request;
    },
    getNewLinkType({extra,row,more,df}){
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _type = df || (HConfig.defaultNewWindowCellLink==true?'_blank':'_self');
      if(_more.newWindow===true){
        _type = '_blank';
      }
      else if(_more.newWindow===false){
        _type = '_self';
      }
      return _type;
    },
    getQuery({extra,row,more,fnList}){//fId,queryString,query
      // console.warn('extra,row,more', extra,row,more);
      let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
      let _fnList = fnList || CellTypeHelper.getFnList({extra,row});

      let _queryObj = {};

      if(row.ProjectId){//Tu dong gan ProjectId
        _queryObj.ProjectId = row.ProjectId;
      }
      if(_more.fProjectId && row[_more.fProjectId]){
        _queryObj.ProjectId = row[_more.fProjectId];
      }

      if (_more.fID){//Gan fId
        _queryObj[_more.fID] = row.Id;
      }      

      let _queryString = _more.queryString;
      // console.warn('query1',_queryString);
      if (typeof(_queryString)==='string'){
        if(_queryString){
          _queryString = HUtils.SquareBracket.replace(_queryString,row);
          // console.warn('query2',_queryString);
          try {
            let _query = JSON.parse(_queryString);
            if (_query!=null){
              _queryObj = Object.assign(_queryObj,_query);
            }
          } catch (error) {
            console.warn("Parse json _queryString error:",_queryString,error);
          }
        }
      }

      if(_more.query){
        let _queryMore = _more.query;
        let _query = {};
        let _obj = Object.keys(_queryMore);
        for (let i of _obj){
          let _value = _queryMore[i];
          if(row.hasOwnProperty(_value)==true){
            if (row[_value]!=null){
              _query[i] = row[_value];
            }
          }          
          else{
            _query[i] = _queryMore[i];
          }
        }
        // console.warn('query result', _query);
        if (_query!=null){
          _queryObj = Object.assign(_queryObj,_query);          
        }
      }

      if(_more.queryConfig){
        if(_more.queryConfig.hasFilter){
          if(_fnList && _fnList.fnGetFilterQuery){
            let _filterQuery=_fnList.fnGetFilterQuery();
            if(_filterQuery && Object.keys(_filterQuery).length>0){
              _queryObj = Object.assign(_queryObj,{
                FilterQuery: _filterQuery
              });  
            }              
          }
        }
        if(_more.queryConfig.hasColumnShow){
          if(_fnList && _fnList.fnGetColumnShowQuery){
            _queryObj = Object.assign(_queryObj,_fnList.fnGetColumnShowQuery());    
          }
        }
      }

      if(_more.queryFromParent && _fnList && _fnList.fnGetParentData){        
        let _queryFromParent = {};
        let _parentData = _fnList.fnGetParentData();
        let _allKeys = Object.keys(_more.queryFromParent);
        if(_parentData){
          for(let k of _allKeys){
            _queryFromParent[k] = _parentData[_more.queryFromParent[k]];
          }
        }   
        // console.warn("queryFromParent1",_parentData);
        _queryObj = Object.assign(_queryObj,_queryFromParent);        
      }
      // console.warn("queryFromParent",_more.queryFromParent);
      // console.warn('queryObj', _queryObj);
      return _queryObj;
    },
  },
  callRequestUpdate({extra,row,fieldName,newValue,component,forceUpdate,successCallBack,hideMsgUpdate}){
    return V.Cell_UpdateCell({extra,row,fieldName,newValue,alwaysUpdate:forceUpdate,fnForceUpdateCell: ()=>{
      if(component && component.forceUpdate){          
        component.forceUpdate();
      }
    }})
  },
  // callRequestUpdateOld({extra,row,fieldName,newValue,component,forceUpdate,successCallBack,hideMsgUpdate}){
  //   let _fnList = CellTypeHelper.getFnList({extra,row});
  //   let _fnRequestUpdate = _fnList.fnRequestUpdate;
  //   if(extra.fnRequestUpdate){
  //     _fnRequestUpdate = extra.fnRequestUpdate;
  //   }
  //   let _cell = row[fieldName];
  //   // console.warn('callRequestUpdate ne!!!',extra,row,fieldName,newValue,_fnList);
  //   // console.warn('fieldName: ', fieldName);
  //   // console.warn('_cell: ', _cell);
  //   // console.warn('newValue: ', row, fieldName, row[fieldName],newValue,_cell,forceUpdate);
  //   if (_fnRequestUpdate){
  //     let _canUpdate = false;
  //     if (newValue!==_cell && !(_cell==null && newValue==="")){// false == "", fix
  //       _canUpdate = true;
  //     }
  //     else if(newValue==_cell){//Cho phep update khi 2 gia tri bang nhau va dang bi loi
  //       if(row["_UI_Error"] && row["_UI_Error"][fieldName]!=null){
  //         _canUpdate = true;
  //       }
  //       else if(forceUpdate==true){
  //         console.warn('newValue forceUpdate: ', newValue,_cell,forceUpdate);
  //         _canUpdate = true;
  //       }
  //     }
  //     if (_canUpdate){
  //       if(row["_UI_Loading"]==null){
  //         row["_UI_Loading"] = {};
  //       }
  //       row["_UI_Loading"][fieldName] = true;
  //       // console.warn("component update",component);
  //       // return;
  //       if(component && component.forceUpdate){          
  //         component.forceUpdate();
  //       }
  //       _fnRequestUpdate(row, fieldName, newValue, {
  //         component: component,
  //         hideMsgUpdate: hideMsgUpdate,
  //         fnUpdateUILoading: function(isLoading,opts){
  //           if(row["_UI_Loading"]==null){
  //             row["_UI_Loading"] = {};
  //           }
  //           row["_UI_Loading"][fieldName] = isLoading;
  //           if(opts && opts.component && opts.component.forceUpdate){
  //             opts.component.forceUpdate();
  //           } 
  //           // console.warn("fnUpdateUILoading",isLoading,row["_UI_Loading"][fieldName],fieldName,row,component);
  //         },
  //         fnUpdateUIError: function(msg,opts){
  //           if(row["_UI_Error"]==null){
  //             row["_UI_Error"] = {};
  //           }
  //           if(msg==null){//msg == null nghia la xoa error di
  //             delete row["_UI_Error"][fieldName];
  //           }
  //           else{
  //             row["_UI_Error"][fieldName] = {
  //               msg: msg,
  //             };
  //           }   
  //           // console.warn("fnUpdateUIError",msg,fieldName,row,opts,component);
  //           if(opts && opts.component && opts.component.forceUpdate){
  //             opts.component.forceUpdate();
  //           }         
  //         },
  //         fnSuccessCallBack: function(response,opts){
  //           if(successCallBack){
  //             successCallBack(response,opts);
  //           }
  //         }
  //       });
  //     }
  //   }
  // },
  renderClearBtn({disabled,cell,extra,row,more}){//ko nen xai nua, chuyen sang renderStatus
    let _showClear = more.showClear;
    if(_showClear==null && HConfig.tableCellShowClear==true){
      _showClear = true;
    }
    if (_showClear==true && disabled!=true && cell!=null && cell!=""){
      let _emptyValue = more.emptyValue!=null?more.emptyValue:null;
      return(
        <div className="mct-clear-btn no-print" onClick={()=>{
          CellTypeHelper.callRequestUpdate({extra,row,fieldName:extra.fieldName,newValue:_emptyValue}); 
        }}><i className="fa fa-close"/></div>
      )
    }
  },
  renderStatus({disabled,cell,extra,row,more,errorMsg,component}={}){ 
    if (row && extra){
      let _fnList = CellTypeHelper.getFnList({extra,row});
      let _fieldName = extra.fieldName;
      let _configEditIcon = null;
      let _configLinkIcon = null;
      let _configFileIcon = null;
      let _canEditFieldEdit = true;
      let _showClear = null;
      let _emptyValue = null;
      let _linkIcon = null;
      let _showDeleteRow = false;
    
      if(more){ 
        _configEditIcon = CellTypeHelper.More.getConfigEditIcon({extra,row,more});
        _canEditFieldEdit = CellTypeHelper.getCanEdit({extra,row,fieldName:_configEditIcon.fieldName||_fieldName});
        _configLinkIcon = CellTypeHelper.More.getConfigLinkIcon({extra,row,more});
        _configFileIcon = CellTypeHelper.More.getConfigFileIcon({extra,row,more});
        console.warn("_configFileIcon:",more,_configFileIcon)
        _showClear = more.showClear;
        if(_showClear==null && HConfig.fnList.getConfig('tableCellShowClear')==true){
          _showClear = true;
        }
        _emptyValue = more.emptyValue!=null?more.emptyValue:null;  
        if(more.showDeleteRow){
          _showDeleteRow = true;
        }  
      }
      // console.warn("AA:",more,_configLinkIcon);
      if(_configLinkIcon!=null && _configLinkIcon.show==true){        
        if(extra.Type!=null && extra.Type.link!=null){
          _linkIcon = extra.Type.link;
        }
        if(_configLinkIcon.fLink && row[_configLinkIcon.fLink]){          
          _linkIcon = row[_configLinkIcon.fLink];
        }        
      }
      
      // console.warn("showClear:",_showClear);
      
      // row["_UI_Error"]= {};row["_UI_Error"][_fieldName] = {msg: "test"};
      // row["_UI_Loading"] = {}; row["_UI_Loading"][_fieldName] = true;

      return(
        <div className="mct-status no-print">          
          {
            (errorMsg!=null || (row["_UI_Error"] && row["_UI_Error"][_fieldName]!=null && row["_UI_Error"][_fieldName]["msg"]!=null)) &&
            <Tooltip placement="top" overlay={<div style={{maxWidth:'300px'}}>{errorMsg || row["_UI_Error"][_fieldName]["msg"]}</div>}>
              <div className="mct-status-error" onClick={()=>{
              }}>
                <i className="fa fa-exclamation-triangle ani-heartBeat ani-run infinite"/>
              </div>
            </Tooltip>            
          }
          {
            row["_UI_Loading"] && row["_UI_Loading"][_fieldName]==true &&
            <div className="mct-status-saving" onClick={()=>{
            }}>
              <i className="fa fa-spinner fa-spin"/>
            </div>
          }
          {
            more!=null && _configEditIcon!=null && _configEditIcon.using==true && _canEditFieldEdit==true &&
            <ComponentEdit configEditIcon={_configEditIcon} row={row} fieldName={_fieldName} onSubmit={(fieldName,newValue)=>{
              CellTypeHelper.callRequestUpdate({extra,row,fieldName:fieldName,newValue:newValue,component}); 
            }}/>            
          }
          {
            _linkIcon!=null && _linkIcon.length>0 &&
            <ComponentLink configLinkIcon={_configLinkIcon} linkIcon={_linkIcon} row={row} fieldName={_fieldName}/>
          }
          {
            _configFileIcon && _configFileIcon.show &&
            <ComponentFileIcon configFileIcon={_configFileIcon} row={row} fieldName={_fieldName} fnList={_fnList}/>
          }
          {
            _showClear == true && disabled!=true && cell!=null && cell!="" &&
            <div className="mct-status-clear" onClick={()=>{
              CellTypeHelper.callRequestUpdate({extra,row,fieldName:extra.fieldName,newValue:_emptyValue}); 
            }}><i className="fa fa-close"/></div>
          }
          {
            _showDeleteRow == true &&
            <ComponentBtnDeleteRow extra={extra} row={row} styleBtn={{padding:"0px 5px"}}/>
          }      
        </div>
      )
    }
  },
  renderIcon({extra,row,more,dfIconClassName}){
    let _ui = null;
    let _configIcon = CellTypeHelper.More.getConfigIcon({extra,row,more});
    if(_configIcon!=null){
      if(typeof _configIcon=="string"){
        _ui = (
          <i className={_configIcon}/>
        )
      }
      else if(typeof _configIcon =="object"){
        let _classNameIcon = _configIcon.className || dfIconClassName;
        let _styleIcon = _configIcon.style;
        _ui = (
          <i className={_classNameIcon} style={_styleIcon}/>
        )
      }
    }
    return _ui
  },
  renderRightBottom({cell,extra,row,more,component}){
    let _ui = null;
    let _configRightBottom = CellTypeHelper.More.getConfigRightBottom({extra,row,more});
    // console.warn("_configRightBottom:",_configRightBottom);
    if(_configRightBottom!=null && _configRightBottom.show==true && _configRightBottom.fData && row[_configRightBottom.fData]!=null && row[_configRightBottom.fData].length>0){
      let _text = row[_configRightBottom.fData];
      _ui = (
        <div className={classnames("mct-common-rb",_configRightBottom.className)} style={_configRightBottom.style}>
          {_text}
        </div>
      )
    }
    return _ui
  },
  renderOverlay({cell,extra,row,more,isHasValue,component}){
    let _ui = null;
    let _configOverlay = CellTypeHelper.More.getConfigOverlay({extra,row,more});
    // console.warn("_configOverlay:",_configOverlay,isHasValue);
    if(_configOverlay.alwayShow!==true && isHasValue===true){//mac dinh la ko show khi co gia tri
      return;
    }
    if(_configOverlay!=null && _configOverlay.show==true && _configOverlay.fData && row[_configOverlay.fData]!=null && row[_configOverlay.fData].length>0){
      let _text = row[_configOverlay.fData];
      _ui = (
        <div className={classnames("mct-common-overlay",_configOverlay.className)} style={_configOverlay.style}>
          {_text}
        </div>
      )
    }
    return _ui
  },
  renderBadge({cell,extra,row,more,count,component}){
    let _ui = null;
    let _configBadge = CellTypeHelper.More.getConfigBadge({extra,row,more,df:{show:true}});
    let _count = count;
    if(_configBadge && _configBadge.fCount){
      _count = row[_configBadge.fCount];
    }
    if(_configBadge!=null && _configBadge.show==true && _count>0){
      if(_configBadge.fTooltip && row[_configBadge.fTooltip]){
        return (
          <Tooltip placement="top" overlay={<div style={{maxWidth:'300px'}}>{row[_configBadge.fTooltip]}</div>}>
            <span className="badge-files">{_count}</span>
          </Tooltip> 
        )
      }
      return (
        <span className="badge-files">{_count}</span>
      )
    }
    return _ui
  },
  renderAddable({disabled,cell,extra,row,more,component,forceShowAddable}){ 
    let _showAddable = forceShowAddable || false;
    let _isHidden = true;    
    let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
    let _configAddable = CellTypeHelper.More.getConfigAddable({extra,row,more:_more});
    let _fnList = CellTypeHelper.getFnList({extra,row});
    if(disabled){
      _showAddable = false;
    }
    if(_configAddable){      
      if(_configAddable.show!=null){
        _showAddable = _configAddable.show;
      }
    }
    if (row && extra && _showAddable){
      let _fieldName = extra.fieldName;
      let _request = CellTypeHelper.More.getConfigRequest({extra,row,more:_more});
      if(_request.url || (_request.path && _request.name)){
        if(row[_fieldName]==null||row[_fieldName]==""||row[_fieldName]=="0"){
          _isHidden = false;
        }
        else{
          _isHidden = true;
        }
        if(_configAddable){      
          if(_configAddable.alwayShow==true){
            _isHidden = false;
          }
        }
        return(
          <div hidden={_isHidden} className="menu_bottomright no-print">      
            <ComponentAddable configAddable={_configAddable} fnList={_fnList} fName={_more.fName} onSubmit={(data,optionText)=>{                        
              let _queryCommon = CellTypeHelper.More.getQuery({extra,row,more:_more});    
              let _source = extra.Type!=null?extra.Type.source:"";        
              let _query = {
                Id: row.Id,
              };
              if(data){
                _query = Object.assign(_query,data,_queryCommon);              
              }
              HH().ApiGeneric.generic({
                request: _request,
                data: _query,
                successCallBack:(response)=>{
                  let _data = response.Data;
                  fnListUI.fnShowToast(response.Msg)
                  if(_data && _fnList && _fnList.fnAddOptions){
                    let _optionsText = optionText;
                    let _optionsValue = response.Data[_fieldName];
                    if(_configAddable.fOptionsText){
                      _optionsText = _data[_configAddable.fOptionsText];
                    }
                    if(_configAddable.fOptionsValue){
                      _optionsValue = _data[_configAddable.fOptionsValue];
                    }
  
                    _fnList.fnAddOptions({
                      source: _source,
                      value: _optionsValue,
                      text: _optionsText
                    });
                  }
                  if(_fnList && _fnList.fnReloadTable){
                    _fnList.fnReloadTable();
                  }                
                },
                errorCallBack:(error,response)=>{
                }
              })
            }} />
          </div>
        )
      }
    }
  },
  //show modal (table/form) when click an icon in call
  renderModalExpand({extra,row,more}){
    let _expand = more.expand;
    if (_expand){
      let _configModal = this.More.getConfigModal({extra:extra,row:row,more:more});
      let _key = _configModal.key || "modal_expand_table";
      let _title = _configModal.title || "Modal Expand Table";
      let _classNameModal = _configModal.className || 'modal-lg';
      let _type = _expand.type  || "table";
      if (_type=="table"){
        let _fields = _expand.fields;
        return(
          <div className="mct-expand-btn" 
            onClick={()=>{
              MyModal.Helper.showFromType("cell_expand_table",{
                key: _key,
                title: _title,
                className: _classNameModal,
                modalProps:{
                  extra:extra,
                  row:row,
                  fields:_fields,
                },
                ..._configModal       
              })
          }}>
          <i className="fa fa-chevron-right"/></div>
        )
      }else if(_type=="form"){
        let _form = _expand.form;
        let _autoSubmit = true;
        if (_expand.autoSubmit!=null){
          _autoSubmit = _expand.autoSubmit;
        }
        let _showButtonSave = false;
        if (_expand.showButtonSave!=null){
          _showButtonSave = _expand.showButtonSave
        }
        return(
          <div className="mct-expand-btn" 
            onClick={()=>{
              MyModal.Helper.showFromType("cell_expand_form",{
                key: _key,
                title: _title,
                className: _classNameModal,
                modalProps:{
                  extra:extra,
                  row:row,
                  form:_form,
                  autoSubmit:_autoSubmit,
                  showButtonSave:_showButtonSave,
                },
                ..._configModal       
              })
          }}>
          <i className="fa fa-chevron-right"/></div>
        )
      }
    }
  },
  renderLoading({extra,row,isShow}){
    if(isShow){
      return (
        <i className={"fa fa-spinner fa-spin"} />
      )
    }
  },
  renderExpandArea({extra,row,more,component}={}){
    if(more && more.configExpandArea){
      let _configExpandArea = CellTypeHelper.More.getConfigByKeyInMore({extra,row,more,key:"configExpandArea"});
      if(_configExpandArea && _configExpandArea.show===true){//tuong duong more.hasExpandArea
        let _fnList = CellTypeHelper.getFnList({extra,row}); 
        let _ui = [];
        // console.warn("_item 1:",_configExpandArea);
        if(_configExpandArea.listExpand && _configExpandArea.listExpand.length>0){
          let _fnShowExpand = _fnList.fnShowExpand;
          let _fnIsShowExpand = _fnList.fnIsShowExpand;
          for(let i=0;i<_configExpandArea.listExpand.length;i++){
            let _item = _configExpandArea.listExpand[i];
            // console.warn("_item:",_item);
            CellTypeHelper.prepareRowExpand({extra,row,more,objExpand:_item});
            let _canShow = true;
            if(_canShow){
              let _isShow = _fnIsShowExpand!=null?_fnIsShowExpand({row,item:_item}):false;
              _ui.push(
                <Button 
                  c={_item.controller} key={i} color="link" outline 
                  style={{padding:'0px',border:'0px',width:'30px',fontSize:'20px',..._item.styleButton}} 
                  onClick={()=>{
                    if(_fnShowExpand){
                      _fnShowExpand({row,cc: component,item:_item});
                    }
                  }}>
                    <i className={_isShow?(_item.iconCollapse||"fa fa-chevron-circle-up"):(_item.iconExpand||"fa fa-chevron-circle-down")}/>
                  </Button>
              )
            }
          }
        }
        if(_ui.length>0){
          return (
            <div style={{display:'flex',flexDirection:'row'}}>
              {_ui}
            </div>
          )
        }
      }
    } 
  },
  renderEmpty({extra,row,more,style,df}){
    let _text = CellTypeHelper.getContentWhenEmpty({extra,row,more,df});
    return (
      <div style={style}>{_text}</div>
    )
  },
  renderCustomUICell({cell,extra,row,more}){
    const _configCustomUICell = HUtils.Obj.get(more, "customUICell");
    const _fnList = CellTypeHelper.getFnList({extra,row,more})
    if(_configCustomUICell){
      return <ComponentCustomUICell cell={cell} row={row} extra={extra} config={_configCustomUICell} fnList={_fnList}/>
    }
  },
  isNeedUpdateValueAfterWillReceiveProps(props,nextProps,df=""){
    const {cell,row,extra} = props;
    const newCell = nextProps.cell;
    // if(row.Id=="8586"){
    //   console.warn("isNeedUpdateValueAfterWillReceiveProps",cell,newCell);
    // }
    if(row && extra && row._UI_NeedUpdate){
      let _fieldName = extra.fieldName;
      if(_fieldName && row._UI_NeedUpdate.hasOwnProperty(_fieldName)){
        let _value = row._UI_NeedUpdate[_fieldName];
        delete row._UI_NeedUpdate[_fieldName];
        return {
          needUpdate: true,
          value: _value
        };
      }
    }
    if(newCell!=null && cell!=null && newCell!=cell){
      // console.warn("isNeedUpdateValueAfterWillReceiveProps zzzz",cell,newCell);
      return {
        needUpdate: true,
        value: newCell
      };      
    }
    else if(cell==null && newCell!=cell){
      return {
        needUpdate: true,
        value: newCell
      };
    }
    else if(newCell==null && newCell!=cell){
      return {
        needUpdate: true,
        value: df
      };
    }
    return {
      needUpdate: false,
      newCell: newCell
    }
  },
  updateRowWithData({row,data,fieldId}={}){
    if(row && data && row[fieldId]==data[fieldId]){
      for(let _key of Object.keys(data)){
        if(_key.startsWith('_')==false){
          row[_key] = data[_key];
        }
      }
    }
    // row.btn_GetMessegerAgain = "AAA";
    // row.Match_CustomerName = "AAABBB";
    // row.IsAutoMatched = true;
    // row.LinkToChat = "http://google.com";
    // console.log("updateRowWithData",row,data);
  },
  getQuery({row,query,df}){
    let _query = df || {};
    if(query){
      _query = Object.assign(_query,query);
      let _keys = Object.keys(_query);
      for(let k of _keys){
        let _value = query[k];
        if(row.hasOwnProperty(_value)==true){
          if (row[_value]!=null){
            _query[k] = row[_value];
          }
        }          
        else{
          _query[k] = _query[k];
        }
      }
    }    
    return _query;
  },
  getRequest({row,configApi}){
    let _request = {
      method: 'POST' || configApi.method,
      url: configApi.url,
      path: configApi.path,
      name: configApi.name 
    };
    if(_request.url){
      if(_request.url.indexOf("[")>-1){
        _request.url = HUtils.SquareBracket.replace(_request.url,row);
      }
    }
    if(_request.name){
      if(_request.name.indexOf("[")>-1){
        _request.name = HUtils.SquareBracket.replace(_request.name,row);
      }
    }
    return _request;
  },
  getIsHideWhenEmpty({extra,cell,row,more,df}){
    let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
    let _isHideWhenEmpty = df || false;
    if(_more.hideWhenEmpty==true){
      _isHideWhenEmpty = true;
    }
    if(_isHideWhenEmpty && (cell==null || cell=="")){//cell=="" => "", 0 deu bang true
      return true;
    }
    return false;
  },
  getIsShowWhenEmpty({extra,cell,row,more,df}){
    let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
    let _isShowWhenEmpty = df || false;
    if(_more.showWhenEmpty==true){
      _isShowWhenEmpty = true;
    }
    if(_isShowWhenEmpty && (cell==null || cell=="")){//cell=="" => "", 0 deu bang true
      return true;
    }
    return false;
  },
  getContentWhenEmpty({extra,row,more,df}){
    let _more = more || CellTypeHelper.getMoreInType({extra:extra,row:row});
    let _text = df!=null?df:"";
    if(_more.fShowWhenEmpty && row[_more.fShowWhenEmpty]){
      _text = row[_more.fShowWhenEmpty];
    }
    return _text;
  },
  checkShowModal({extra,row,more,fromComponent,configModal,cb}){
    let _more = more || CellTypeHelper.getMoreInType({extra,row});
    let _fnList = CellTypeHelper.getFnList({extra,row});
    let _configModal = configModal || CellTypeHelper.More.getConfigModal({extra:extra,row:row,more:_more});
    let _canShowModal = CellTypeHelper.getCanShowModal({extra,row,more:_more,configModal:_configModal});
    // console.warn("_canShowModal:",_canShowModal);
    if(cb){
      if(_canShowModal){
        let _configApiCheckShowModal = CellTypeHelper.More.getConfigByKeyInMore({extra,row,more:_more,key:HConstant.ConfigInMore.apiCheckShowModal})
        if(_configApiCheckShowModal){
          let _request = CellTypeHelper.getRequest({row,configApi:_configApiCheckShowModal});
          let _query = CellTypeHelper.getQuery({row,query:_configApiCheckShowModal.query});    
          MyModal.Helpers.checkShowModal({canShowModal:_canShowModal,configApiCheckShowModal:_configApiCheckShowModal,apiRequest:_request,apiQuery:_query,row,fnList:_fnList,fromComponent,cb});
          // fromComponent.setState({
          //   isLoading: true
          // },()=>{
          //   SApiGeneric.generic({
          //     request: _request,
          //     data: _query,
          //     successCallBack:(response)=>{
          //       let _data = response.Data;
          //       if(_data && _data.Config && _data.Config.IsShow==true){
          //         cb({
          //           canShow: true,
          //           config: _data.Config
          //         })
          //       }
          //       else{
          //         let _resultModel = HUtils.Obj.get(response,"Data.Config.ResultModel");
          //         if(_resultModel){
          //           HUtils.updateDataWithNewData(row,_resultModel,{fieldId:'Id'});
          //           if(_fnList.fnForceUpdateTable){
          //             _fnList.fnForceUpdateTable();
          //           }
          //         }
          //         cb({
          //           canShow: false,
          //           response: response
          //         })
          //       }    
          //       fromComponent.setState({
          //         isLoading: false
          //       });                     
          //     },
          //     errorCallBack:(error,response)=>{
          //       fromComponent.setState({
          //         isLoading: false
          //       });
          //       cb({
          //         canShow: false
          //       })
          //     }
          //   })
          // })                   
        }
        else{
          cb({
            canShow: true
          })
        } 
      }
      else{
        cb({
          canShow: false
        })
      }
    }   
  },
}

export {
  CellTypeHelper as CellHelper,
  Global,
  Options,
  M,
  UI,
};
export default CellTypeHelper
