const Encode = {
    Hash:{
        listHashCode:{},
        hashCode(str) {
          if(str==null || str.length==0){
            return "00";
          }
          if(Encode.Hash.listHashCode[str]){        
            return Encode.Hash.listHashCode[str];
          }
          var hash = 5381,i = str.length;
          while(i) {
            hash = (hash * 33) ^ str.charCodeAt(--i);
          }
          /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
           * integers. Since we want the results to be always positive, convert the
           * signed int to an unsigned by doing an unsigned bitshift. */
          let _result = hash >>> 0;
          Encode.Hash.listHashCode[str] = _result;
          return _result;
        },
      },
}

export default Encode