import Text from './Text';
import ApiGeneric from '../services/generic'
import LocalStorage from './LocalStorage';
import HConstant from './Constant'
import HLink from '../helpers/Link';
import { HCF} from "@macashipo/mlib"

const SettingKey = "GlobalSetting";
// const IS_CHANGE_PASSWORD = 'IS_CHANGE_PASSWORD';
// const MSG_CHANGE_PASSWORD = 'MSG_CHANGE_PASSWORD';
const Setting = {
  HConfig: null,
  DefaultHomeURL: null,
  IsNeedChangePassword: false,
  MsgNeedChangePassword: "You must change password to continue",
  PublicLinks: [],//danh sach controller PublicLinks
  IsNeedReloadAfterRequestFirstSetting: false,
  IsGettedFirstSetting: false,
  getHConfig(){
    if(Setting.HConfig){
      return Setting.HConfig;
    }
  },
  initFromLocal(Config){
    Setting.HConfig = Config;
    //Lay localStorage SettingKey ls.getItem(SettingKey) > stringJson
    // -> Object = JSON.parse
    // Co object roi -> Text.updateFromList(obj.TextDisplatySetting)
    // Xu ly luon DefaultHomeURL-> Setting.DefaultHome = obj.Defh
    let _lcSettingKey = localStorage.getItem(SettingKey);
    try {
      let _obj = JSON.parse(_lcSettingKey);
      // let _textDisplaySetting = Text.updateFromList(_obj.TextDisplaySettings);
      Setting.parseFirstSetting(_obj);
    } catch (error) {
      console.log('error', error);
    }
  },
  clearNeedChangePassword(){
    if (Setting.IsNeedChangePassword==true){
      Setting.IsNeedChangePassword = false;
      try {
        let _obj = JSON.parse(localStorage.getItem(SettingKey));  
        if (_obj!=null && _obj["IsNeedCheckChangePass"]!=null){
          _obj["IsNeedCheckChangePass"] = false;
          localStorage.setItem(SettingKey,JSON.stringify(_obj)) ;
        }
      } catch (error) {
        console.warn('catch error',error);
      }
    }
  },
  beforeLogout(){
    Setting.IsNeedChangePassword = false;
  },
  afterLogin(){
    Setting.requestRemindChangePassword(true);
  },
  getIsFirst(){
    let _isFirst = LocalStorage.getString("_IsFirst",null);
    let _isHasGlobalSetting = (localStorage.getItem(SettingKey) != null);
    console.log("getIsFirst:",_isFirst,_isHasGlobalSetting);
    if(_isFirst!=null || _isHasGlobalSetting==true || Setting.IsGettedFirstSetting){      
      return false;
    }
    LocalStorage.setString("_IsFirst","1");
    return true;
  },
  setIsNeedReloadAfterRequestFirstSetting(value){
    Setting.IsNeedReloadAfterRequestFirstSetting = value;
  },
  parseFirstSetting(data){
    // console.warn('config data', data);
    if(data){
      HCF.setCFMultiObj(data)
      if(data.DefaultValues){
        HCF.setCFMultiObj(data.DefaultValues)
      }
    }
    const HConfig = Setting.getHConfig();
    if(HConfig && data){
      Setting.DefaultHomeURL = data.DefaultHomeURL;
      if(Setting.DefaultHomeURL){
        HConfig.home_url = Setting.DefaultHomeURL;
      }
      let _isUpdateTextDisplay = true;
      if(data.IsUpdateTextDisplay!=null){
        _isUpdateTextDisplay = data.IsUpdateTextDisplay;
      }
      if(_isUpdateTextDisplay===true){
        Text.updateFromList(data.TextDisplaySettings);
      }   
      if(data.MediaAcceptedType){
        HConfig.fileUploadSupport = data.MediaAcceptedType;
      }   
      if(data.BackgroundUrl){
        document.body.style.backgroundImage = `url(${data.BackgroundUrl})`;
        document.body.style.backgroundSize = `cover`;
      }      
      if(data.IsNeedCheckChangePass!=null){
        Setting.IsNeedChangePassword = data.IsNeedCheckChangePass;
        if (data.Message_IsNeedChangePass){
          Setting.MsgNeedChangePassword = data.Message_IsNeedChangePass;
        }
        Setting.requestRemindChangePassword();
      }
      if(data.PublicApiScreenList){
        Setting.PublicLinks = data.PublicApiScreenList;
      }
      if(data.ListFunctions){
        HConfig.listFunctions = data.ListFunctions;
      } 
      if(data.DefaultValues){
        let _defaultFromConfig = HConfig.DefaultValues;
        let _keys = Object.keys(data.DefaultValues);
        for(let k of _keys){
          _defaultFromConfig[k] = data.DefaultValues[k];
        }

        //check ExpandSideMenu
        if(_defaultFromConfig['expandSideMenu']===true){
          console.warn("need expand!!!!");
          if(document && document.body && document.body.classList && document.body.classList.contains("sidebar-hidden")){
            document.body.classList.remove("sidebar-hidden");
          }
        }
      }
      if(data.UIAppConfig && typeof data.UIAppConfig == "object"){
        HConfig.fnList.updateUIAppConfig(data.UIAppConfig);
      }
    }
    HConstant.rc(HConstant.AppFnList.afterParseFirstSetting,[data]);
    // console.warn(`CONFIG`,Config,Setting)
  },
  requestGlobalSetting(){
    const HConfig = Setting.getHConfig();
    if(HConfig && HConfig.fnList.checkValidConfigApp()){
      let _list = Text.getTextDisplaySettings();
      ApiGeneric.generic({
        request:{
          method: 'POST',
          path: 'GlobalAppSetting',
          name: 'FisrtSetting'
        },
        data:{
          TextDisplaySettings: _list,
          UIAppConfig: HConfig.UIAppConfig
        },
        successCallBack:(response)=>{
          if(response.Data!=null){                        
            Setting.parseFirstSetting(response.Data);
            try {
              let _dataString = JSON.stringify(response.Data);
              localStorage.setItem(SettingKey,_dataString);
            } catch (error) {
              console.warn('catch error',error);
            }
            Setting.IsGettedFirstSetting = true;
            if(Setting.IsNeedReloadAfterRequestFirstSetting){
              window.location.reload();
              console.warn("Need reload");
            }
            HConstant.rc(HConstant.AppFnList.afterRequestFirstSetting,[response]);
          }
        },
        errorCallBack:(error,response)=>{

        }
      })
    }    
  },
  requestRemindChangePassword(isNeedCallApi=false){
    // console.warn('requestRemindChangePassword',Setting.IsNeedChangePassword,);
    let _pathName = window.location.hash.replace('#',"");
    let _pathMyProfile = "/my-profile?returnUrl="+_pathName;
    if (Setting.IsNeedChangePassword==true){
      if (window.location.href.indexOf("my-profile")==-1){
        HLink.push(_pathMyProfile);
      }
    }
    if (isNeedCallApi==true){
      ApiGeneric.generic({
        request:{
          method: 'POST',
          path: 'MyProfileUserPassword',
          name: 'CheckNeedChangePassword'
        },
        data:{},
        successCallBack:(response)=>{
          // console.warn('success');
          // console.warn('Remind Change Password: ', response);
          if(response.Data!=null){
            if (response.Data){
              let _data = response.Data;
              // console.warn('requestRemindChangePassword data', _data);
              let _isNeedChangePassword = _data.IsNeedChangePassword;
              // _isNeedChangePassword = true; //test
              Setting.IsNeedChangePassword = _isNeedChangePassword;
              Setting.MsgNeedChangePassword = _data.Msg;
              // localStorage.setItem(IS_CHANGE_PASSWORD, _isNeedChangePassword);
              // localStorage.setItem(MSG_CHANGE_PASSWORD, _data.Msg);
              if (_isNeedChangePassword==true){
                if (window.location.href.indexOf("my-profile")==-1){
                  HLink.push(_pathMyProfile);
                }
              }
            }            
          }
        },
        errorCallBack:(error,response)=>{
  
        }
      })
    }
  }
}
export default Setting;