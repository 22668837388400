const Form={       
  Title: '',
  DefaultValues:{
    row21:'22284C/V.F.C',
    row22:'VN0001MB',
    row16:'TCNU22121997',
    row5:'WOODEN FURNITURE',
    row6:'8,328.00 KGS; ',
    row7:'B/L No. V22121997TTTT',
    row81:'VIETNAM',
    row82:'HO CHI MINH CITY, VIETNAM',
    row83:'AUSTRALIA',
    row91:'INTERWOOD LIMITED',
    row92:'MORROW MARKETING',
    row11_1:'JUL, 05, 2014',
    row11_2:'TANAMEXCO PORT/CY',
    row12_1:'48 GRS/M3',
    row12_2:'24 HOURS',
    row13_1:'25 độ C',
    row13_2:'48 GRS/M3',
    row18_2:'5',
    row22_2_1:'JUL. 06,2014',
    row22_2_2:'VN-AF045'
  },
  Styles:{
    // Direction: 'row',
    Form:{
      padding: '0px',
      c_padding: '0px',
      border:'0px solid',
    },
    Label:{
      // color: 'blue', 
      // textAlign: 'left', 
    },
    Cell:{
      marginBottom: '0px', 
      // backgroundColor:'white',

    }
  },
  Data: [
    //ROW 1
    {
      Type: 'readonly',
      Label: 'AFAS - METHYL BROMIDE FUMIGATION CERTIFICATE',
      Props: {
      },
      FieldName: 'row1',    
      Styles:{
        Label:{
          width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          textAlign:'center',
          fontWeight:'bold',
          fontSize:'x-large',
        },
      },                      
    },
    //ROW 2
    {
      Title: '',
      Styles:{
        Direction: 'row',
        Label:{
          display:'flex',
          justifyContent:'center',
          textAlign:'left',
          fontWeight:'bold',
          marginLeft:'5px'
        },
        Control:{
          fontWeight:'bold',
        },
      },
      Data:[
        {
          Type: 'text',
          Label: 'Certificate number:',
          FieldName: 'row21',  
          Styles:{
            Label:{

            },
            Control:{
              width: '50%',
              border:'1px solid black'
            },
            Cell: {
              width: '50%',  
            },
          },                    
        },
        {
          Type: 'text',
          Label: 'AEI number:',
          Props: {
          },
          FieldName: 'row22',    
          Styles:{
            Label:{
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center'
            },
            Control:{
              width: '50%',
              border:'1px solid black'
            },
            Cell: {
              width: '50%',  
            },
          },                      
        },  
      ],
    },
    //ROW 3
    {
      Type: 'readonly',
      Label: 'TARGER OF FUMIGATION DETAILS',
      Props: {
      },
      FieldName: 'row3',    
      Styles:{
        Label:{
          width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          textAlign:'center',
          fontWeight:'bold',
          fontSize:'larger',
        },
        Cell:{
          borderBottom:'1px solid black'
        },
        
      },                      
    },
    //ROW 4
    {
      Title: '',
      Styles:{
        Direction: 'col',
        Label:{
        },
        Cell:{
          height:'50px',
          display:'flex',
          justifyContent:'center'
        }
      },
      Data:[
        {
          Type: 'readonly',
          Label: 'Target of fumigation:',
          Props: {
          },
          FieldName: 'row41',      
          Styles:{
            Label:{
              fontWeight:'inherit',
              marginLeft:'5px',
              display:'flex',
              alignItems:'center',
              height:'100%',
            },
            Cell: {
              width: '30%',  
            },
          },  
        },
        {
          Type: 'listcheckbox',
          Label: '',
          Props: {  
            direction:'row',
            marginright:'80px'          
          },
          Required: false,
          FieldName: 'row42',
          SourceList: [
            {value:'commodity',text:'Commodity'},
            {value:'packing',text:'Packing'},
            {value:'both',text:'Both Commodity and Packing'},
          ], 
          Styles:{
            Direction:'row',
            Label:{
            },
            Cell: {
              width: '58%',  
              // marginLeft:'20px'
            },
          },                
        },           
      ],
    },
    //ROW 5
    {
      Type: 'text',
      Label: 'Commodity:',
      FieldName: 'row5',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%%'
        }
      },                    
    },
    //ROW 6
    {
      Type: 'text',
      Label: 'GROSS WEIGHT:',
      FieldName: 'row6',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%'
        }
      },                    
    },
    //ROW 7
    {
      Type: 'text',
      Label: 'Consignment link: ',
      FieldName: 'row7',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%'
        }
      },                    
    },
    //ROW 8
    {
      Title: '',
      Styles:{
        Direction: 'row',     
        Label:{
          display:'flex',
          justifyContent:'flex-start'
        }, 
        Control:{
          
        } , 
        Form:{
          border:'0px solid'
        } 
      },
      Data:[
        //row 81
        {
          Type: 'text',
          Label: 'Country of origin: ',
          FieldName: 'row81',  
          Styles:{
            Label:{
              fontWeight:'inherit',
              textAlign:'left',
              width:'25%',
            },
            Control:{
              width: '75%',
            },
            Cell:{
              width:'30%',

            }
          },                    
        },
        //row 82
        {
          Type: 'text',
          Label: 'Port of loading: ',
          FieldName: 'row82',  
          Styles:{
            Label:{
              fontWeight:'inherit',
              textAlign:'left',
              width:'15%'
            },
            Control:{
              width: '85%',
            },
            Cell:{
              width:'40%'
            }
          },                    
        },
        //row 83
        {
          Type: 'text',
          Label: 'Country of destination: ',
          FieldName: 'row83',  
          Styles:{
            Label:{
              fontWeight:'inherit',
              textAlign:'left',
              width:'25%'
            },
            Control:{
              width: '75%',
            },
            Cell:{
              width:'30%'
            }
          },                    
        },
      ],
    },
    //ROW 9
    {
      Title: '',
      Styles:{
        Direction: 'row',     
        Label:{
        },   
        Form:{
          borderTop:'1px solid black',
          borderBottom:'1px solid black'
        } 
      },
      Data:[
        //row 91
        {
          Type: 'textarea',
          Label: 'Name and address of exporter: ',
          FieldName: 'row91',  
          Styles:{
            Direction:'col',
            Label:{
              fontWeight:'inherit',
              textAlign:'left',
              width:'100%'
            },
            Control:{
              width: '97%',
            },
            Cell:{
              width:'45%',
              borderRight:'1px solid black'
            }
          },                    
        },
        //row 92
        {
          Type: 'textarea',
          Label: 'Name and address of importer: ',
          FieldName: 'row92',  
          Styles:{
            Direction:'col',
            Label:{
              fontWeight:'inherit',
              textAlign:'left',
              width:'100%',
              marginLeft:'5px',
            },
            Control:{
              width: '86%',
              marginLeft:'5px',
            },
            Cell:{
              width:'55%'
            }
          },                    
        },
      ],
    },
    //ROW 10
    {
      Type: 'readonly',
      Label: 'TREATMENT DETAILS',
      Props: {
      },
      FieldName: 'row3',    
      Styles:{
        Label:{
          width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          textAlign:'center',
          fontWeight:'bold',
          fontSize:'larger',
        },
        Cell:{
          borderBottom:'1px solid black'
        },
        
      },                      
    },
    //ROW 11
    {
      Title: '',
      Styles:{
        Direction: 'row',
        Label:{
          display:'flex',
          justifyContent:'center',
          textAlign:'left',
          fontWeight:'inherit',
          marginLeft:'5px',
          width:'30%'
        },
        Control:{
          width:'78%'
        }
      },
      Data:[
        {
          Type: 'text',
          Label: 'Date fumigation completed::',
          FieldName: 'row11_1',  
          Styles:{
            Label:{

            },
            Cell: {
              width: '50%',  
            },
          },                    
        },
        {
          Type: 'text',
          Label: 'Place of fumigation:',
          Props: {
          },
          FieldName: 'row11_2',    
          Styles:{
            Label:{
            },
            Cell: {
              width: '50%',  
            },
          },                      
        },  
      ],
    },
    //ROW 12
    {
      Title: '',
      Styles:{
        Direction: 'row',
        Label:{
          display:'flex',
          justifyContent:'center',
          textAlign:'left',
          fontWeight:'inherit',
          marginLeft:'5px',
          width:'30%'
        },
        Control:{
          width:'78%'
        }
      },
      Data:[
        {
          Type: 'text',
          Label: 'AQIS prescribed dose rate (g/m3): ',
          FieldName: 'row12_1',  
          Styles:{
            Label:{

            },
            Cell: {
              width: '50%',  
            },
          },                    
        },
        {
          Type: 'text',
          Label: 'EXposure period (hrs): ',
          Props: {
          },
          FieldName: 'row12_2',    
          Styles:{
            Label:{
            },
            Cell: {
              width: '50%',  
            },
          },                      
        },  
      ],
    },
    //ROW 13
    {
      Title: '',
      Styles:{
        Direction: 'row',
        Label:{
          display:'flex',
          justifyContent:'center',
          textAlign:'left',
          fontWeight:'inherit',
          marginLeft:'5px',
          width:'30%'
        },
        Control:{
          width:'78%'
        }
      },
      Data:[
        {
          Type: 'text',
          Label: 'Forecast minimum temp (độ C): ',
          FieldName: 'row13_1',  
          Styles:{
            Label:{

            },
            Cell: {
              width: '50%',  
            },
          },                    
        },
        {
          Type: 'text',
          Label: 'Applied dose rate (g/m3): ',
          Props: {
          },
          FieldName: 'row13_2',    
          Styles:{
            Label:{
              
            },
            Cell: {
              width: '50%',  
            },
          },                      
        },  
      ],
    },
    //ROW 14
    {
      Title: '',
      Styles:{
        Direction: 'col',
        Label:{
        },
        Cell:{
          display:'flex',
          justifyContent:'center'
        },
        Form:{
          borderTop:'1px solid black',
          borderBottom:'0px solid',
          borderLeft:'0px solid',
          borderRight:'0px solid'
        }
      },
      Data:[
        {
          Type: 'readonly',
          Label: 'How was the fumigation conducted:',
          Props: {
          },
          FieldName: 'row41',      
          Styles:{
            Label:{
              fontWeight:'inherit',
              display:'flex',
              alignItems:'center',
              height:'100%',
            },
            Cell: {
              width: '40%',  
            },
          },  
        },
        {
          Type: 'listcheckbox',
          Label: '',
          Props: {  
            direction:'row',
            marginright:'100px'          
          },
          Required: false,
          FieldName: 'row42',
          SourceList: [
            {value:'stack',text:'Stack under sheet'},
            {value:'container',text:'Container/s undre sheet'},
          ], 
          Styles:{
            Direction:'row',
            Label:{
            },
            Cell: {
              width: '55%',  
              marginLeft:'20px'
            },
          },                
        },           
      ],
    },
    //ROW 15
    {
      Title: '',
      Styles:{
        Direction: 'col',
        Label:{
        },
        Cell:{
          display:'flex',
          justifyContent:'center'
        }
      },
      Data:[
        {
          Type: 'readonly',
          Label: '',
          Props: {
          },
          FieldName: 'row41',      
          Styles:{
            Label:{
              fontWeight:'inherit',
              marginLeft:'5px',
              display:'flex',
              alignItems:'center',
              height:'100%',
            },
            Cell: {
              width: '40%',  
            },
          },  
        },
        {
          Type: 'listcheckbox',
          Label: '',
          Props: {  
            direction:'row',
            marginright:'100px'          
          },
          Required: false,
          FieldName: 'row42',
          SourceList: [
            {value:'parmament',text:'Permament Chamber'},
            {value:'pressure',text:'Pressure tested container/s'},
          ], 
          Styles:{
            Direction:'row',
            Label:{
            },
            Cell: {
              width: '55%',  
              marginLeft:'20px'
            },
          },                
        },           
      ],
    },
    //ROW 16
    {
      Type: 'text',
      Label: 'Container number/s (where applicable):',
      FieldName: 'row16',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'15%',
          marginLeft:'5px',
        },
        Control:{
          width: '91%',
          fontWeight:'bold'
        },
        Cell: {
          width: '100%',  
        },
      },                    
    },
    //ROW 17
    {
      Title: '',
      Styles:{
        Direction: 'col',
        Label:{
        },
        Cell:{
          display:'flex',
          justifyContent:'center',

        },
        Form:{
          borderTop:'1px solid black',
          borderBottom:'1px solid black',
          borderLeft:'0px solid',
          borderRight:'0px solid',
        }
      },
      Data:[
        {
          Type: 'readonly',
          Label: 'Does the terget of the fumigation conform to the AQIS plastic wrapping and imperious surface requirements at the time of fumigation?',
          Props: {
          },
          FieldName: 'row41',      
          Styles:{
            Label:{
              fontWeight:'inherit',
              display:'flex',
              alignItems:'center',
              height:'100%',
            },
            Cell: {
              width: '60%',  
            },
          },  
        },
        {
          Type: 'listcheckbox',
          Label: '',
          Props: {  
            direction:'row',
            marginright:'10px'          
          },
          Required: false,
          FieldName: 'row42',
          SourceList: [
            {value:'yes',text:'Yes'},
            {value:'no',text:'No'},
          ], 
          Styles:{
            Direction:'row',
            Label:{
            },
            Cell: {
              width: '37%',  
              marginLeft:'20px'
            },
          },                
        },           
      ],
    },
    //ROW 18
    {
      Title: '',
      Styles:{
        Direction: 'row',
        Label:{
          display:'flex',
          justifyContent:'center',
          textAlign:'left',
          marginLeft:'5px',
          fontWeight:'inherit'
        },
      },
      Data:[
        {
          Type: 'readonly',
          Label: 'Ventilation',
          FieldName: 'row18_1',  
          Styles:{
            Label:{
              width:'100%',
              fontWeight:'bold',
            },
            Cell: {
              width: '10%',  
            },
          },                    
        },
        {
          Type: 'text',
          Label: 'Final TLV reading (ppm):',
          FieldName: 'row18_2',  
          Styles:{
            Label:{
              width:'30%',
            },
            Control:{
              width:'10%'
            },
            Cell: {
              width: '90%',  
            },
          },                    
        },
      ],
    },
    //ROW 19
    {
      Type: 'readonly',
      Label: 'DECLARATION',
      Props: {
      },
      FieldName: 'row3',    
      Styles:{
        Label:{
          width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          textAlign:'center',
          fontWeight:'bold',
          fontSize:'larger',
        },
        Cell:{
          borderBottom:'1px solid black',
        },
      },                      
    },
    //ROW 20
    {
      Type: 'readonly',
      Label: 'By signing below, I, the AFAS',
      Props: {
      },
      FieldName: 'row3',    
      Styles:{
        Label:{
          width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          textAlign:'center',
          fontWeight:'inherit'
        },
        Cell:{
          borderBottom:'1px solid black',
        },
      },                      
    },
    //ROW 21
    {
      Type: 'readonly',
      Label: 'ADDITIONAL DECLARATION',
      Props: {
      },
      FieldName: 'row3',    
      Styles:{
        Label:{
          width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          textAlign:'center',
          fontWeight:'bold',
          fontSize:'larger',
        },
        Cell:{
          borderBottom:'1px solid black',
        },
      },                      
    },
    //ROW 23
    {
      Type: 'textarea',
      Label: '',
      Props: {
      },
      FieldName: 'row3',    
      Styles:{
        Label:{
        },
        Control:{
          width:'100%'
        },
        Cell:{
        },
      },                      
    },
    //ROW 22
    {
      Title: '',
      Styles:{
        Direction: 'row',
        Label:{
          display:'flex',
          justifyContent:'unset',
          alignItems:'center',
          textAlign:'center',
          fontWeight:'inherit',
          marginLeft:'5px'
        },
        Cell:{
          height:'200px'
        }
      },
      Data:[
        {
          Type: 'readonly',
          Label: 'Signature\nName of Accredited Fumigator',
          FieldName: 'row22_1',  
          Styles:{
            Label:{
              whiteSpace:'pre',
              fontWeight:'inherit',
              width:'100%',
            },
            Cell: {
              width: '30%',  
            },
          },                    
        },
        {
          Title: '',
          Styles:{
            Direction: 'col',
            Label:{
              display:'flex',
              justifyContent:'unset',
              textAlign:'left',
              fontWeight:'inherit',
              marginLeft:'5px'
            },
            Form:{
              width:'40%',
              border:'0px solid'
            }
          },
          Data:[
            {
              Type: 'text',
              Label: '',
              FieldName: 'row22_2_1',  
              Styles:{
                Label:{

                },
                Control:{
                  width: '30%',
                  marginLeft:'35%',                    
                },
                Cell: {
                  height: '20%',  
                },
              },                    
            },
            {
              Type: 'text',
              Label: 'AFAS Accreditaion Number',
              Props: {
              },
              FieldName: 'row22_2_2',    
              Styles:{
                Label:{
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center',
                  alignItems:'center'
                },
                Control:{
                  width: '30%',
                  marginLeft:'35%',                    
                },
                Cell: {
                  height: '20%',  
                },
              },                      
            },  
          ],
        },
        {
          Type: 'readonly',
          Label: 'Company stamp',
          FieldName: 'row22_3',  
          Styles:{
            Label:{
              whiteSpace:'pre',
              fontWeight:'inherit',
              width:'100%',
            },
            Cell: {
              width: '30%',  
            },
          },                    
        }, 
      ],
    },
  ]
};

export default Form