import React from 'react'
import PropTypes from 'prop-types'
import {CellHelper} from '../Helpers';
import {classnames} from '../../../apps/Libs';
/**
 * Cell Type: readonly.
 *
 * @class readonly
 * @static
 * @namespace readonly
 * @memberof CellType
 * 
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.readonly
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component
   * 
  */
  more() {
  }

  render() {
    const {cell,row,extra} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _value = cell;
    if(_more.hasOwnProperty("fShow") || _more.hasOwnProperty("fDisplay")){
      _value = CellHelper.More.getTextDisplay({extra,row,cell,more:_more});
    }
    if(typeof cell == 'object' && cell!=null){
      return <div className={_className} style={_style}>{"[object]"}</div> 
    }
    return <div className={_className} style={_style}>{_value}</div>
  }
}

export default Z;