import React from 'react';
import {MyUIHelpers , CSuppense} from "@macashipo/mlib"
import LayoutSquare from '../../components2/MyUI/Types/layout_square'
import O from '../../components2/MyUI/TypesCore/o';
import T from '../../components2/MyUI/TypesCore/t';
import TCard from '../../components2/MyUI/TypesCore/t_card';
import TTable from '../../components2/MyUI/TypesCore/t_table';
import TTooltip from '../../components2/MyUI/TypesCore/t_tooltip';
import TInputCheck from '../../components2/MyUI/TypesCore/t_input_check';
import TInputText from '../../components2/MyUI/TypesCore/t_input_text';

const CFiles = React.lazy(() => import('../../components2/MyUI/TypesCell/c_files'));

const Div = React.lazy(() => import('./Types/div'));
const SelectList = React.lazy(() => import('./Types/selectlist'));
const SelectListCheck = React.lazy(() => import('./Types/selectlist_check'));
const Select2Box = React.lazy(() => import('./Types/select2box'));
const SolidpageHeaderButton = React.lazy(() => import('./Types/solid_pageheaderbutton'));
const SolidCardHeader = React.lazy(() => import('./Types/solid_cardheader'));
const SolidPageHeader = React.lazy(() => import('./Types/solid_pageheader'));
const HeaderStaff = React.lazy(() => import('./Types/header_staff'));
const DetailObj = React.lazy(() => import('./Types/detail_obj'));
const DetailTab = React.lazy(() => import('./Types/detail_tab'));
const DetailTable = React.lazy(() => import('./Types/detail_table'));
const Slider = React.lazy(() => import('./Types/slider'));
const FooterSign = React.lazy(() => import('./Types/footer_sign'));
const CardPhoto = React.lazy(() => import('./Types/card_photo'));
const SolidTableOptions = React.lazy(() => import('./Types/solid_table_options'));
const SolidEVMFooterSign = React.lazy(() => import('./Types/solid_evm_footer_sign'));
const SolidProjectComment = React.lazy(() => import('./Types/solid_projectcomment'));
const SolidProjectMenu = React.lazy(() => import('./Types/solid_project_menu'));
const CheckQuality = React.lazy(() => import('./Types/check_quality'));
const ItemQuality = React.lazy(() => import('./Types/item_quality'));
const SolidItemGallery = React.lazy(() => import('./Types/solid_item_gallery'));
const HTMLEditor = React.lazy(() => import('./Types/html_editor'));
const Rate = React.lazy(() => import('./Types/rate'));
const ListCheck = React.lazy(() => import('./Types/list_check'));

//Solid
const Card_solid_docs = React.lazy(() => import('./TypesSolid/card_solid_docs'));
const Card_solid_doc_item = React.lazy(() => import('./TypesSolid/card_solid_doc_item'));
const Card_solid_doc_item_group = React.lazy(() => import('./TypesSolid/card_solid_doc_item_group'));
const Card_solid_docs_group = React.lazy(() => import('./TypesSolid/card_solid_docs_group'));
const Card_solid_docs_group_item = React.lazy(() => import('./TypesSolid/card_solid_docs_group_item'));
const Card_solid_answercheck = React.lazy(() => import('./TypesSolid/card_solid_answercheck'));

const Card_card_solid_timetracking = React.lazy(() => import('./TypesSolid/card_solid_timetracking'));
const Card_solid_sitephoto = React.lazy(() => import('./TypesSolid/card_solid_sitephoto'));
const Card_solid_sitephoto_item = React.lazy(() => import('./TypesSolid/card_solid_sitephoto_item'));
const Card_solid_staffevaluation = React.lazy(() => import('./TypesSolid/card_solid_staffevaluation'));
const Card_solid_staffevaluation_item = React.lazy(() => import('./TypesSolid/card_solid_staffevaluation_item'));
const Cardrow_dev = React.lazy(() => import('./TypesSolid/cardrow_dev'));
const Cardrow_dev_item = React.lazy(() => import('./TypesSolid/cardrow_dev_item'));
const Button_solid_timetracking = React.lazy(() => import('./TypesSolid/button_solid_timetracking'));
const Button_solid_scantime = React.lazy(() => import('./TypesSolid/button_solid_scantime'));
const EP_solid_header_draw = React.lazy(() => import('./TypesSolid/ep_solid_header_draw'));
const CellSolidDeleteCustom = React.lazy(() => import('./TypesSolid/cell_solid_delete_custom'));
const CellCheckVerify = React.lazy(() => import('./TypesSolid/cell_check_verify'));
const FileManageDocFuntion = React.lazy(() => import('./TypesSolid/file_manage_doc_function'));

//component2
const PageHeader = React.lazy(() => import('../../components2/MyUI/Types/page_header'));
const PageHeaderSearch = React.lazy(() => import('../../components2/MyUI/Types/page_header_search'));
const PageFilter = React.lazy(() => import('../../components2/MyUI/Types/page_filter'));
const InputText = React.lazy(() => import('../../components2/MyUI/Types/input_text'));
const InputCheck = React.lazy(() => import('../../components2/MyUI/Types/input_check'));
// const Html = React.lazy(() => import('../../components2/MyUI/Types/html'));
const Box = React.lazy(() => import('../../components2/MyUI/Types/box'));
const Timeline_s1 = React.lazy(() => import('../../components2/MyUI/TypesMore/timeline_s1'));
const Select2 = React.lazy(() => import('../../components2/MyUI/Types/select2'));
const JQ_datetimepicker = React.lazy(() => import('../../components2/MyUI/Types/jq_datetimepicker'));
const JQ_datepicker = React.lazy(() => import('../../components2/MyUI/Types/jq_datepicker'));
const FilesManagerV2 = React.lazy(() => import('../../components2/MyUI/Types/files_manager_v2'));
const LayoutGrid = React.lazy(() => import('../../components2/MyUI/Types/layout_grid'));
const LayoutGridSquare = React.lazy(() => import('../../components2/MyUI/Types/layout_grid_square'));

const InputFormatFixed = React.lazy(() => import('../../components2/MyUI/Types/input_format_fixed'));
const InputFormatNumeral = React.lazy(() => import('../../components2/MyUI/Types/input_format_numeral'));
const InputDivEditable = React.lazy(() => import('../../components2/MyUI/Types/input_div_editable'));

const List = {
  div: Div,
  selectlist: SelectList,
  selectlist_check: SelectListCheck,
  select2box: Select2Box,
  solid_pageheaderbutton:SolidpageHeaderButton,
  solid_cardheader: SolidCardHeader,
  solid_pageheader: SolidPageHeader,
  header_staff: HeaderStaff,
  detail_obj: DetailObj,
  detail_tab: DetailTab,
  detail_table: DetailTable,
  slider: Slider,
  footer_sign: FooterSign,
  card_photo: CardPhoto,
  solid_table_options: SolidTableOptions,
  solid_evm_footer_sign: SolidEVMFooterSign,
  solid_projectcomment: SolidProjectComment,
  solid_project_menu: SolidProjectMenu,
  check_quality: CheckQuality,
  item_quality: ItemQuality,
  solid_item_gallery: SolidItemGallery,
  html_editor: HTMLEditor,
  rate: Rate,
  list_check: ListCheck,
  jq_datetimepicker: JQ_datetimepicker,
  jq_datepicker: JQ_datepicker,
  page_header: PageHeader,
  page_header_search: PageHeaderSearch,
  page_filter: PageFilter,
  box: Box,
  timeline_s1: Timeline_s1,
  select2: Select2,
  input_text: InputText,
  input_check: InputCheck,
  files_manager_v2: FilesManagerV2,
  layout_grid: LayoutGrid,
  card_solid_docs:Card_solid_docs,
  card_solid_doc_item:Card_solid_doc_item,
  card_solid_doc_item_group:Card_solid_doc_item_group ,
  card_solid_docs_group:Card_solid_docs_group ,
  card_solid_docs_group_item:Card_solid_docs_group_item,   
  card_solid_timetracking:Card_card_solid_timetracking,
  card_solid_sitephoto:Card_solid_sitephoto,
  card_solid_sitephoto_item:Card_solid_sitephoto_item,
  card_solid_staffevaluation:Card_solid_staffevaluation,
  card_solid_staffevaluation_item:Card_solid_staffevaluation_item,
  card_solid_answercheck:Card_solid_answercheck,    
  cardrow_dev:Cardrow_dev,
  cardrow_dev_item:Cardrow_dev_item,
  button_solid_timetracking:Button_solid_timetracking,
  button_solid_scantime:Button_solid_scantime,
  ep_solid_header_draw:EP_solid_header_draw,
  cell_solid_delete_custom:CellSolidDeleteCustom,
  layout_grid_square:LayoutGridSquare,
  cell_check_verify:CellCheckVerify,
  file_manage_doc_function:FileManageDocFuntion,
  c_files: CFiles,
  input_format_fixed: InputFormatFixed,
  input_format_numeral: InputFormatNumeral,
  input_div_editable: InputDivEditable,
}

const ListNotLazy = {
  layout_square: LayoutSquare,
  o: O,
  t: T,
  t_div: T,
  t_card: TCard,
  t_table: TTable,
  t_tooltip: TTooltip,
  t_input_check: TInputCheck,
  t_input_text:TInputText,

}
const MyUITypes = {
  init(){
    MyUIHelpers.addTypesFromListLazy(List)
    for(let k of Object.keys(ListNotLazy)){
      MyUIHelpers.addTypes(k,(props)=>{
        return CSuppense(props, ListNotLazy[k])
      })
    }
  }
}
MyUITypes.init();
export default MyUITypes;