import HUtils from './Utils';
import HLink from './Link';
import HC from './Constant';
import HLocalStorage from './LocalStorage'
import HColor from './Color'
import MyTableCellTypes from '../components2/MyTableCell/MyTableCellTypes'
import MyTableHeader from '../components/MyTableHeader/MyTableHeader'
import MyUIHelpers from '../components/MyUI/MyUIHelpers'

const A = {  
  ExtraData:{
    getColumns(extraData){
      return HUtils.Obj.get(extraData,"Columns");
    },
    getColumnsCanShow(extraData){
      let _columns = A.ExtraData.getColumns(extraData);
      let _colsCanShow = [];
      if(_columns){
        let _keys = Object.keys(_columns);
        for(let i=0; i<_keys.length; i++){
          const k = _keys[i];
          if(_columns[k].CanShow == true){
            _colsCanShow.push(k);
          }
        }
      }
      return _colsCanShow;
    },
    getConfigColumnHide(extraData,fieldName){
      let _configFilter = A.ExtraData.getExtraOfFieldByKey(extraData,fieldName, "Filter");
      if(_configFilter && _configFilter.more){
        if(_configFilter._moreObj==null){
          try {
            let _moreObj = JSON.parse(_configFilter.more);
            _configFilter._moreObj = _moreObj;
          } catch (error) {
            HUtils.logErrorParseJson(_configFilter);
          }
        }        
        if(_configFilter._moreObj && _configFilter._moreObj.columnHide){
          return _configFilter._moreObj.columnHide;
        }
      }
    },
    getExtraOfColumn(extraData, fieldName){
      const _columns = A.ExtraData.getColumns(extraData);
      return HUtils.Obj.get(_columns,fieldName); 
    },
    getExtraOfFieldByKey(extraData, fieldName, key){      
      const _columns = A.ExtraData.getColumns(extraData);
      if(_columns && _columns[fieldName]){
        const _fieldConfig = _columns[fieldName];
        if(_fieldConfig[key]!=null){
          return _fieldConfig[key]
        }
      }
      return null;
    },
    getCanEditOfFieldName(extraData,fieldName){
      return A.ExtraData.getExtraOfFieldByKey(extraData,fieldName,"CanEdit");
    },
    // buildColsWithHidden({cols,columnsFilterList}={}){
    //   let _colsHide = columnsFilterList.filter((v) => v.IsShow === false);
    //   let _cols = cols;
    //   for (let i = 0; i < _colsHide.length; i++) {
    //     _cols = _cols.filter((v) => {
    //       if(_colsHide[i].FieldName){
    //         return v.dataField !== _colsHide[i].FieldName
    //       }
    //       else if(_colsHide[i].GroupFields){
    //         return _colsHide[i].GroupFields.indexOf(v.dataField)==-1?true:false;
    //       }
    //       return true;
    //     });
    //   }
    //   return _cols;
    // },
    buildColsFieldHideFromColumnsFilterList({columnsFilterList}){
      let _colsHide = columnsFilterList.filter((v) => v.IsShow === false);
      let _cols = [];
      for(let i=0;i<_colsHide.length;i++){
        if(_colsHide[i].FieldName){
          _cols.push(_colsHide[i].FieldName);
        }
        else if(_colsHide[i].GroupFields){
          for(let j=0;j<_colsHide[i].GroupFields.length;j++){
            _cols.push(_colsHide[i].GroupFields[j]);
          }
        }
      }
      return _cols;
    },
    // buildColumnHidden({cols,extraData}){
    //   if (cols) {
    //     let _cFL = [];
    //     let _group = {};
    //     for (let i = 0; i < cols.length; i++) {
    //       const _item = cols[i];
    //       if (_item.dataField !== "Id" && !_item.dataField.startsWith("_")) {
    //         let _configColumnHide = A.ExtraData.getConfigColumnHide(extraData,_item.dataField) || {};
    //         if(_configColumnHide.show!==false){    
    //           if(_configColumnHide.group){
    //             if(_group[_configColumnHide.group]==null){
    //               _group[_configColumnHide.group] = {
    //                 Title: _configColumnHide.group,
    //                 GroupFields: [_item.dataField],
    //                 IsShow: _configColumnHide.isDefautHide===true?false:true,
    //                 Styles: _configColumnHide.style,                                       
    //               }
    //               _cFL.push(_group[_configColumnHide.group]);
    //             }
    //             else{
    //               _group[_configColumnHide.group].GroupFields.push(_item.dataField);
    //             }
    //           }
    //           else{
    //             let _obj = {
    //               Title: _item.header || _item.dataField,
    //               IsShow: _configColumnHide.isDefautHide===true?false:(_item.isDefautHide===true?false:true),
    //               FieldName: _item.dataField,
    //               Styles: _configColumnHide.style,
    //             }
    //             _cFL.push(_obj);
    //           }              
    //         }            
    //       }
    //     }
    //     // console.warn("cFL",_cFL)
    //     return _cFL;
    //   }
    // }
  },
  ConfigPage:{
    getConfigObj(configPage){
      return HUtils.Obj.get(configPage,`ConfigObj`);
    },
    getConfig(configPage,key){
      return HUtils.Obj.get(configPage,`ConfigObj.${key}`);
    }
  },
  Options:{
    parseOptions(options,{customConfig,defaultConfig}={}){
      if(options){
        let _UITypeContents = HUtils.Obj.get(options,"Configs.UITypeContents");
        if(_UITypeContents){
          MyUIHelpers.addListUI(_UITypeContents);
        }
        A.Options.optimizeButtonList(HUtils.Obj.get(options, "ButtonList"));
        if(defaultConfig){
          HUtils.Obj.set(options,`Configs._DefaultConfig`,defaultConfig);
        }
        if(customConfig){
          HUtils.Obj.set(options,`Configs._CustomConfig`,customConfig);
        }
        //title of HeaderConfig
        if(options.HeaderConfig && options.HeaderConfig.Title){
          // console.warn("options.HeaderConfig.Title",options,options.HeaderConfig.Title)
          window.document.title = `${options.HeaderConfig.Title} - ${HC.gc(HC.AppConfig.title)}`
        }
      }   
      // console.warn("parseOptions:",options,customConfig,defaultConfig);   
    },
    optimizeButtonList(arrButtonList){
      if(arrButtonList && arrButtonList.length>0){
        for(let i=0 ; i<arrButtonList.length; i++){
          const _bi = arrButtonList[i];          
          if(_bi.Type === null){
            if(_bi.Form){
              _bi.Type = "form"
            }
            else if(_bi.APIName){
              _bi.Type = "api"
            }
            else if(_bi.Url){
              _bi.Type = "link"
              if(_bi.Color==null){
                _bi.Color = "primary";
              }
            }
          }
          if(_bi.Color==null){
            _bi.Color = "success";
          }
        }
      }
    },
    getScreenConfig(options){
      if(options){              
        if(HUtils.Obj.has(options,"Configs._ScreenConfigMerge")){
          return HUtils.Obj.get(options,"Configs._ScreenConfigMerge");
        }
        let _screnConfig = HUtils.Obj.get(options,"Configs.ScreenConfig");
        if(_screnConfig){
          try {        
            let _defaultConfig = HUtils.Obj.get(options,"Configs._DefaultConfig") || {};
            let _customConfig = HUtils.Obj.get(options,"Configs._CustomConfig") || {};
            let _screenConfigObj = JSON.parse(_screnConfig);            
            // console.warn("A getScreenConfig:",options,_screenConfigObj);
            HUtils.Obj.set(options,"Configs._ScreenConfigObj",_screenConfigObj);       
            //merge
            let _screenConfigMerge = {};
            if(_defaultConfig){
              HUtils.Obj.addDataToObj(_screenConfigMerge,_defaultConfig);
            }
            if(_screenConfigObj){
              HUtils.Obj.addDataToObj(_screenConfigMerge,_screenConfigObj);
            }
            if(_customConfig){
              HUtils.Obj.addDataToObj(_screenConfigMerge,_customConfig);
            }
            if(_screnConfig){
              HUtils.Obj.set(options,"Configs._ScreenConfigMerge",_screenConfigMerge);
            }
            return _screenConfigMerge;
          } catch (error) {
            HUtils.logErrorParseJson(_screnConfig);
          }
        }
      }            
      return {};
    },
    getScreenConfigByKey(options,key){
      let _screenConfigObj = A.Options.getScreenConfig(options);
      // console.warn("getScreenConfigByKey:",_screenConfigObj,key,options);
      return HUtils.Obj.get(_screenConfigObj,key) || {};
    },
    getTableConfigInScreenConfig(options){
      return A.Options.getScreenConfigByKey(options,'Table');
    },
    getTableConfigInScreenConfigByKey(options,key,df){
      const tableConfig = A.Options.getTableConfigInScreenConfig(options);
      // console.warn("tableConfig:",tableConfig);
      return HC.getConfig(tableConfig, HC.TableConfig._key, key, df);
    },
    // getTableClassName(options){
    //   let _className =A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.showHeader)
    //     ? "" : "s_table_hideheader";
    //   _className += A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.hasCustomHeader)
    //     ? " s_table_customheader": "";
    //   _className += A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.hasWrapHeader)
    //     ? " s_table_wrapheader": "";
    //   _className += A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.noBorder)
    //     ? " s_table_noborder": "";
    //   _className += A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.noBorderCol)
    //     ? " s_table_noborder_col": "";
    //   _className += A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.borderLight)
    //     ? " s_table_borderlight": "";
    //   _className += A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.hasBigBorderBottom)
    //     ? " has-big-borderbottom": "";      
    //   _className += " " + A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.classNameTable);
    //   _className += A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.forceFullExpandDetail)
    //     ? " s_table_fullexpanddetail": "";
    //   return _className;
    // },
    getTablePropsAndOptionsTable(options,{onSortChange,onPageChange,expandComponent,getExpanding,getFooterData,getDataTotalSize,controlSizePerPageInClient,sizePerPage,currentPage}={}){
      // let _tick = performance.now()
      let _tableProps = A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.tableProps);
      let _optionsTable = A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.optionsTable);
      let _hasExpand = A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.hasExpand);
      let _hasPagingRemote = A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.hasPagingRemote);
      if(onSortChange){
        _optionsTable.onSortChange = onSortChange;
      }      
      _optionsTable.paginationShowsTotal =A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.hasPaginationShowsTotal);
      _optionsTable.hideSizePerPage = A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.hideSizePerPage);

      // console.warn("_tableProps:",_tableProps,_optionsTable,_hasExpand,options)
      if (_hasExpand === true) {
        _tableProps = Object.assign(_tableProps, {
          expandableRow: () => {
            return true;
          },
          expandComponent: expandComponent,
        });
        _optionsTable = Object.assign(_optionsTable, {
          expandAllChilds: true,
          expandRowBgColor: HColor.bgExpand,
          expandBy: "column",
          expandBodyClass: A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.expandBodyClass),
          expanding: getExpanding!=null?getExpanding():[],
        });
      }
      if (_hasPagingRemote) {
        _tableProps = Object.assign(_tableProps, {
          remote: true,
          fetchInfo: {
            dataTotalSize: HUtils.isFunction(getDataTotalSize)==true?getDataTotalSize():0,
          },
        });
        let _sizePerPage = _optionsTable.sizePerPage || sizePerPage;
        if (controlSizePerPageInClient == true) {
          _sizePerPage = sizePerPage;
        } else {
          if (_optionsTable.sizePerPage) {
            sizePerPage = _optionsTable.sizePerPage;
          }
        }
        _optionsTable = Object.assign(_optionsTable, {
          sizePerPage: _sizePerPage,
          onPageChange: onPageChange,
          page: currentPage,
        });
      }

      let _maxHeight = A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.maxHeight); //example : calc(100vh - 350px)
      if (_maxHeight) {
        _tableProps.containerStyle = {
          maxHeight: _maxHeight,
          overflow: "auto",
        };
      }
      let _showTotal = A.Options.getTableConfigInScreenConfigByKey(options,HC.TableConfig.showTotal);
      if(_showTotal && HUtils.isFunction(getFooterData)){
        let _footerData = getFooterData();
        if (_footerData) {
          _tableProps.footer = true;
          _tableProps.footerData = _footerData;
        }
      }
      // let _tock = performance.now()     
      // console.warn("ticktock:",_tock-_tick,options);
      return {
        tableProps: _tableProps,
        optionsTable: _optionsTable
      }
    }
  },
  Filter:{
    converArrayValue2ContentValue(arrValues){
      let _newValue = "";
      if(arrValues){
        for(let i=0;i<arrValues.length;i++){
          let _v = arrValues[i];
          _newValue+=`(;${_v};)`;
          if(i<arrValues.length-1){
            _newValue+="|";
          }
        }
      }
      return _newValue;
    }
  },
  Table:{
    buildCols({extraData,fieldId="Id",sourceList,fnList,opts={}}){
      let _cols = [];
      let _extraColumns = A.ExtraData.getColumns(extraData) || {};
      if(opts.addExtraColumns!=null){
        for(let _key of Object.keys(opts.addExtraColumns)){
          let _newCol = opts.addExtraColumns[_key];
          _extraColumns[_key] = _newCol;
        }        
      }
      if(opts.customExtraColumns!=null){
        for(let e of Object.keys(opts.customExtraColumns)){
          if(_extraColumns[e]){
            HUtils.Obj.addDataToObj(_extraColumns[e],opts.customExtraColumns[e]);
          }          
        }
      }
      let _arrColumns = Object.keys(_extraColumns);
      for(let i=0;i<_arrColumns.length;i++){
        let _fieldName = _arrColumns[i];
        let _item = _extraColumns[_fieldName];
        let _col;
        if(_fieldName==fieldId){
          let _hidden = true;
          if(opts.showID!=null && opts.showID==true){
            _hidden = false;
          }
          if(_hidden==true){
            _col = {
              width:'40px', hidden: true, isKey:true, dataField: fieldId, header: 'ID',
            }
          }
          else{
            _col = {
              width:_item.Width || '40px', hidden: false, isKey:true, dataField: fieldId, header: _item.Header||_fieldName,
            }
          }
        }
        else{
          if(opts.excludeFieldName!=null && opts.excludeFieldName.indexOf(_fieldName)>-1){
            continue;
          }
          if(_item.CanShow==true){
            _col = {
              width: _item.Width||'100px', 
              dataField: _fieldName, 
              header: _item.Header||_fieldName,
              colProps: {},
            }

            if(opts.columnClassName!=null){
              _col.columnClassName = opts.columnClassName;
            }
            if(opts.defaultFormat!=null){
              _col.dataFormat = opts.defaultFormat;
            }
            else{
              _col.dataFormat = MyTableCellTypes.Types._default;
            }
            /*
            Parse Json Header
            */            
            if(_item.Header && _item.Header.startsWith('{')){
              try {
                _item._HeaderObj = JSON.parse(_item.Header);
                let _headerObj = _item._HeaderObj;
                if(_headerObj){
                  _col.header = _headerObj.title || _headerObj.header || '';
                  _col.colProps.headerText = _col.header;//bo sung title cho column
                  if(_headerObj.type){
                    _col.colProps.componentHeader = (header,fieldName)=>{
                      return (
                        <MyTableHeader type={_headerObj.type} header={header} fieldName={fieldName}
                          headerObj={_headerObj} extraData={extraData} fnList ={fnList}
                        />
                      )
                    }
                  }
                  if(_headerObj.canSort!=null && typeof _headerObj.canSort == 'boolean'){
                    _col.colProps.dataSort = _headerObj.canSort;
                  }
                  if(_headerObj.canPrint===false){
                    _col.columnClassName = _col.columnClassName||'' + ' no-print-col';
                    _col.className = _col.className||'' + ' no-print-col';
                  }
                  if(_headerObj.Cls!=null){
                    _col.className = _col.className||'' + ' ' + _headerObj.Cls;
                  }
                  if(_headerObj.CCls!=null){
                    _col.columnClassName = _col.columnClassName||'' + ' ' + _headerObj.CCls;
                  }
                  if(_headerObj.hidden){
                    _col.hidden = true;
                  }
                }
              } catch (error) {
                HUtils.logErrorParseJson(_item.Header);
              }
            }
            
            if(_item.CanSort!=null){
              _col.colProps.dataSort = _item.CanSort;
            }
            if(_item.CanPrint===false){
              _col.columnClassName = _col.columnClassName||'' + ' no-print-col';
              _col.className = _col.className||'' + ' no-print-col';
            }
            if(_item.Cls!=null){
              _col.className = _col.className||'' + ' ' + _item.Cls;
            }
            if(_item.CCls!=null){
              _col.columnClassName = _col.columnClassName||'' + ' ' + _item.CCls;
            }            

            //autowidth
            if(_item.MWAS && _item.MWASD){
              let _w = window.innerWidth>_item.MWAS?'100%':_item.MWASD+'px';
              _col.width = _w;
            }            
            
            if(_item.CanEdit==true || _item.CanEdit==false){
              if(opts.customProps!=null && opts.customProps['_Arr']!=null){
                let _regex = opts.customProps['_Arr'].regex;
                let _test = _regex.test(_fieldName);
                if(_test==true){
                  _col.colProps = Object.assign(_col.colProps,opts.customProps['_Arr'].props);
                }
              }
              if(opts.customProps!=null && opts.customProps[_fieldName]!=null){
                _col.colProps = Object.assign(_col.colProps,opts.customProps[_fieldName]);
              }

              if(opts.customType!=null && opts.customType[_fieldName]!=null){
                _item.Type = Object.assign(_item.Type,opts.customType[_fieldName]);
                console.log('_item.Type:',_item.Type,opts.customType);
              }

              let _formatExtraData = {
                fieldName: _fieldName,
                // fnRequestUpdate: fnRequestUpdate,
                // fnRequestDelete: fnRequestDelete,
                extraData: extraData,
                sourceList: sourceList||{},
                fnList: fnList||{},
              }
              _formatExtraData = Object.assign(_formatExtraData,_item);
              _col.colProps.formatExtraData = _formatExtraData;

              // if(_item.Type!=null){//ko can tuy chinh type nua, gom vao _default
              //   if(CellHelper.DataFormat[_item.Type.type]!=null){
              //     _col.dataFormat = CellHelper.DataFormat[_item.Type.type];
              //   }
              // }
            }
          }
        }
        // _col.colProps.formatExtraData = {
        // } 
        if(_col!=null){
          if(_item.Index!=null){
            if(_cols.length>_item.Index){
              _cols.splice(_item.Index,0,_col);
            }
            else{
              // _cols.unshift(_col);
              _cols.push(_col);
            } 
          }
          else{
            _cols.push(_col);
          }
        }
      }

      if(opts.customAction!=null){
        opts.customAction.colProps = {
          formatExtraData: {
            fieldName: '_ActionDelete',
            extraData: extraData,
            sourceList: sourceList||{},
            fnList: fnList||{},
          }
        }
        _cols.push(
          opts.customAction
        )
      }
      else if(_cols.length>0 && opts[HC.TableConfig.canDelete]==true){
        let _isHideColDelete = false;
        if(opts[HC.TableConfig.hideColDelete]===true){
          _isHideColDelete = true;
        }
        if(_isHideColDelete!==true && HC.rc("getIsMobileByWidth") && opts[HC.TableConfig.hideColDeleteOnSmallWidth]===true){
          _isHideColDelete = true;
        }
        if(_isHideColDelete!==true){
          let _typeCell = {type:"delete"};
          let _width = HUtils.Obj.get(opts,"customDelete.width") || '35px';
          if(opts.customDelete){
            let _type = HUtils.Obj.get(opts,"customDelete.type");          
            if(_type){
              _typeCell.type = _type;
              _typeCell.more = HUtils.Obj.get(opts,"customDelete.more");
            }          
          }
          _cols.push(
            {
              width: _width, dataSort: false, dataField:'_ActionDelete', header: '',
              dataFormat: MyTableCellTypes.Types._default,
              columnClassName: 'no-print-col',
              colProps: {
                formatExtraData: {
                  fieldName: '_ActionDelete',
                  extraData: extraData,
                  sourceList: sourceList||{},
                  fnList: fnList||{},
                  Type: _typeCell,//for custom delete
                }
              }
            },
          )
        }        
      }
      return _cols;
    }
  },
  V1:{
    updateTotalDataToTab(dataTotal,configPage){
      // console.warn("updateTotalDataToTab",dataTotal,configPage)
      if(dataTotal && configPage){        
        var a = document.querySelector(`[data-id="${configPage.Id}"]`);
        if(a){
          var nodeBadge = null;
          if(a.children && a.children.length>0){
            for (var i = 0; i < a.children.length; i++) {
              if (a.children[i].className == "badge-files") {
                nodeBadge = a.children[i];
                break;
              }        
            }
          }
          if(nodeBadge){
            nodeBadge.innerHTML = dataTotal;
          }
          else{
            var b = document.createElement('span');b.className = "badge-files";b.innerHTML = dataTotal;
            a.appendChild(b)
          }                    
        }
      }
    },
    parseFilterObjtoFilterQuery({filterObj}={}){
      let _filterQuery = {};
      // console.warn("parseFilterObjtoFilterQuery:",filterObj);
      if(filterObj){
        let _keys = Object.keys(filterObj);
        if(_keys && _keys.length>0){
          for(let k of _keys){
            let _fieldName = k;
            let _item = filterObj[k];
            // console.warn("parseFilterObjtoFilterQuery 1:",_fieldName,_item);
            if(_item && _item.isServer===true){
              if(typeof _item.value=='object'){
                if(_item.value.hasOwnProperty('valueQueryServer')){
                  _filterQuery[_fieldName] = _item.value['valueQueryServer']
                }
              }              
              else if(_item.value!=null){
                _filterQuery[_fieldName] = _item.value
              }
            }
          }
        }
      }
      // console.warn("parseFilterObjtoFilterQuery 2:",_filterQuery);
      return _filterQuery;
    }
  },
  TableExpand:{
    getCanShowOfKeyExpand(row,keyScreen){
      let _canShow = true;
      if(_canShow===true){
        if(HUtils.Obj.get(row,"UI_ConfigExpand.Disable")){
          if(row.UI_ConfigExpand.Disable.indexOf(keyScreen)>-1){
            _canShow = false;
          }
        }
      }
      return _canShow;
    },
    getIsExpandOfScreenItem(row,item){
      if(item){
        if (item.list && item.list.length>0){//showAll
          let _rs = true;
          for(let i=0;i<item.list.length;i++){
            let _key = item.list[i].key || item.list[i].screenCode || 'nokey';
            if(A.TableExpand.getIsExpandOfKeyScreen(row,_key)!=true)
            {
              return false; //chỉ cần 1 cái sai là return sai
            }
          }
          return _rs;
        }else{
          let _key = A.TableExpand.getKeyScreenOfScreenItem(item);
          return A.TableExpand.getIsExpandOfKeyScreen(row,_key)
        }
      }     
      return false;
    },
    getIsExpandOfKeyScreen(row,keyScreen){
      return HUtils.Obj.get(row,`_isExpandMulti.${keyScreen}`);
    },
    getConfigExpandOfKeyScreen(row,keyScreen){
      return HUtils.Obj.get(row,`_configMulti.${keyScreen}`);
    },
    getKeyScreenOfScreenItem(item){
      return item.key || item.screenCode || 'nokey';
    },
    getKeyScreenExpand(row){
      let _configMulti = HUtils.Obj.get(row,"_configMulti");
      if(_configMulti){
        let _keys = Object.keys(_configMulti);
        if(_keys && _keys.length>0){
          return _keys.filter((v,i)=>{
            if(v.startsWith('_')){
              return false;
            }
            return true;
          })
        }
      }
      return [];
    },
    checkConfigMulti(row,detailConfig,item){
      if(row){
        if(row._isExpandMulti==null){
          row._isExpandMulti = {};
        }
        if(row._configMulti==null){
          row._configMulti = {};
        }
        if(HUtils.Obj.get(detailConfig,"isRow")){
          HUtils.Obj.set(row,"_configMulti._isRow",true)
        }
        if(item && item.isRow){
          HUtils.Obj.set(row,"_configMulti._isRow",true)
        }
      }
    },
    showExpand(row,detailConfig,item){
      if(row && item && row['_configMulti'] && row['_isExpandMulti']){
        if (item && item.list && item.list.length>0){
          for (let i=0;i<item.list.length;i++){
            let _item = item.list[i];
            let _key = A.TableExpand.getKeyScreenOfScreenItem(_item);
            row['_configMulti'][_key] = _item;
            if(row['_isExpandMulti'][_key]==null){
              row['_isExpandMulti'][_key] = true;
            }
            else{
              row['_isExpandMulti'][_key] = !row['_isExpandMulti'][_key];
            }
          }
        }
        else{
          let _key = A.TableExpand.getKeyScreenOfScreenItem(item);
          row['_configMulti'][_key] = item;
          if(row['_isExpandMulti'][_key]==null){
            row['_isExpandMulti'][_key] = true;
          }
          else{
            row['_isExpandMulti'][_key] = !row['_isExpandMulti'][_key];
          }
        } 
      }
    },
    getPrefixFieldKeyScreen(detailConfig){
      return HUtils.Obj.get(detailConfig,"prefixFieldKeyUI") || "";
    },
    getStyleOfKeyScreen(row,detailConfig,keyScreen){
      let _config = A.TableExpand.getConfigExpandOfKeyScreen(row,keyScreen);
      let _bgColor = HC.gc(HC.AppConfig.expandBgColor);            
      let _padding = '5px';
      if(detailConfig){
        if(detailConfig.bgColor){
          _bgColor = detailConfig.bgColor;
        }
        else if(detailConfig.bgF){
          _bgColor = row[detailConfig.bgF];
        }              
      }
      if(_config.bgColor){
        _bgColor = _config.bgColor;
      }
      else if(_config.bgF){
        _bgColor = row[_config.bgF];
      }
      if(_config.padding) {
        _padding =_config.padding;
      }
      let _styleItem = {
        padding:_padding,
        backgroundColor:_bgColor,
        position:"relative",
      };
      if(_config.styleExpand){
        _styleItem = Object.assign(_styleItem,_config.styleExpand);
      }
      else if(_config.style){
        //style se bi nham lan voi style cell nen ko nen dung nua
        _styleItem = Object.assign(_styleItem,_config.style);
      }
      if(A.TableExpand.getIsRow(row)===true){
        if(_styleItem.width==null){
          _styleItem.flex = 1;
        }              
      }
      return _styleItem;
    },
    getKeyUIKeyScreen(row,detailConfig,keyScreen){
      let _k = "";
      let _prefix = A.TableExpand.getPrefixFieldKeyScreen(detailConfig);
      if(_prefix){
        let _arr = _prefix.split(",");
        for(let f of _arr){
          if(row[f]!=null){
            _k += `${row[f].toString()}_`;
          }else{
            _k += `${f}_`;
          }
        } 
      }
      _k += keyScreen; 
      return _k;
    },
    getConfigArrow(row,detailConfig,keyScreen){
      let _config = A.TableExpand.getConfigExpandOfKeyScreen(row,keyScreen);
      let _hideArrow = false;
      let _iconArrow = "fa-angle-double-right";
      let _styleArrow = {
        paddingLeft:'5px',paddingRight:'5px',color:'#20a8d8',marginRight:'1px',fontSize:'30px'
      }
      if(_config.hideArrow==true){
        _hideArrow=true;
      }
      if(_config.iconArrow){
        _iconArrow=_config.iconArrow;
      }
      if(_config.styleArrow){
        _styleArrow = Object.assign(_styleArrow,_config.styleArrow);
      }
      return {
        hideArrow: _hideArrow,
        iconArrow: _iconArrow,
        styleArrow: _styleArrow
      }
    },
    getConfigPageFromScreenItem(row,detailConfig,keyScreen){
      let _configExpand = A.TableExpand.getConfigExpandOfKeyScreen(row,keyScreen);
      let _configPage = {
        APIName: _configExpand.controller,
        Config: `{"type":"tablev3"}`,
        Id: "",
        RequestData: null,
        ScreenCode: _configExpand.screenCode,
        Title: "",Type: "",UIType: "Client",UIUrl: "",
      }
      let _query = {};
      if(detailConfig && detailConfig.queryFromParent){
        _query = Object.assign(_query,HUtils.getQueryFromObj(detailConfig.queryFromParent,row));
      }
      if(_configExpand && _configExpand.queryFromParent){
        _query = Object.assign(_query,HUtils.getQueryFromObj(_configExpand.queryFromParent,row));
      }

      //using on v1
      if(_configExpand){
        if(_configExpand.uiType){
          _configPage.UIType = "Client"
        }
        if(_configExpand.configOnServer){
          let _currentObjConfig = JSON.parse(_configPage.Config) || {};
          _currentObjConfig = Object.assign(_currentObjConfig,_configExpand.configOnServer);
          _configPage.Config = JSON.stringify(_currentObjConfig)
        }        
      }
      _configPage.RequestData = _query;
      console.warn("_configPage:",_configPage,_configExpand)
      return _configPage;
    },
    getIsRow(row){
      return HUtils.Obj.get(row,"_configMulti._isRow");
    },
  },
  V3:{
    MiniApp:{
      enter(){
        window.document.body.classList.add("theme-solidv3-appmini"); 
      },
      leave(){
        window.document.body.classList.remove("theme-solidv3-appmini")
      },
      initHeight(dom){
        if(dom){
          dom.style.height = `calc(100vh - ${dom.offsetTop}px)`;
        }
      }
    },
    TabV3:{
      prefix: "V3_Last_",
      Key: {
        User: "User",
        Department: "Department",
      },
      getObjLocalByKey(key){//key = "project,user,department"
        let _objLast = HLocalStorage.getObject(`${A.V3.TabV3.prefix}${key}`,{});
        let _obj = {
          indexSwitch: _objLast.indexSwitch!=null?_objLast.indexSwitch: -1,
          indexData: _objLast.indexData!=null?_objLast.indexData: 0,
          data: _objLast.data!=null?_objLast.data: {Id:""},
        }        
        return _obj;
      },
      setSwitch(key,indexSwitch=-1){
        let _currentObj = A.V3.TabV3.getObjLocalByKey(key);
        let _obj = {
          ..._currentObj,
          indexSwitch: indexSwitch,
        }       
        if(indexSwitch!=-1){
          _obj.indexData = indexSwitch;
        }
        // console.warn("setSwitch:",_currentObj,_obj);
        HLocalStorage.setObject(`${A.V3.TabV3.prefix}${key}`,_obj); 
      },
      saveObjLocalByKey(key,{indexSwitch=-1,indexData=0,data}={}){//key = "project,user,department"
        let _obj = {
          indexSwitch: indexSwitch,
          indexData: indexData,
          data: data,
        }
        if(indexSwitch!=-1){
          _obj.indexData = indexSwitch
        }
        // if(data!=null && data.DisplayName){
        //   _obj._name = data.DisplayName
        // }
        // else{
        //   console.error("no name:",data);
        // }
        HLocalStorage.setObject(`${A.V3.TabV3.prefix}${key}`,_obj); 
      },
    },
    getLastProjectId(){
      let _currentProjectInUrl = HLink.getParamsOfURLWithKey(window.location.href,HC.ParramURL.pj,{removeHash:true});
      if(_currentProjectInUrl==null){
        let _currentProjectLocalStorage = HLocalStorage.getString(HC.LocalKey.V3_CurrProject,null);
        if(_currentProjectLocalStorage){
          _currentProjectInUrl = _currentProjectLocalStorage;
        }
      }
      return _currentProjectInUrl;
    },
    getLastIsPorfolio(){
      let _currentIsPorfolioInUrl = HLink.getParamsOfURLWithKey(window.location.href,HC.ParramURL.pf,{removeHash:true});
      if(_currentIsPorfolioInUrl==null){
        let _currentIsPorfolio = HLocalStorage.getString(HC.LocalKey.V3_CurrPorfolioIndex,null);
        if(_currentIsPorfolio!=null){
          _currentIsPorfolioInUrl = _currentIsPorfolio;
        }
      }
      if(_currentIsPorfolioInUrl==null){
        _currentIsPorfolioInUrl = -1;//default all
      }
      return _currentIsPorfolioInUrl;
    }
  },
}

export default A;