import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames';
import HLink from '../../../helpers/Link';

class CellType_ComponentAddable extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
  }

  render() {
    const {configLinkIcon,linkIcon} = this.props;
    return (
      <div className="mct-status-link" title={configLinkIcon.title || linkIcon} onClick={()=>{
        HLink.push(linkIcon,configLinkIcon.newWindow===false?false:true);
      }}>
        <i className={configLinkIcon.icon || "fa fa-external-link"}/>
      </div>
    )
  }
}

export default CellType_ComponentAddable;