import * as React from 'react';
import * as PropTypes from 'prop-types';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn("error UI:",error,errorInfo,this.props);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div></div>;
    }
    return this.props.children; 
  }
}

export default ErrorBoundary;