import React from 'react'
import HUtils from '../../../helpers/Utils'
import HConstant from '../../../helpers/Constant'
import {MyUI} from "@macashipo/mlib"

/**
 * 
 * t: type, tag: tagHTML, st: style, cn: className, p: more Props, ext: row, cell, btn,...
 * wb: wrap by MyUI, 
 * ch_af: child array with field name
 * stf: style by, stfa: style field all // get style by field
 * 
 * row: data, row, fnList
 * cell: data, fnList
 * btn: fnList
 * 
 */

class MyUI_Object extends React.Component {
  renderArray=(arr)=>{
    return (
      <React.Fragment>
      {
        arr.map((v,i)=>{       
          return (
            <MyUI type="o" key={i} configObj={v} fnList={this.getFnList()}/>
          )
        })
      }        
   </React.Fragment>
    )
  }
  renderString=(str)=>{
    let _str = str;
    if(_str && typeof _str=="string"){
      if(_str.startsWith("fn/")){
        let _arr = _str.split("/");        
        // console.warn("_arr:",_arr,_str);
        return this.parseFunction(_arr);
      }
      else if(_str.indexOf("[")>-1 && _str.indexOf("]")>-1){
        let _myData = HUtils.runFnList(this.getFnList(),HConstant.UIFnCore.myData,this,[""]) || {};        
        _str = HUtils.SquareBracket.replace(_str,_myData,{emptyWhenNull:true});
        // console.warn("myData",_myData,_str);
      }
    }    
    return _str;
  }
  renderChildren=(ch,obj={})=>{
    const {ch_af} = obj;
    if(ch_af){
      let _arr = this.parseFunction(["fn","myData",[ch_af]]);
      console.warn("_arr:",_arr);
      if(Array.isArray(_arr)){
        return (
          <React.Fragment>
            {
              _arr.map((v,i)=>{
                return (
                  <MyUI key={i} type="o" configObj={ch} data={v} fnList={this.getFnList()}/>
                )
              })
            }
          </React.Fragment>        
        )
      }
      else{
        return(
          <div></div>
        )
      }      
    }
    else if(typeof ch=="string"){      
      return this.renderString(ch);
    }
    else if(Array.isArray(ch) && ch.length>0){
      return this.renderArray(ch);
    }
    else if(typeof ch=="object"){
      return (
        <MyUI type="o" configObj={ch} fnList={this.getFnList()}/>
      )
    }
  } 
  parseFunction=(arr)=>{
    const fnList = this.getFnList();
    // console.warn("parse Function",arr,fnList);
    if(arr && arr.length>2 && fnList){
      let _fnName = arr[1];
      let _arg3 = arr[2];
      if(_arg3==null){
        // console.warn("_arg3 = null");
        _arg3 = [];
      }
      else if(Array.isArray(_arg3)==false){
        // console.warn("_arg2 not array:",_arg3);
        if(_arg3.startsWith("[") && _arg3.endsWith("]")){
          try {
            _arg3 = _arg3.replace(/\'/g,`"`);
            let _newArg3 = JSON.parse(_arg3);
            // console.warn("_newArg3:",_newArg3);
            // let _newArg3 = _arg3.replace(/\[/g,'').replace(/\]/g,'').split(',');
            for(let i=0;i<_newArg3.length;i++){
              let _item = _newArg3[i];
              if(_item && typeof _item=="object" && _item.fn!=null){
                _newArg3[i] = this.parseFunction(["fn",_item.fn,_item.args]);
                // console.warn("_newArg3 i:",i,_newArg3[i]);
              }
            }
            // console.warn("_newArg3:",_newArg3);
            _arg3 = _newArg3;
          } catch (error) {
            console.warn("error parse json:",_arg3,error);
            // _arg3 = [arr[2]];
          }          
        }
        else{
          _arg3 = [arr[2]];
        }        
      }    
      // console.warn("runFnList 1",_fnName,_arg3,_value);
      if(Array.isArray(_arg3)==false){//dam bao _arg3 la 1 array
        _arg3 = [_arg3];
      }  
      let _value = HUtils.runFnList(fnList,_fnName,this,_arg3);
      // console.warn("runFnList",_fnName,_arg3,_value);
      return _value;   
    }
  }
  parseMoreProp=(p)=>{
    let _moreProps = p;
    let _more={};
    if(_moreProps){
      let _keys = Object.keys(_moreProps);
      for(let k of _keys){        
        let _v = _moreProps[k];
        if(typeof _v=="string" && ["onB","onCl","onC"].indexOf(k)==-1){
          _more[k] = this.renderString(_v);
        }
        else{
          _more[k] = _moreProps[k];
        }
      }
      if(_more.hasOwnProperty(HConstant.UIOnClick.key)){   
        _more.m = {
          ..._more.m,
          onClick: ()=>{
            console.warn("Click",p,this.props);
            let _onCl = HUtils.Obj.get(p,HConstant.UIOnClick.key);
            this.renderString(_onCl)            
          }
        }
        // console.warn("add onCLick:",_more);
      }
    }
    return _more;
  }
  getFnList=()=>{
    const {fnList,data} = this.props;
    if(this._fnList){
      return this._fnList;
    }
    let _myFnList = {
      myChange: (fieldName,v,opts)=>{
        // console.warn("myChange:",fieldName,v,this.props,opts);
        let _onC = HUtils.Obj.get(opts,HConstant.UIOnChange.key);
        if(_onC==HConstant.UIOnChange.updateField){
          HUtils.runFnList(fnList,HConstant.UIFnPage.fnRequestUpdate,this,[
            data,fieldName,v,
          {
            // cbOK: ()=>{
            //   console.warn("OK");
            // }
          }])
        }                
      },
      myBlur: (fieldName,v,opts)=>{
        // console.warn("myBlur:",fieldName,v,this.props,opts);
        let _onB = HUtils.Obj.get(opts,HConstant.UIOnBlur.key);
        if(_onB==HConstant.UIOnBlur.updateField){
          HUtils.runFnList(fnList,HConstant.UIFnPage.fnRequestUpdate,this,[
            data,fieldName,v,
          {
            // cbOK: ()=>{
            //   console.warn("OK");
            // }
          }])
        }                
      }
    };
    if(data){
      _myFnList.myData=(key)=>{
        return HUtils.Obj.get(this.props.data,key||"");//truong hop key = null se bi loi-> truyen chuoi rong
      }
    }
    this._fnList = {      
      ...fnList,
      ..._myFnList//uu tien fnList hien tai, overide lai cac fnList truoc do
    }
    return this._fnList;
  }
  parseStyle=(obj)=>{
    let _style = obj.st;
    if(obj.stf){
      let _styleField = {};
      let _keys = Object.keys(obj.stf);
      for(let k of _keys){
        _styleField[k] = this.renderString(obj.stf[k]);
      }
      // console.warn("parseStyle:",_styleField,obj.stf);
      _style = Object.assign({},_style,_styleField);
    }
    if(obj.stfa){
      let _styleAllField = this.renderString(obj.stfa); 
      _style = Object.assign({},_style,_styleAllField);
    }   
    return _style;
  }
  render(){
    const {configObj,isWrap} = this.props;
    // console.warn("render o:",this.props);
    if(configObj && typeof configObj=="object" && configObj.t){
      let _obj = configObj;
      let _type = configObj.t;
      let _wrapBy = configObj.wb;
      if(_wrapBy && typeof _wrapBy =="object" && isWrap!==true){
        _wrapBy.ch = Object.assign({},configObj);
        delete _wrapBy.ch.wb;        
        return (
          <MyUI type="o" configObj={_wrapBy} isWrap={true} fnList={this.getFnList()}/>
        )
      }
      let _moreProps = this.parseMoreProp(_obj.p);
      let _style = this.parseStyle(_obj);
      return (
        <MyUI type={_type} tag={_obj.tag} style={_style} className={_obj.cn} fnList={this.getFnList()} {..._moreProps}>
          {
            _obj.ch!=null &&
            this.renderChildren(_obj.ch,_obj)
          }
        </MyUI>
      )
    }
    else if(Array.isArray(configObj)){
      return this.renderArray(configObj)
    }
    else if(typeof configObj=="string"){
      let _value = this.renderString(configObj);
      if(_value){
        return this.renderString(_value);
      }
      else{
        return "";
      }      
    }
    console.warn("MyUI o missing type:",configObj);
    return (
      <div></div>
    ) 
  } 
}

export default MyUI_Object