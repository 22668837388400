import React from 'react';
import { Button,HHub,MyButtonHelpersV1 } from '../_import';

class MyButton_FileManager extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
  }
  _onClick=()=>{
    console.warn("onClick");
    let _requestData = MyButtonHelpersV1.getRequestData(this);
    let _configFile = MyButtonHelpersV1.getConfigFile(this);
    HHub.showFilesToUploadV2({
      opts:{
        moreRequestQuery: _requestData,
        ..._configFile                   
      },                  
      canEdit: false,                  
      fnList:{
      }
    })
  }
  render() {
    return(
      <Button 
        onClick={()=>{
          this._onClick();
        }}
        {...MyButtonHelpersV1.getMoreProps(this.props,null,null)}
      >
      {
        MyButtonHelpersV1.getTitle(this.props)
      }
      </Button>
    )
  }
}

export default MyButton_FileManager;