import { HUtils, HUI } from "@macashipo/mlib"
import { FlowBuildColTableFromExtraData, FlowFilterColsWithHidden, FlowBuildColumnHidden, FlowGetClassNameTable, FlowRoutes, FlowPage, FlowMNeedUpdateMLib, FlowTableCell, FlowTableFilter, FlowFormControl, FlowApi } from "./Flow"
import A from "./A"

export const Table_BuildCols = ({extraData,fieldId="Id",sourceList,fnList,opts={}})=> {
  return FlowBuildColTableFromExtraData({extraData,fieldId,sourceList,fnList,
    excludeFieldName:opts.excludeFieldName,
    columnClassName: opts.columnClassName,
    defaultFormatColumn: opts.defaultFormat,//old: defaultColumn
    customProps: opts.customProps,
    customType: opts.customType
  })
  // return A.Table.buildCols({extraData,fieldId,sourceList,fnList,opts})
}

//Summary V3
const V3 = {
  Table_BuildCols: Table_BuildCols,
  Table_FilterColsWithHidden: FlowFilterColsWithHidden,
  Table_BuildColumnHidden: FlowBuildColumnHidden,
  Table_GetClassNameTable: FlowGetClassNameTable,
  //Table Cell  
  Cell_GetCanEdit: FlowTableCell.FlowGetCanEdit,
  Cell_GetStyleCell: FlowTableCell.FlowGetStyleCell,
  Cell_GetClassCell: FlowTableCell.FlowGetClassCell,
  Cell_GetColor: FlowTableCell.FlowGetColor,
  Cell_UpdateCell: FlowTableCell.FlowUpdateCell,
  Cell_GetIsUpdatingCell: FlowTableCell.FlowGetIsUpdatingCell,//ko xai nua, chi su dung ben trong renderLoading
  Cell_GetMore: FlowTableCell.FlowGetMore,
  Cell_GetConfigOfMore: FlowTableCell.FlowGetConfigOfMore,
  Cell_GetConfigModal: FlowTableCell.FlowGetConfigModal,
  Cell_GetStyleInput: FlowTableCell.FlowGetStyleInput,
  Cell_GetMoreAttrsInput: FlowTableCell.FlowGetMoreAttrsInput,
  Cell_GetHeaderTitle: FlowTableCell.FlowGetHeaderTitle,
  Cell_RenderLoading: FlowTableCell.FlowRenderLoading,
  Cell_CheckUpdateValueAfterWillReceiveProps: FlowTableCell.FlowCheckUpdateValueAfterWillReceiveProps,
  Cell_KMore: FlowTableCell.KMore,
  //Table Filter
  MTF_GetMore: FlowTableFilter.FlowGetMore,
  MTF_GetConfigOfMore: FlowTableFilter.FlowGetConfigOfMore,
  MTF_GetSourceData: FlowTableFilter.FlowGetSourceData,
  MTF_KMore: FlowTableFilter.KMore,
  //Routes
  Routes_ListPublic: FlowRoutes.RoutesPublic,
  Routes_ListPrivate: FlowRoutes.RoutesPrivate,
  Routes_ListTest: FlowRoutes.RoutesTest,
  Routes_AddPublic: FlowRoutes.FlowAddPublicRoute,
  Routes_AddPrivate: FlowRoutes.FlowAddPrivateRoute,
  Routes_AddTest: FlowRoutes.FlowAddTestRoute,
  //Page
  Page_GetSharedQueryOfPage: FlowPage.FlowGetSharedQueryOfPage,
  //Form
  FormControl_GetMore: FlowFormControl.FlowGetMore,
  FormControl_GetCanEdit: FlowFormControl.FlowGetCanEdit,
  FormControl_GetPlaceHolder: FlowFormControl.FlowGetPlaceHolder,
  FormControl_UpdateValueForm: FlowFormControl.FlowUpdateValueForm,
  FormControl_GetConfigFormControlFromV1: FlowFormControl.FlowGetConfigFormControlFromV1,
  FormControl_GetDefaultValueOfFieldName: FlowFormControl.FlowGetDefaultValueOfFieldName,
  //Api
  Api_Generic: FlowApi.FlowApiGeneric,
  Api_RefreshCache: FlowApi.FlowApiRefreshCache,
  //Need Update MLib
  M_addItemToArrayOrCreateNewArray:FlowMNeedUpdateMLib.addItemToArrayOrCreateNewArray,
}
//Summary V5
const V5 = {
  Table_BuildCols: Table_BuildCols, //chua xu dung
  // Table_FilterColsWithHidden: FlowFilterColsWithHidden,
  // Table_BuildColumnHidden: FlowBuildColumnHidden,
}

export default V3