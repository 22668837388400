import Moment from "moment";
const Format = {
  formatByConfigFormat(value,configFormat){
    if(configFormat){
      if(configFormat.type=="money"){
        return Format.currency(value,{
          ...configFormat
        })
      }
      else if(configFormat.type=="date"){
        return Format.Date.dateStringWithFormat(value,configFormat.format)
      }
    }
    
  },
  HConfig: null,
  symbolDecimal: ',',
  symbolThousand: ".",
  preCurrency: null,
  sufCurrency: null,
  initFormat(Config){
    Format.HConfig = Config;
    Format.updateConfig();
  },
  updateConfig(){
    const HConfig = Format.HConfig;
    if(HConfig){
      Format.symbolDecimal = HConfig.fnList.getConfig('symbolDecimal');
      Format.symbolThousand = HConfig.fnList.getConfig('symbolThousand');
      Format.preCurrency = HConfig.fnList.getConfig('preCurrency');
      Format.sufCurrency = HConfig.fnList.getConfig('sufCurrency');
    }
  },
  percent(number,{numDecimal=1,needMultip=false}={}){
    let _v = '';
    if(number!=null){
      // let _n = Math.round(number * 100) / 100;      
      let _x = 10;
      let _number = number;
      if(numDecimal>1 && numDecimal<=5){
        _x = Math.pow(10,numDecimal);
      }
      if(needMultip===true){
        _number = _number * 100;
      }
      // console.warn("percent:",numDecimal,_x);
      let _n = Math.round(_number * _x) / _x;
      _v = `${_n}%`;
    }
    return _v;
  },
  currency(s,{symbolThousand,preCurrency,sufCurrency}={}){
    let _value = '';
    let _symbolThousand = symbolThousand || Format.symbolThousand;
    let _preCurrency = preCurrency || Format.preCurrency;
    let _sufCurrency = sufCurrency || Format.sufCurrency;
    if(s!=null){//support so am//if(s && s>0)
      let _isNegative = false;
      if(s<0){
        _isNegative = true;
        s  = s*-1;
      }
      _value = s.toFixed().replace(/./g, function(c, i, a) {
        // return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? _symbolThousand + c : c;
      });
      if(_preCurrency){
        _value = _preCurrency + _value;
      }
      if(_sufCurrency){
        _value = _value + _sufCurrency;
      }
      if(_isNegative){
        _value = "-" + _value;
      }
    }
    return _value;
  },
  Date:{
    dateStringWithFormat(time,format){
      if(time==null || time==0 || time == ''){
        return "";
      }
      let _d = new Date(time);
      if(format!=null && format.length>0){
        return Moment(time).format(format);
      }
      return _d.toISOString();
    },
    fromNow(time){
      if(time==null || time==0 || time == ''){
        return "";
      }
      return Moment(time).fromNow(); 
    },
    getObjectFromTime(time){
      let _dateObj = new Date(time);
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return {
        month: _dateObj.getUTCMonth() + 1,
        monthName: monthNames[_dateObj.getUTCMonth()],
        monthShortName: monthShortNames[_dateObj.getUTCMonth()],
        day: _dateObj.getUTCDate(),
        hour: _dateObj.getUTCHours(),
        minute: _dateObj.getUTCMinutes()
      }
    },
  },
  expandFormatRepetitions(format) {
    return format.reduce(function __reducePatterns(patterns, nextItem) {
      if (nextItem.repeat > 1) {
        const expanded = [];
        const copy = { ...nextItem };
        delete copy.repeat;
        for (let i = 0; i < nextItem.repeat; i += 1) {
            expanded.push({ ...copy });
        }
        return [...patterns, ...expanded];
      }
      return [...patterns, nextItem];
    }, []);
  },
  formatValue(value, formatSpec = []) {
    const format = Format.expandFormatRepetitions(formatSpec);
    if (format.length > 0) {
        const characters = value.split("");
        let formattedValue = "",
            rawValue = "";
        while (format.length > 0 && characters.length > 0) {
            const pattern = format.shift();
            if (pattern.char) {
                let charRexp;
                if (typeof pattern.char === "object" && typeof pattern.char.test === "function") {
                    charRexp = pattern.char;
                } else if (Array.isArray(pattern.char) && pattern.char.length >= 1) {
                    const [rexp, mod = ""] = pattern.char;
                    charRexp = new RegExp(rexp, mod);
                } else if (typeof pattern.char === "string") {
                    charRexp = new RegExp(pattern.char);
                } else {
                    throw new Error(`Invalid pattern provided: ${pattern.char}`);
                }
                while (characters.length > 0 && charRexp.test(characters[0]) !== true) {
                    characters.shift();
                }
                if (characters.length > 0) {
                    formattedValue += characters[0];
                    rawValue += characters[0];
                    characters.shift();
                }
            } else if (typeof pattern.exactly === "string") {
                if (pattern.exactly.length !== 1) {
                    throw new Error(
                        `Unable to format value: 'exactly' value should be of length 1: ${pattern.exactly}`
                    );
                }
                formattedValue += pattern.exactly;
                if (pattern.exactly === characters[0]) {
                    characters.shift();
                }
            } else {
                throw new Error(
                    `Unable to format value: Invalid format specification: ${JSON.stringify(
                        pattern
                    )}`
                );
            }
        }
        return { formatted: formattedValue, raw: rawValue };
    }
    return { formatted: value, raw: value };
  }
}

window.HFormat = Format;
export default Format;