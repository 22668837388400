import _, {orderBy,isFunction,isObject,isArray,isString,has,set} from 'lodash'
const Utils = {
  isString: isString,
  isStringJson(str) {
    if (!isString(str)){
      return false;
    }
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  },
  moveItemInArray(arr=[],sourceIndex,targetIndex){
    if(sourceIndex>-1 && targetIndex>-1 && sourceIndex!=targetIndex && arr.length>sourceIndex && arr.length>targetIndex){
      var element = arr[sourceIndex];
      arr.splice(sourceIndex, 1);
      arr.splice(targetIndex, 0, element);
    }
  },
  isFunction: isFunction,
  isObj: isObject,
  isArray: isArray,
  logExistType(type){
    console.warn("Not exist type:",type);
  },
  logErrorParseJson(s){
    console.warn("Error JSON:",s);
  },
  addTypeComponent(type,typesList,fnUI,opts={}){
    if(type){
        if(typesList[type]){
            console.warn("Type exist:",type);
            if(opts.forceAdd===true){
                typesList[type] = fnUI;
            }
        }
        else{
            typesList[type] = fnUI;
        }
    }
  },
  initInnerRef(component) {
    if (component && component.props && component.props.innerRef) {
      component.props.innerRef(component);
    }
  },
  getIconClassFile(type) {
    let _classNameFileIcon = "fa fa-file-o";
    if (type != null) {
      if (type.indexOf("image") > -1) {
        _classNameFileIcon = "fa fa-file-photo-o";
      } else if (type.indexOf("pdf") > -1) {
        _classNameFileIcon = "fa fa-file-pdf-o";
      } else if (type.indexOf(".document") > -1) {
        _classNameFileIcon = "fa fa-file-word-o";
      } else if (type.indexOf(".sheet") > -1) {
        _classNameFileIcon = "fa fa-file-excel-o";
      } else if (type.indexOf("multi") > -1) {
        _classNameFileIcon = "fa fa-files-o";
      }
    }
    return _classNameFileIcon;
  },
  getProjectIdInMatch(props = {}) {
    const { match } = props;
    // console.warn("getProjectIdInMatch:",props,match)
    if (props.projectId) {
      return props.projectId;
    }
    if (match && match.params != null) {
      if (match.params.projectId != null) {
        return match.params.projectId;
      }
      if (match.params.id != null) {
        return match.params.id;
      }
    }
  },
  getScreenCode(props = {}) {
    if (props.screenCode) {
      //ko nen truyen qua props nua
      return props.screenCode;
    }
    if (props && props.configPage) {
      return props.configPage.ScreenCode;
    }
  },
  userAgent: window.navigator!=null?window.navigator.userAgent:null,
  isEdge(){
    return /Edge/.test(Utils.userAgent);
  },
  isChrome(){
    return /chrome/i.test(Utils.userAgent) && !Utils.isEdge();
  },
  isSafari(){
    return /safari/i.test(Utils.userAgent) && !Utils.isEdge() && !Utils.isChrome(); 
  },
  isMobile(){
    return /mobile/i.test(Utils.userAgent);
  },
  isRunOnMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  getYoutubeID(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  },
  parseOptionsLogoConfig(options) {
    if (
      options &&
      options.Configs &&
      options.Configs.LogoConfig &&
      options.Configs.LogoConfig.Url
    ) {
      let _e = document.getElementById("brandlogo");
      if (_e) {
        _e.href = options.Configs.LogoConfig.Url;
      }
    }
  },
  updateDataWithNewData(
    oldData,
    newData,
    { fieldId = "Id", prefixNotUpdate = "_", customUpdate } = {}
  ) {
    if (oldData && newData) {
      let _isCondition = true;
      if (fieldId) {
        _isCondition = oldData[fieldId] === newData[fieldId];
      }
      if (_isCondition) {
        Object.keys(newData).map((v, i) => {
          if (customUpdate) {
            customUpdate(oldData, newData, v);
          } else {
            oldData[v] = newData[v];
          }
        });
      }
    }
  },
  getQueryFromData(query, data) {
    let _queryMore = query || {};
    let _query = {};
    let _obj = Object.keys(_queryMore);
    if (_obj && _obj.length > 0) {
      for (let i of _obj) {
        let _value = _queryMore[i];
        if (data.hasOwnProperty(_value) == true) {
          if (data[_value] != null) {
            _query[i] = data[_value];
          }
        } else {
          _query[i] = _queryMore[i];
        }
      }
    }
    return _query;
  },
  runFnList(fnList, fnName, context = this, args,opts={}) {
    if (fnList && fnName && fnList[fnName] && isFunction(fnList[fnName])) {
      return fnList[fnName].apply(context, args);
    } else {
      if(opts.disabledWarn!==true){
        if(fnList && fnList[fnName]==null){
          console.warn("runFnList failed:", fnList, fnName, context, args, opts);
        }        
      }
    }
  },
  buildGridUIWithConfig(configCol) {
    let _style = {};
    if (configCol && configCol.default) {
      let _numCol = Utils.Style.getNumCol(configCol, 1);
      let _colGapPercent = 1;
      let _widthCol = Utils.Style.getWidthPercentCol(_numCol, {
        colGapPercent: _colGapPercent,
      });
      let _gridTemplateColumns = "";
      for (let i = 0; i < _numCol; i++) {
        _gridTemplateColumns += `${_widthCol} `;
      }
      _style.display = "grid";
      _style.gridGap = `${_colGapPercent}%`;
      _style.rowGap = "10px";
      _style.gridTemplateColumns = _gridTemplateColumns;
    }
    return _style;
  },
  runFnConfig(configBuild, fnName, arrArguments, dfFn) {
    if (configBuild && configBuild.fnList && configBuild.fnList[fnName]) {
      configBuild.fnList[fnName].apply(configBuild, arrArguments);
    } else if (dfFn) {
      dfFn.apply(configBuild, arrArguments);
    }
  },
  getInObj(obj, path) {
    return Utils.Obj.get(obj, path);
  },
  get(obj, path){
    return Utils.Obj.get(obj, path);
  },
  arrayObj2ObjWithKey: function (arr, keyField) {
    var rv = {};
    if (arr != null) {
      for (var i = 0; i < arr.length; i++) {
        var _vf = arr[i][keyField] || i;
        rv[_vf] = arr[i];
      }
    } else {
      console.log("arrayObj2ObjWithKey: arr null");
    }
    return rv;
  },
  getQueryFromObj: function (query, obj) {
    let _result = {};
    if (query && obj) {
      let _keys = Object.keys(query);
      if (_keys.length > 0) {
        for (let i = 0; i < _keys.length; i++) {
          const _k = _keys[i];
          if (obj.hasOwnProperty(query[_k])) {
            _result[_k] = obj[query[_k]];
          }
        }
      }
    }
    return _result;
  },
  Obj: {
    has: has,
    set: set,
    sortByLodash(arr,fields,option){
      if(orderBy){
        // console.warn("sortByLodash:",arr,fields,option);
        return orderBy(arr,fields,option);
      }
      return arr;
    },
    groupFromArray(arr, fieldKey, typeItem = "array") {
      //[{k1:"a1",k2:"a2"},{k1:"a12",k2:"a22"}]=>{k1:[{k1:"a1",k2:"a2"}],k2:[{k1:"a12",k2:"a22"}]}
      let _group = {};
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          let _item = arr[i];
          let _key = _item[fieldKey] || "nokey";
          if (_group[_key] == null) {
            if (typeItem == "object") {
              _group[_key] = {};
            } else {
              _group[_key] = [];
            }
          }
          if (_group[_key] != null) {
            if (typeItem == "object") {
              _group[_key] = _item;
            } else {
              _group[_key].push(_item);
            }
          }
        }
      }
      return _group;
    },
    groupFromArray2Level(arr, fieldKey1, fieldKey2) {
      //[{k1:"a1",k2:"a2"},{k1:"a12",k2:"a22"}]=>{k1:[{k1:"a1",k2:"a2"}],k2:[{k1:"a12",k2:"a22"}]}
      let _group = Utils.Obj.groupFromArray(arr, fieldKey1);
      if (_group && fieldKey2) {
        let _allKeys = Object.keys(_group);
        if (_allKeys && _allKeys.length > 0) {
          for (let k of _allKeys) {
            let _arrOfKey1 = _group[k];
            let _group2 = Utils.Obj.groupFromArray(
              _arrOfKey1,
              fieldKey2,
              "object"
            );
            _group[k] = _group2;
          }
        }
      }
      return _group;
    },
    groupFromArrayKeepFieldMaster(arr, fieldKey) {
      let _group = [];
      if (arr && Array.isArray(fieldKey)) {
        if (fieldKey.length == 1) {
          for (let i = 0; i < arr.length; i++) {
            const _item = arr[i];
            let _exist = _group.find((k) => k[fieldKey] === _item[fieldKey]);
            if (_exist) {
              _exist.Children.push(_item);
            } else {
              let _obj = {};
              _obj.Children = [];
              _obj[fieldKey] = _item[fieldKey];
              _obj.Key = fieldKey;
              _obj.Children.push(_item);
              _group.push(_obj);
            }
          }
        }
      }
      return _group;
    },
    fastDeepEqual(a, b) {
      if (a === b) return true;
      if (a && b && typeof a == "object" && typeof b == "object") {
        if (a.constructor !== b.constructor) return false;
        var length, i, keys;
        if (Array.isArray(a)) {
          length = a.length;
          if (length != b.length) return false;
          for (i = length; i-- !== 0; )
            if (!Utils.Obj.fastDeepEqual(a[i], b[i])) return false;
          return true;
        }

        if (a instanceof Map && b instanceof Map) {
          if (a.size !== b.size) return false;
          for (i of a.entries()) if (!b.has(i[0])) return false;
          for (i of a.entries())
            if (!Utils.Obj.fastDeepEqual(i[1], b.get(i[0]))) return false;
          return true;
        }

        if (a instanceof Set && b instanceof Set) {
          if (a.size !== b.size) return false;
          for (i of a.entries()) if (!b.has(i[0])) return false;
          return true;
        }

        if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
          length = a.length;
          if (length != b.length) return false;
          for (i = length; i-- !== 0; ) if (a[i] !== b[i]) return false;
          return true;
        }

        if (a.constructor === RegExp)
          return a.source === b.source && a.flags === b.flags;
        if (a.valueOf !== Object.prototype.valueOf)
          return a.valueOf() === b.valueOf();
        if (a.toString !== Object.prototype.toString)
          return a.toString() === b.toString();

        keys = Object.keys(a);
        length = keys.length;
        if (length !== Object.keys(b).length) return false;

        for (i = length; i-- !== 0; )
          if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

        for (i = length; i-- !== 0; ) {
          var key = keys[i];
          if (key === "_owner" && a.$$typeof) {
            // React-specific: avoid traversing React elements' _owner.
            //  _owner contains circular references
            // and is not needed when comparing the actual elements (and not their owners)
            continue;
          }
          if (!Utils.Obj.fastDeepEqual(a[key], b[key])) return false;
        }
        return true;
      }
      // true if both NaN, false otherwise
      return a !== a && b !== b;
    },
    get: function (obj, path, def) {
      /**
       * If the path is a string, convert it to an array
       * @param  {String|Array} path The path
       * @return {Array}             The path array
       */
      if (obj != null && typeof obj == "object") {
        var stringToPath = function (path) {
          // If the path isn't a string, return it
          if (typeof path !== "string") return path;
          // Create new array
          var output = [];
          // Split to an array with dot notation
          path.split(".").forEach(function (item, index) {
            // Split to an array with bracket notation
            item.split(/\[([^}]+)\]/g).forEach(function (key) {
              // Push to the new array
              if (key.length > 0) {
                output.push(key);
              }
            });
          });
          return output;
        };
        // Get the path as an array
        path = stringToPath(path);
        // Cache the current object
        var current = obj;
        // For each item in the path, dig into the object
        for (var i = 0; i < path.length; i++) {
          // If the item isn't found, return the default (or null)
          if (current[path[i]] == null) return def;
          // Otherwise, update the current  value
          current = current[path[i]];
        }
        return current;
      } else {
        return def;
      }
    },
    notNull: function (obj, path, def) {
      if (obj != null && typeof obj == "object") {
        var stringToPath = function (path) {
          if (typeof path !== "string") return path;
          var output = [];
          path.split(".").forEach(function (item, index) {
            item.split(/\[([^}]+)\]/g).forEach(function (key) {
              if (key.length > 0) {
                output.push(key);
              }
            });
          });
          return output;
        };
        path = stringToPath(path);
        var current = obj;
        for (var i = 0; i < path.length; i++) {
          if (current[path[i]] == null) return false;
          current = current[path[i]];
        }
        return true;
      } else if (obj != null) {
        return true;
      } else {
        return false;
      }
    },
    addDataToObj(sourceObj, addedObj) {
      if (sourceObj && addedObj) {
        let _keys = Object.keys(addedObj);
        for (let k of _keys) {
          if(Utils.isArray(addedObj[k])){
            sourceObj[k] = addedObj[k];
          }
          else if (typeof addedObj[k] == "object") {
            if (sourceObj[k] == null) {
              //kiem tra neu chua co source[k] thi khoi tao object rong
              sourceObj[k] = {};
            }
            Utils.Obj.addDataToObj(sourceObj[k], addedObj[k]);
          } else {
            sourceObj[k] = addedObj[k];
          }
        }
      }
      return sourceObj;
    },
  },
  SquareBracket: {
    regex: /\[(.[^\]]*)\]/gm,
    extract(s) {
      var _result = [];
      if (s != null) {
        var _m;
        while ((_m = Utils.SquareBracket.regex.exec(s)) !== null) {
          if (_m.length > 1) {
            _result.push(_m[1]);
          }
        }
      }
      return _result;
    },
    replace(s, obj, { emptyWhenNull } = {}) {
      //replace nhieu gia tri, tra ve 1 string
      var extract = Utils.SquareBracket.extract(s);
      var newS = s;
      if (extract != null && obj != null) {
        for (var _item of extract) {
          if (obj[_item] != null) {
            var rg = new RegExp(`\\[${_item}\\]`, "g");
            newS = newS.replace(rg, obj[_item]);
          } else if (emptyWhenNull == true && obj[_item] == null) {
            ////Bo sung emptyWhenNull, de ko muon [] trong chuoi sau khi replace
            var rg = new RegExp(`\\[${_item}\\]`, "g");
            newS = newS.replace(rg, "");
          }
        }
      }
      return newS;
    },
    getValueOfObj(s, obj) {
      //tra ve 1 gia tri trong obj
      var extract = Utils.SquareBracket.extract(s);
      if (extract != null) {
        for (var _item of extract) {
          return obj[_item];
        }
      }
    },
  },
  Style: {
    getByWidth(obj, df) {
      //get by config width
      let _objRes = obj || df;
      if (typeof obj == "object") {
        if (obj.default) {
          _objRes = obj.default;
        } else {
          //bat buoc co default moi xet width
          return _objRes;
        }
        const windowWidth = (window && window.innerWidth) || Infinity;
        let matchedBreakpoint = Infinity;
        for (let k of Object.keys(obj)) {
          const optBreakpoint = parseInt(k);
          const isCurrentBreakpoint =
            optBreakpoint > 0 && windowWidth <= optBreakpoint;
          if (isCurrentBreakpoint && optBreakpoint < matchedBreakpoint) {
            matchedBreakpoint = optBreakpoint;
            _objRes = obj[k];
          }
        }
      }
      return _objRes;
    },
    getStyleByWidth(obj) {
      if (obj && obj.default) {
        return Utils.Style.getByWidth(obj, {});
      }
      return obj;
    },
    getNumCol(col, df = 1) {
      //number or object
      let _numCol = col || df;
      if (typeof _numCol == "number") {
        return _numCol;
      }
      return Utils.Style.getByWidth(_numCol, df);
    },
    getWidthPercentCol(numCol = 1, { colGapPercent } = {}) {
      let _width = 100;
      let _fullWidth = 100;
      if (colGapPercent && numCol > 1) {
        _fullWidth -= numCol - 1;
      }
      if (numCol > 0) {
        _width = Math.floor((_fullWidth / numCol) * 100) / 100;
      }
      return `${_width}%`;
    },
  },
  DataConvert:{
    /*
    var a = [{color:"red",age:12,name:"A"},{color:"blue",age:14,name:"B"},{color:"red",age:15,name:"C"}];
    */
    convert(arr,type,args){
      if(Utils.DataConvert[type]){
        return Utils.DataConvert[type].apply(this,[arr,...args]);
      }
      return arr;
    },
    convertByDataConvert(arr,dataConvert){//dataConvert: [{type:"",args:[]}]
      if(arr && dataConvert && Utils.isArray(dataConvert)){
        let _result = arr;
        for(let i=0;i<dataConvert.length;i++){
          let _c = dataConvert[i];
          if(_c.type && _c.args){
            _result = Utils.DataConvert.convert(_result,_c.type,_c.args);
          }
        }
        return _result;
      }
      return arr;
    },
    groupBy(arr,fieldGroup,{fKey="key",fChildrens="list"}={}){//[arr]=>{fieldGroup:[arr items]}
      if(arr){
        return _.chain(arr).groupBy(fieldGroup).map((value, key) => ({ [fKey]: key, [fChildrens]: value })).value()
      } 
    },
    sortBy(arr,fieldSort,typeSort='asc'){
      if(arr){
        if(typeSort=='desc'){
          return _.sortBy(arr,fieldSort).reverse();
        }
        return _.sortBy(arr,fieldSort)
      }
    },
    map(arr,mapFields){
      if(arr && mapFields){
        let _keys = Object.keys(mapFields);
        let _keep = [];
        if(mapFields._keep){
          _keep = mapFields._keep;
        }
        return arr.map((v,i)=>{
          let _newObj = {};
          for(let k of _keys){
            _newObj[k] = v[mapFields[k]];
          }
          for(let j=0;j<_keep.length;j++){
            _newObj[_keep[j]] = v[_keep[j]];
          }
          return _newObj;
        })
      }
    },
    chain(arr,chainList){
      
    },
  },
  runFuntion: (fnList, name, args = []) => {
    if (fnList && isFunction(fnList[name])) {
      fnList[name].apply(null, args)
    } else {
      console.warn("runFuntion failed:", fnList, name)
    }
  }
};

window.SquareBracket = Utils.SquareBracket;
window.HUtils = Utils;
window._M && window._M.addObj("HUtils", Utils);
export default Utils;

/**
 * Test: 16/3/2021
 */

// console.warn('================================== TEST ===============================')
// console.warn('================================== End TEST ===============================')
