import React from 'react';
import {MyTableHeaderHelpers} from "@macashipo/mlib"
function ComponentLoading(props) {
  return (
    <div></div>
  )
}

const Default = React.lazy(() => import('./Types/_default'));
const Styled = React.lazy(() => import('./Types/styled'));
const Column = React.lazy(() => import('./Types/column'));
const Split = React.lazy(() => import('./Types/split'));
const Html = React.lazy(() => import('./Types/html'));
const SplitFiles = React.lazy(() => import('./Types/split_files'));
const MTH_tooltip = React.lazy(() => import('./Types/tooltip'));
const TableHeaderTypes = {
  addTypes(key,fnUI){
    if(TableHeaderTypes.UI[key]==null){
        TableHeaderTypes.UI[key] = fnUI;
    }
    else{
      console.warn('addTypes exist:',key)
    }
  },
  UI:{
    _default:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Default {...props}/> </React.Suspense> ) },
    styled:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Styled {...props}/> </React.Suspense> ) },
    column:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Column {...props}/> </React.Suspense> ) },
    split:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Split {...props}/> </React.Suspense> ) },
    html:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Html {...props}/> </React.Suspense> ) },
    split_files:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <SplitFiles {...props}/> </React.Suspense> ) },
    tooltip:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <MTH_tooltip {...props}/> </React.Suspense> ) },
  }
}

const ListHeader = {
  _default: Default,
  styled: Styled,
  column: Column,
  split: Split,
  html: Html,
  split_files: SplitFiles,
  tooltip: MTH_tooltip
}
MyTableHeaderHelpers.addTypesFromListLazy(ListHeader)
export default TableHeaderTypes;