import MyUIHelpers from '../../components/MyUI/MyUIHelpers'
import HConstant from '../../helpers/Constant';
const MyPageHelpers = {
  parseConfigInConfigPage(configPage){
    let _config = {};
    // console.warn("parseConfigInConfigPage:",configPage);
    if(configPage){
      //
      HConstant.rc(HConstant.AppFnList.convertConfigPage,[configPage]);

      if(configPage.Config){
        if(typeof configPage.Config=="object"){
          _config = configPage.Config;
        }
        else if(typeof configPage.Config=="string"){
          try {
            _config = JSON.parse(configPage.Config);
          } catch (error) {
            console.warn("parsejsonerr:",configPage);
          }
        }      
      }
      if(configPage._ConfigObj){//Client, chuyen tu ConfigObj sang _ConfigObj de tranh merge nhieu lan
        _config = {..._config,...configPage._ConfigObj}
      }
      configPage.ConfigObj = _config;
    }
        
    //parse ListUI    
    // console.warn("MyPageHelpers:",configPage,_config);
    if(_config && _config.listUI){
      MyUIHelpers.addListUI(_config.listUI);
    }
    return _config;
  }
}

export default MyPageHelpers;