import React from 'react'
import MyLayoutTypes from './MyLayoutTypes';
import HUtils from '../../helpers/Utils'
class MyLayout extends React.Component {    
  render(){
    const {type} = this.props;
    if(type && MyLayoutTypes.Types[type]){
      return MyLayoutTypes.Types[type](this.props);
    }
    HUtils.logExistType(type);
    return (
      <div></div>
    )
  } 
}

export default MyLayout