const PasteHelper = {  
  fnUpload: null,
  fnPatse: function(e){
    e.preventDefault();
    console.warn(`paste:`,e);
    var items = e.clipboardData.items;
    console.warn(`paste items:`,items);
    let _files = [];
    if(items){
      for (var i = 0 ; i < items.length ; i++) {
        var item = items[i];
        console.log("Paste Item type: " + item.type,item.kind);
        if(item.kind=="file"){
          let _file = item.getAsFile();
          _files.push(_file);
          console.log("Paste Item file: " + item,_file);
        }
        else{
          const text = (e.originalEvent || e).clipboardData.getData('text/plain');
          // window.document.execCommand('insertText', false, text);
          console.log(`paste text:`,text);
        }
      }  
      console.log(`PasteHelper.fnUpload:`,PasteHelper.fnUpload);
      if(PasteHelper.fnUpload && _files.length>0){
        PasteHelper.fnUpload(_files);
      }
    }  
  },
  checkPasteSupported(){
    var el = document.createElement('div');
    el.setAttribute('onpaste', 'return;');
    return typeof el.onpaste == "function";
  },
  addEventUploadFileAfterPaste: function(fnUpload){
    PasteHelper.fnUpload = fnUpload;
  },
  removeEventUploadFileAfterPaste: function(){
    PasteHelper.fnUpload = null;    
  },
  removeEvenPaste: function(){
    window.removeEventListener("paste", PasteHelper.fnPatse , false);
  },
  addEventPaste: function(){
    window.addEventListener("paste", PasteHelper.fnPatse , false);
  },
};
export default PasteHelper;