
export default {
  data:{},
  key:{
    Planweek_ColorPj: 'Planweek_ColorPj',
    Planweek_ColorNote: 'Planweek_ColorNote',
    Planweek_MenuDepartment: 'Planweek_MenuDepartment',
    Scope_ObjectDTC: 'Scope_ObjectDTC',
    QualityManagement_ObjectDTC: 'QualityManagement_ObjectDTC',
    Milestone_ColorSelect2: 'Milestone_ColorSelect2',
    CRMOpty_ColorPj: 'CRMOpty_ColorPj',
  },
  getData(key,fnGetValue){
    if(this.data[key]!=null){
      //console.log('Store getData:',key);
      return this.data[key];
    }
    else if(fnGetValue!=null){
      let _newValue = fnGetValue();
      this.setData(key,_newValue);
      return _newValue;
    }
  },
  setData(key,data){
    //console.log('Store setData:',key,data);
    this.data[key] = data!=null?data:null;
  },
}