import React from 'react';
import {
  Button
} from '../../../_importComponent';
import HH from '../../../helpers/H';
import { Popover } from '../../../mreact/core';
import Tooltip from '../../../mreact/tooltip';
import ApiGeneric from '../../../services/generic';
import { CellHelper } from '../Helpers';
import {HText} from '../../../_import'
import {fnListUI} from "src/helpers/fnList"
/**
 * Cell Type: api.
 *
 * @class api
 * @static
 * @namespace api
 * @memberof CellType
 * 
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
      isLoading: false,
    };
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.api
   * @param {Object} [style] style của Component
   * @param {Object} [button] config của button
   * @param {String} [method] method của api
   * @param {String} [url] url của api
   * @param {Boolean} [needReload] reload lại table hay không
   * @param {Boolean} [needReExpand] expand hay không
   * @param {String} [queryString] data gửi lên cho hàm gọi api
   * @param {String} [fShowWhenEmpty] show text khi không show button
   * @param {String} [confirmMsg] nội dung câu ConfirmMsg
   * @param {String} [showModalMsg] showModalMsg=true thì show ConfirmMsg bằng modal (mặc định là Popover)
   * @param {String} [titleConfirm] title của popover (mặc định là "Confirm")
   * 
  */
  more() {
  }

  _onClickApi=()=>{
    const {cell,row,extra} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _fnList = CellHelper.getFnList({extra,row});
    let _query = CellHelper.More.getQuery({extra,row,more:_more});
    this.setState({
      isLoading: true,
    },()=>{
      ApiGeneric.generic({
        // request:{
        //   method: CellHelper.More.getRequestMethod({extra,row,more:_more}),
        //   url: _url
        // },
        request: CellHelper.More.getConfigRequest({extra,row,more:_more}),
        data:_query,
        successCallBack:(response)=>{
          this.setState({
            isLoading: false,
          })
          fnListUI.fnShowToast(response.Msg)    
          CellHelper.More.parseResponse({extra,row,more:_more,fnList:_fnList,response:response});
          CellHelper.More.needReload({extra,row,more:_more,fnList:_fnList,response});
        },
        errorCallBack:(error,response)=>{
          this.setState({
            isLoading: false,
          })
        }
      });
    });              
  }

  render() {
    const {cell,row,extra} = this.props;
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    // let _fnList = CellHelper.getFnList({extra,row});
    let _tooltip = CellHelper.getExtraTooltip({extra,row});
    let _configButton = CellHelper.More.getConfigButton({extra,row, more:_more});
    // let _morePropsButton = _configButton.props || {};
    if(CellHelper.getIsHideWhenEmpty({extra,cell,row,more:_more,df:true})){
      return CellHelper.renderEmpty({extra,row,more:_more,style:_style,df:""});
    }
    let _confirmMsg = CellHelper.More.getConfirmMsg({extra,row,more:_more}); 
    let _titleConfirm = CellHelper.More.getConfirmTitle({extra,row,more:_more});
    // if (_more!=null && _more.titleConfirm){_titleConfirm = _more.titleConfirm;}
    let _content = CellHelper.More.getTextDisplayInButton({extra,row,more:_more,cell,icon:_configButton.icon,title:_configButton.title,hasIconAndText:_configButton.hasIconAndText});
    let _defaultPropsButton = {
      className: "has-wrap",
      color:"primary",
      outline: false,
      style: {padding:'0px',width:'100%',height:'100%'},
      disabled: this.state.isLoading==true?true:false,
    };
    let _ui = [];
    if (_confirmMsg!=null && _confirmMsg.length>0){
      if (_more!=null && _more.showModalMsg && _more.showModalMsg==true){
        _ui = (
          <div className="idiv-ro has-wrap" style={_style}>
            <Button 
              // className={_configButton.className!=null?_configButton.className:"has-wrap"}
              // color={_configButton.color!=null?_configButton.color:"primary"}
              // outline={_configButton.outline!=null?_configButton.outline:false}
              // style={_styleButton}
              onClick={()=>{
                console.log("click api",_more);
                HH().Alert.showMsgQuestion(_titleConfirm,_confirmMsg,()=>{
                  console.log('ok');
                  this._onClickApi();
                },()=>{
                  console.log('dissmiss');
                })
  
              }}
              {...CellHelper.More.getMorePropsButton({extra,row,more:_more,df:_defaultPropsButton,content:_content})}>
                {CellHelper.renderLoading({isShow:this.state.isLoading})}
                {_content}
              </Button>
          </div>
        )
      }else{
        _ui = (
          <div className="idiv-ro has-wrap" style={_style}>
            <Popover placement="topRight" title={_titleConfirm}
              overlay={<div>{_confirmMsg}</div>} trigger="click" showButton okText={HText.get('btn_ok')} cancelText={HText.get('btn_close')} 
              onOk={(hide)=>{
                this._onClickApi();
                if(hide){hide()}
              }} 
              onCancel={()=>{console.log('Cancel')}}>
            <Button 
              // className={_configButton.className!=null?_configButton.className:"has-wrap"}
              // color={_configButton.color!=null?_configButton.color:"primary"}
              // outline={_configButton.outline!=null?_configButton.outline:false}
              // style={_styleButton}
              onKeyPress={(ev)=>{
                if(ev.key === 'Enter'){
                  // console.warn('press Enter');
                  this._onClickApi();
                }}}
              // onClick={()=>{
              // }}
              {...CellHelper.More.getMorePropsButton({extra,row,more:_more,df:_defaultPropsButton,content:_content})}>
                {CellHelper.renderLoading({isShow:this.state.isLoading})}
                {_content}
              </Button>
            </Popover>
    
          </div>
        )
      }      
    }else{
      _ui = (
        <div className="idiv-ro has-wrap" style={_style}>
          <Button 
            // className={_configButton.className!=null?_configButton.className:"has-wrap"}
            // color={_configButton.color!=null?_configButton.color:"primary"}
            // outline={_configButton.outline!=null?_configButton.outline:false}
            // style={_styleButton}
            onClick={()=>{
              this._onClickApi();
            }}
            {...CellHelper.More.getMorePropsButton({extra,row,more:_more,df:_defaultPropsButton,content:_content})}>
              {CellHelper.renderLoading({isShow:this.state.isLoading})}
              {_content}
          </Button>
        </div>
      )
    }    
    if(_tooltip){
      return (
        <Tooltip placement="top" overlay={_tooltip} >
          {_ui}
        </Tooltip>
      )
    }
    return _ui;
  }
}

export default Z;