import React, { Component } from 'react';
class MyLoadingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render() {
    return (
      <div className={"mlds"}><i className="fa fa-spinner fa-spin" style={{fontSize:"30px"}}/></div>
    )
  }
}

export default MyLoadingScreen;
