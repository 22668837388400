import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from '../../_importLibs';
import HH from '../../helpers/H';

class MyTableBootstrap extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  componentDidMount(){
  }

  cleanSort=()=>{
    if(this._cTable){
      this._cTable.cleanSort();
    }
  }

  handleFilterData=(obj)=>{
    if(this._cTable!=null){
      this._cTable.handleFilterData(obj);
    }
  }
  handleSearch=(obj)=>{
    if(this._cTable!=null){
      this._cTable.handleSearch(obj);
    }
  }
  handleExportCSV=()=>{
    if(this._cTable!=null){
      this._cTable.handleExportCSV();
    }
  }
  
  getTableDataIgnorePaging=()=>{
    if(this._cTable!=null){
      return this._cTable.getTableDataIgnorePaging();
    }
  }

  _renderCol=(col,i)=>{
    const {colsHidden,colsRemove} = this.props;
    let _defaultCol = {
      width:'100px',
      dataField: '',
      dataAlign: 'left',
      isKey:false,
      hidden:false,
      dataSort:true,
      header: '',
      dataFormat: (cell,row,e,r)=>{return cell},
      columnClassName: 's_relative',
      className: '',
    }
    _defaultCol = Object.assign(_defaultCol,col);
    var {width,fnGetWidth,isKey,hidden,dataField,filter,dataAlign,dataSort,header,dataFormat,columnClassName,className} = _defaultCol;

    var moreProps = {
    };
    if(col.colProps!=null){
      moreProps = Object.assign(moreProps,col.colProps);
    }
    let _header = header;
    if(moreProps.componentHeader!=null){
      _header = moreProps.componentHeader(header,dataField);
    }
    if(colsHidden && colsHidden.indexOf(dataField)>-1){
      hidden = true;
    }
    if(colsRemove && colsRemove.indexOf(dataField)>-1){
      return <TableHeaderColumn key={i} hidden/>
    }
    let _width = width;
    if(typeof width=="function"){
      _width=width();
    }
    return (
      <TableHeaderColumn 
        key={i}
        columnClassName={columnClassName}
        className={className}
        width={_width} 
        isKey={isKey}
        hidden={hidden} 
        editable={ false } 
        dataField={dataField}
        dataAlign={dataAlign}
        filter={filter}
        formatExtraData={{fieldName:dataField}}
        dataFormat={dataFormat}
        expandable={false}
        dataSort={dataSort}
        {...moreProps}>
        { _header }
      </TableHeaderColumn>
    )
  }
  _renderTable=()=>{
    const {data,cols,hideHeader,tableProps,optionsTable}=this.props;
    const {className} = this.props;
    let _className = "s_table table-cell-full";
    if(hideHeader==true){
      _className+= ' s_table_hideheader';
    }
    if(className!=null && className.length>0){
      _className += ` ${className}`;
    }

    let _optionsTable = {
      sortIndicator: true,
      hideSizePerPage: false,
      sizePerPage: 100,
      // hidePageListOnlyOnePage: true,
      paginationShowsTotal: true,
      withFirstAndLast: true,
      paginationSize: 3,
      hidePageListOnlyOnePage: true,
      clearSearch: true,
      alwaysShowAllBtns: false,
    }
    if(optionsTable!=null){
      _optionsTable = Object.assign(_optionsTable,optionsTable);
    }

    let _uiCols = [];
    if(cols!=null && cols.length>0){
      for(let i=0;i<cols.length;i++){
        _uiCols.push(
          this._renderCol(cols[i],i)
        )
      }
    }
    else{
      return (
        <div>Loading....</div>
      )
    }

    var moreProps = {
    };
    moreProps = Object.assign(moreProps,tableProps);

    return (
      <BootstrapTable 
        className={_className} 
        ref={r=>{this._cTable=r;}} 
        data={data} version="4" striped={HH().Config.DefaultValues.tableStriped} pagination search={false} hover={HH().Config.DefaultValues.tableHover} //striped đổi thành true (10/09/2019), thêm hover=true (10/09/2019)
        options={_optionsTable} 
        insertRow={ false }
        {...moreProps}>
        {
          _uiCols
        }
      </BootstrapTable>
    )
  }

  render() {
    return (
      <div>
        {this._renderTable()}
      </div>
    )
  }
}

export default MyTableBootstrap;
