import React from 'react';
import Global from '../Global';

const OptionsHelper = {
  createListForSelect({sourceList,itemEmpty,currentValue,currentValueText,isNotAutoAddWhenNotExist}={}){
    let _ui = [];
    if(sourceList!=null){
      if(itemEmpty!=null){
        _ui.push(
          <option key={-1} value={""}>{itemEmpty}</option>
        );
      }
      let _isExistCurrentValue = false;
      for(let i=0;i<sourceList.length;i++){
        if(sourceList[i]!=null){//fix truong hop item source null
          let _value = sourceList[i].Value;
          let _style={};
          let _colorCode = sourceList[i].ColorCode;
          if(_colorCode){
            _style.backgroundColor = _colorCode;          
            let _colorText = Global.Color.getTextColorBG(_colorCode);          
            if(_colorText){
              _style.color = _colorText;
            }
          }
          if(currentValue!=null && currentValue==_value){
            _isExistCurrentValue = true;
          }
          _ui.push(
            <option key={i} value={_value} style={_style}>{sourceList[i].Text}</option>
          );
        }
        else{
          console.warn("Source item null",sourceList);
        }        
      }
      if(_isExistCurrentValue==false && currentValue!=null && currentValue!='' && isNotAutoAddWhenNotExist!=true){
        _ui.push(
          <option key={'c'} value={currentValue}>{currentValueText||currentValue}</option>
        );
      }
    }
    return _ui;
  },
  convertOptionsApiToSelect2({sourceList}){
    let _sourceList = [];
    if(sourceList!=null && sourceList.length>0){
      _sourceList = sourceList.map((e,i)=>{
        return {value: e.Value, label: e.Text};
      })
    }
    return _sourceList;
  },
  createOptionsLevelFromOptionsApi({options,sourceKey,childKey}){
    if(options!=null){
      let _sourceList = options[sourceKey];
      if(_sourceList!=null){
        let _newSourceList = {};
        for(let i in _sourceList){
          let _item = _sourceList[i];
          if(_item[childKey]!=null){
            _newSourceList[_item.Value] = _item[childKey];
          }
        }
        options[sourceKey+'_'+childKey] = _newSourceList;
        return _newSourceList;
      }
    }
    return [];
  },
}

export default OptionsHelper;