import React, { Component } from 'react';
import {Select } from '../../../_importLibs'
const Select2Color=(option)=>{
  let _bg = option.value||'transparent';
  return (
    <div style={{display:'flex',alignItems:'center'}}><i className="fa fa-square" style={{color:_bg,fontSize:'20px'}}></i> &nbsp; {option.label}</div>
  )
}

class SelectColor extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selected: null,
    };
  }

  render() {
    const {moreProps, options, onChange, selected} = this.props;
    return (
      <Select
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        autoFocus={false}
        options={options}
        valueRenderer={Select2Color}
        optionRenderer={Select2Color}
        value={this.state.selected||selected}
        simpleValue
        multi={false}
        closeOnSelect={true}
        clearable={true}
        name="selected-state"
        // value={this.state.WPGGroupId}
        onChange={(newValue)=>{
          console.log('onChange:',newValue);
          this.setState({
            selected: newValue
          },()=>{
            if(onChange!=null){
              onChange(newValue);
            }
          })
        }}
        searchable={true}
        {...moreProps}
      />
    )
  }
}

SelectColor.Helper = {
  ConvertDataFromServer(options){
    let _options = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        let _item = options[i];
        let _newItem = {
          value: _item.ColorCode,
          label: _item.Name,
          bg: _item.ColorCode,
          color: _item.ColorCode,
        }
        _options.push(_newItem);
      }
    }
    return _options;
  }
}

export default SelectColor;