// import ConfigSolid from "../solid/_config";
import * as Routers from "./_routes";
import AppMyPage from "./components/MyPage";
import AppMyUI from "./components/MyUI";
import AppMyLayout from './components/MyLayout';
import HRouter from "../../helpers/Route";
import HStore from "../../helpers/StoreRedux";
import HConstant from '../../helpers/Constant'
import HC from "../../helpers/Constant";
import HGlobalSetting from '../../helpers/GlobalSetting';
import HelperV3 from './_helpers'

import * as CustomStore from "./_store";
const ConfigBuild = {
  Routers: Routers,
  ConfigApp: {
    BaseUrl: "https://desearchdevapi.mezy.com.au",
    BaseUrl_dev:  "https://desearchdevapi.mezy.com.au",
    defaultHomePage: '',
    isUseHash: true,//Su dung router Hash,
    googleClientId: "786359577708-65mnb6iqfg4fev8mgrlvu9hb4o22um8f.apps.googleusercontent.com",
    appName: 'De Search',
    theme: "theme-de",
    metaName: 'DE',
    metaUrl: '',
    hasRequestFirst: true,
    showLoginWithUserPassword: true,
    pathLogo: '',
    home_url: '/search',
    footer: '',
  },
  fnList: {
    afterInit(HConfig) {
      ConfigBuild.HConfig = HConfig;
      HConstant.TableConfig._default.canDelete = false
      console.warn("RunAfterInit:", Routers);
      /** GA */
      // HGA.initialize();

      HRouter.initRouter(HConfig);
      HC.initConstant(HConfig);
      //init MyPage
      AppMyPage.init();
      AppMyUI.init();
      AppMyLayout.init();
      HelperV3.initLocalRoute();
      HStore.initStore(HConfig, CustomStore);
    },
  },
};

export default ConfigBuild;
