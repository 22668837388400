import React from 'react'
import {MyUI} from "@macashipo/mlib"

/**
 * 
 * td: array
 * th: array
 * tr: fields get data
 * 
 */
class MyUI_TagTable extends React.Component {    
  renderTH=()=>{
    const {th,thSt,thStR} = this.props;
    if(th && Array.isArray(th)){      
      return (
        <tr style={thStR}>
        {
          th.map((v,i)=>{
            let _style;
            if(thSt && thSt[i]!=null){
              _style = thSt[i];
            }
            return (
              <th key={i} style={_style}>{v}</th>
            )
          })
        }
        </tr>
      )
    }    
  }
  renderTD=({data}={})=>{
    const {td,tdSt} = this.props;
    if(td && Array.isArray(td)){
      return td.map((v,i)=>{
        let _style;
        if(tdSt && tdSt[i]!=null){
          _style = tdSt[i];
        }
        return (
        <td key={i} style={_style}>{this.renderCell({v,data})}</td>
        )
      })
    }  
  }
  renderCell=({v,data})=>{
    const {fnList} = this.props;
    if(v){
      return (
        <MyUI type="o" configObj={v} data={data} fnList={fnList}/>
      )
    }    
  }
  renderTR=()=>{
    const {tr,trSt,fRB,fnList} = this.props;
    if(tr && Array.isArray(tr)){
      return tr.map((v,i)=>{
        let _style;
        if(trSt && trSt[i]!=null){
          _style = trSt[i];
        }
        return (
          <React.Fragment key={i}>
            {
              fRB!=null &&
              <tr key={`frb_${i}`} style={fRB.st}>
                <td colSpan={fRB.colSpan}>
                  <MyUI type="o" configObj={fRB.ch} data={v} fnList={fnList}/>
                </td>
              </tr>
            }
            <tr key={i} style={_style}>
              {
                this.renderTD({data:v})
              }
            </tr>
          </React.Fragment>
        )
      })
    }  
  }
  render(){
    const {style,className,fnList,th,tr} = this.props;
    return (
      <table style={style} className={className}>
        <thead>
        {
          th!=null && this.renderTH()
        }
        </thead>
        <tbody>
        {
          tr!=null && this.renderTR()
        }
        </tbody>                
      </table>
    )
  } 
}

export default MyUI_TagTable