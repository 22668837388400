import HText from './Text'
const Filter = {
  hasTextInField(item,searchText,opts){
    if(item!=null){
      let _stringSearch = '';
      let _searchText = searchText;
      if(typeof item=="string"){
        _stringSearch = item;
      }
      else{
        try {
          _stringSearch = JSON.stringify(item);//boolean, number, object, array deu duoc
        } catch (error) {
          console.warn("error parse item to search");
        }
      }

      let _isNeedBothWord = true;
      if(opts){
        if(opts.exactly==false){
          if(opts.bothWord==false){
            _isNeedBothWord = false;
          }
        }      
      }
      _stringSearch = Filter.buildTextByOptsSearch(_stringSearch,opts);
      if(typeof _searchText=='string'){
        if(opts.ignoreCase){//tu chuyen search Text sang lower khi ignoreCase
          _searchText = _searchText.toLowerCase()
        }
        if(_stringSearch.indexOf(_searchText)>-1){
          return true;
        }
      }
      else if(Array.isArray(_searchText)){
        let _count = 0;
        for(let i=0;i<_searchText.length;i++){
          let _rs = Filter.hasTextInField(item,_searchText[i],opts);
          if(_rs==true){    
            if(_isNeedBothWord){
              _count++; 
              if(_count==_searchText.length){
                return true;
              }
            }
            else{
              return true;
            }            
          }
        }        
      }      
    }
    return false;
  },
  buildTextByOptsSearch(searchText,opts){
    let _newSearchText = searchText;
    // console.warn("buildTextByOptsSearch:",_newSearchText)
    if(Array.isArray(searchText) && searchText.length>0){
      return searchText.map((v,i)=>{
        return Filter.buildTextByOptsSearch(v,opts);
      })
    }
    if(opts){
      if(opts.trim){
        _newSearchText = _newSearchText.trim();
      }
      if(opts.ignoreCase){
        _newSearchText = _newSearchText.toLowerCase();
      }
      if(opts.xoadau){
        _newSearchText = HText.xoaDauInText(_newSearchText,{removeSpace:false,removeSpecialChars:false,convertLower:false});
      } 
      if(opts.exactly==false){
        _newSearchText = _newSearchText.split(/\s+/);
      }             
    }
    return _newSearchText;
  },
  hasTextInObj(obj,searchText,opts={
    exactly:true,//search nguyen 1 cum tu hay tach tung tu
    bothWord:true,//trong truong hop exactly = false, co can thiet co mat tat ca cac tu trong cum tu hay ko
    trim:true,//co trim word hay ko
    xoadau:true,//co xoa dau tieng viet hay ko
    ignoreCase: true,//Bo qua hoa thuong
    ignoreFields: null,//Mang field bo qua ko search
    onlyFields: null,//Chi search o trong mang field nay
  }){
    // console.warn("hasTextInObj:",opts);
    if(obj){
      let _keys = Object.keys(obj);
      if(_keys && _keys.length>0){
        let _newSearchText = searchText;
        // let _newSearchText = Filter.buildTextByOptsSearch(searchText,opts);
        // console.warn("search:",_newSearchText);
        for(let i=0;i<_keys.length;i++){
          let _k = _keys[i];
          if(opts.ignoreFields){
            if(opts.ignoreFields.indexOf(_k)>-1){
              continue;
            }
          }
          if(opts.onlyFields){
            if(opts.onlyFields.indexOf(_k)==-1){
              continue;
            }
          }
          let _item = obj[_k];          
          let _rs = Filter.hasTextInField(_item,_newSearchText,opts);
          if(_rs==true){
            return true;
          }
        }
      }      
    }
    return false;
  }
}

export default Filter