const LocalStorage = {
  getString(key, defaultValue) {
    return localStorage.getItem(key) || defaultValue;
  },
  getObject(key, defaultValue) {
    let _s = this.getString(key);
    if (_s != null) {
      try {
        let _obj = JSON.parse(_s);
        return _obj;
      } catch (error) {
        console.log("LocalStore getObject failed:", _s, error);
      }
    }
    return defaultValue;
  },
  setString(key, string) {
    if (key != null && string != null) {
      localStorage.setItem(key, string);
    } else {
      console.log("LocalStore setString failed:", key, string);
    }
  },
  setObject(key, obj) {
    if (key != null && obj != null) {
      let _s = JSON.stringify(obj);
      localStorage.setItem(key, _s);
    } else {
      console.log("LocalStore setObject failed:", key, obj);
    }
  },
  ConfigScreen: {
    key:{
      prefix: 'config_',
      filter: 'filter',
    },
    getConfig(key){
      if(key!=null){
        // console.log('getConfig:',this.key.prefix + key);
        return LocalStorage.getObject(this.key.prefix + key,{});
      }
    },
    setConfig(key,obj){
      if(key!=null){
        // console.log('ConfigScreen setConfig:',this.key.prefix + key,obj);
        return LocalStorage.setObject(this.key.prefix + key,obj);
      }
    },
    clearAllConfig(){
      if(localStorage!=null && Object.keys(localStorage)!=null){
        let _arrKeys = Object.keys(localStorage);
        for(let e of _arrKeys){
          if(e.startsWith(LocalStorage.ConfigScreen.key.prefix)==true){
            delete localStorage[e];
          }
        }
      }
    },
    getSavedFilter(key){
      if(key!=null){
        let _config = this.getConfig(key);
        if(_config!=null && _config[this.key.filter]!=null){
          console.log('getSavedFilter:',_config[this.key.filter]);
          return _config[this.key.filter];
        }
      }
    },
    clearSavedFilter(key){
      if(key!=null){
        let _config = this.getConfig(key);
        delete _config[this.key.filter];
        this.setConfig(key,_config);
      }
    },
    setSavedFilter(key,data){
      if(key!=null){
        let _config = this.getConfig(key);
        _config[this.key.filter] = data;
        this.setConfig(key,_config);
      }
    },
    clearCustomField({screenCode,key}){
      if(key!=null){
        let _config = this.getConfig(screenCode);
        delete _config[key];
        this.setConfig(key,_config);
      }
    },
    setCustomField({screenCode,key,data}){
      if(key!=null){
        let _config = this.getConfig(screenCode)||{};
        if(_config){
          _config[key] = data;
        }
        this.setConfig(screenCode,_config);
      }
    },
    getCustomField({screenCode,key}){
      if(screenCode!=null){
        let _config = this.getConfig(screenCode);
        if(_config!=null && _config[key]!=null){
          return _config[key];
        }
      }
    },
  },
}

export default LocalStorage;