import ReactGA from 'react-ga';
// import Config from '../helpers/Config';
// import { Auth } from '../helpers/Auth';
// const GAId = 'UA-119777650-1';
// const GAIdDev = 'UA-119777650-2';

const HGA = {
  HConfig: null,
  getHConfig(){
    if(HGA.HConfig && HGA.HConfig.fnList){
      return HGA.HConfig;
    }
  },
  getGAId(){
    const HConfig = HGA.getHConfig();
    if(HConfig){
      if(HConfig.fnList.getIsDevMode()==true){
        return HConfig.fnList.getConfig('GAId_Dev');
      }
      return HConfig.fnList.getConfig('GAId');
    }
  },
  initialize(Config){
    HGA.HConfig = Config;
    const HConfig = HGA.getHConfig();
    const GAId = HGA.getGAId();
    console.warn("initGA:",GAId);
    if(HConfig && GAId){
      if(HConfig.fnList.getIsDevMode()==true){
        ReactGA.initialize(GAId,{
          gaOptions:{
            userId: HConfig.fnList.getCurrentUserName()
          }
        });
      }
      else{
        ReactGA.initialize(GAId);
      }
      window._gaId = GAId;
    }      
  },
  trackingPageView({page}={}){
    const HConfig = HGA.getHConfig();
    const GAId = HGA.getGAId();
    if(HConfig && GAId){
      if(page!=null && page.length>0){
        let _page = page;
        console.log('trackingPageView: ',_page);
        let _user = HConfig.fnList.getCurrentUserName();
        if(_user!=null && _user.length>0){
          _page = _page + '?user=' + _user;
        }
        ReactGA.pageview(_page);
      }
      else{
        let _hash = window.location.hash;
        console.log('trackingPageView: ',_hash);
        let _user = HConfig.fnList.getCurrentUserName();
        if(_user!=null && _user.length>0){
          _hash = _hash + '?user=' + _user;
        }
        ReactGA.pageview(_hash);
      }
    }    
  },
  trackingEvent({category,action}){
    const GAId = HGA.getGAId();
    if(GAId){
      ReactGA.event({
        category: category,
        action: action,
      })
    }
  }
}

export default HGA