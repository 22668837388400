import React, { Component } from 'react';
import Types from './MyButtonTypes';
class MyButton extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
  }

  _renderButton=()=>{
    const {type} = this.props;
    let _type = typeof type=="string"?type.toLocaleLowerCase():"";
    if(_type && Types.UI[_type]){
      return Types.UI[_type](this.props);
    }
    return Types.UI._default(this.props);
  }
  render() {
    const {style} = this.props;
    return (
      <div style={style}>
        {this._renderButton()}
      </div>
    )
  }
}

export default MyButton;