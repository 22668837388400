import React, { Component } from 'react';
import { HModules, HCF} from "@macashipo/mlib"
import HConstant from './Constant';
import HDev from './Dev';
import HGA from '../libs/GA'
import HGlobal from './Global'
import HLink from './Link';
import HUI from './UI';
import HUtils from './Utils'
import HGlobalSetting from './GlobalSetting'
import HFormat from './Format'
import HUITooltip from './UITooltip';
import HPushNotifyChrome from './PushNotifyChrome'
import {Auth as HAuth} from './Auth';
import Api from '../services/api'
import { initI18n } from 'src/helpers/i18n'

import AppReduceCRM from '../apps/reduce/Config';
import AppBauCat from '../apps/baucat/Config';
import AppBauCatDev from '../apps/baucat/ConfigDev';
import AppHongVan from '../apps/hongvan/Config';
import AppHongVan_Dev from '../apps/hongvan/ConfigDev';
import AppAjuma from '../apps/ajuma/Config';
import AppAjuma2 from '../apps/ajuma/Config2';
import AppVFC02 from '../apps/vfc/Config2';
import AppCosco from '../apps/cosco/Config';
import AppAlliance from '../apps/allianceitsc/Config';
import AppSoezyReport from '../apps/soezyreport/Config';
/*
VFC Config
- LOGIN page: showLoginWithUserPassword: true,
- API: http://vfc02api.allianceitsc.com
- Search And replace "2018 Solid Interior". at className="app-footer"
 */


// const BaseUrl_dev = "https://devapi.solidapp.vn";//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
// const BaseUrl_pro = "https://api.solidapp.vn";
// const BaseUrl_pro_test = "https://apitest.solidapp.vn";
const AppCore = {
  BaseUrl_dev : "https://devapi.solidapp.vn",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://api2.solidapp.vn",
  BaseUrl_pro_test : "https://apitest.solidapp.vn",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://app.solidapp.vn">SolidApp</a> &copy; 2018 Solid Interior. </span>,
  title: 'Solid App',
  pathLogo: 'img/logo.png',
  pathFavicon: 'img/favicon-core.png',
  appClass: 'app-core',
}

const AppLocal = {
  BaseUrl_dev : "http://localhost:12391/",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "http://localhost:12391/",
  BaseUrl_pro_test : "http://localhost:12391/",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://app.solidapp.vn">Local</a> &copy; 2018 Local. </span>,
  title: 'Alliance App',
  pathLogo: 'img/logo-alliance.png',
  pathFavicon: 'img/favicon-alliance.png',
  appClass: 'app-local',
}

const AppSoEzy = {
  BaseUrl_dev : "http://localhost:32391",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "http://localhost:32391",
  BaseUrl_pro_test : "http://localhost:32391",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://app.solidapp.vn">Local</a> &copy; 2018 Local. </span>,
  title: 'Alliance App',
  pathLogo: 'img/logo-alliance.png',
  pathFavicon: 'img/favicon-alliance.png',
  appClass: 'app-local',
}

const AppSoEzy_admin = {
  BaseUrl_dev : "http://soezyapi-dev.allianceitsc.com",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://mezysearch-api.mezy.com.au",
  BaseUrl_pro_test : "http://soezyapi-dev.allianceitsc.com/",
  home_url: '/welcome',
  showLoginWithUserPassword: true,
  footer: <span><a href="http://mezy.com.au">Mezy</a> &copy; 2020</span>,
  title: 'SoEzy App',
  pathLogo: 'img/logo-mezy.png',
  pathFavicon: 'img/favicon-mezy.ico',
  appClass: 'app-soezy',
  expandSideMenu: true,
}

const AppLocalStock01 = {
  BaseUrl_dev : "http://localhost:25391/",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "http://localhost:25391/",
  BaseUrl_pro_test : "http://localhost:25391/",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://app.solidapp.vn">Local</a> &copy; 2018 Local. </span>,
  title: 'Alliance Stock',
  pathLogo: 'img/logo-alliance.png',
  pathFavicon: 'img/favicon-alliance.png',
  appClass: 'app-localstock01',
}

const AppSolidDev = {
  BaseUrl_dev : "http://soliddevapi.allianceitsc.com",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "http://soliddevapi.allianceitsc.com",
  BaseUrl_pro_test : "http://soliddevapi.allianceitsc.com",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: '<span><a href="http://app.solidapp.vn">SolidApp</a> &copy; 2018 DEV </span>',
  title: 'Solid App',
  pathLogo: 'img/logo-alliance.png',
  pathFavicon: 'img/favicon-alliance.png',
  GAId: 'UA-119777650-2',
  GAId_Dev: 'UA-119777650-2',
  appClass: 'app-solid',
  expandSideMenu: false,
  listFunctions: ['SolidNotify','PrintWatermark'],
  hasPushNotifyChrome: false,
  publicKeyPushNotify: 'BG105uwatjdxR0AmC5KqUoFdlB0s8LaqTJrpz7opxb5LG84uqo72Jm4bZcQn2hAA6VPwuk2Bh6TyezL1JWl2n18'//Kv5idnh_45OYMI6hQO9DjIJqObYOdn5t2Z2Hmm0iI8k
}

const AppSolid = {
  BaseUrl_dev : "https://api.solidapp.vn",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://api.solidapp.vn",
  BaseUrl_pro_test : "https://apitest.solidapp.vn",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://app.solidapp.vn">SolidApp</a> &copy; 2018 Solid Interior. </span>,
  title: 'Solid App',
  pathLogo: 'img/logo-solid.png',
  pathFavicon: 'img/favicon-solid.png',
  GAId: 'UA-119777650-1',
  appClass: 'app-solid',
  listFunctions: ['SolidNotify','PrintWatermark'],
}
const AppSolid2 = {
  BaseUrl_dev : "https://api2.solidapp.vn",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://api2.solidapp.vn",
  BaseUrl_pro_test : "https://apitest.solidapp.vn",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://app2.solidapp.vn">SolidApp</a> &copy; 2018 Solid Interior. </span>,
  title: 'Solid App',
  pathLogo: 'img/logo-solid.png',
  pathFavicon: 'img/favicon-solid.png',
  GAId: 'UA-119777650-1',
  appClass: 'app-solid',
  listFunctions: ['SolidNotify','PrintWatermark'],
}
const AppSolidBeta = {
  BaseUrl_dev : "https://apibeta.solidapp.vn",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://apibeta.solidapp.vn",
  BaseUrl_pro_test : "https://apitest.solidapp.vn",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://appbeta.solidapp.vn">SolidApp</a> &copy; 2018 Solid Interior. </span>,
  title: 'Solid Beta',
  pathLogo: 'img/logo-solid-beta.png',
  pathFavicon: 'img/favicon-solid-beta.png',
  GAId: 'UA-119777650-1',
  appClass: 'app-solid',
  listFunctions: ['SolidNotify','PrintWatermark'],
}
const AppSolidOperation = {
  BaseUrl_dev : "https://operationapi.solidapp.vn",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://operationapi.solidapp.vn",
  BaseUrl_pro_test : "https://operationapi.solidapp.vn",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://operation.solidapp.vn">Solid Operaion</a> &copy; 2018 Solid Interior. </span>,
  title: 'Solid Operation',
  pathLogo: 'img/logo-solid-operation.png',
  pathFavicon: 'img/favicon-solid-operation.png',
  GAId: 'UA-119777650-1',
  appClass: 'app-solidoperation',
  listFunctions: ['SolidNotify','PrintWatermark'],
}
const AppSolidSwanPark = {
  BaseUrl_dev : "https://swanparkapi.solidapp.vn",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://swanparkapi.solidapp.vn",
  BaseUrl_pro_test : "https://swanparkapi.solidapp.vn",
  home_url: '/welcome',
  showLoginWithUserPassword: false,
  footer: <span><a href="http://operation.solidapp.vn">Solid SwanPark</a> &copy; 2018 Solid Interior. </span>,
  title: 'Solid SwanPark',
  pathLogo: 'img/logo-solid-swanpark.png',
  pathFavicon: 'img/favicon-solid-swanpark.png',
  GAId: 'UA-119777650-1',
  appClass: 'app-solidswanpark',
  listFunctions: ['SolidNotify','PrintWatermark'],
}

const AppPOS_Dev = {
  BaseUrl_dev : "https://posapi.allianceitsc.com",
  BaseUrl_pro : "https://posapi.allianceitsc.com",
  BaseUrl_pro_test : "https://posapi.allianceitsc.com",
  home_url: '/welcome',
  showLoginWithUserPassword: true,
  footer: <span><a href="http://pos.allianceitsc.com">POS</a> &copy; 2018 POS. </span>,
  title: 'Solid App',
  pathLogo: 'img/logo-alliance.png',
  pathFavicon: 'img/favicon-alliance.png',
  GAId: 'UA-156318542-3',
  appClass: 'app-solidev',
}

const AppDe = {
  BaseUrl_dev : "https://desearchdevapi.allianceitsc.com",//"https://devapi.solidapp.vn";"http://soliddevapi.vmcode.net";"http://vfc02api.allianceitsc.com"
  BaseUrl_pro : "https://desearchdevapi.allianceitsc.com",
  BaseUrl_pro_test : "https://desearchdevapi.allianceitsc.com",
  home_url: '/search',
  showLoginWithUserPassword: false,
  footer: "",//<span><a href="http://app.solidapp.vn">Local</a> &copy; 2018 Local. </span>,
  title: 'DE Search',
  pathLogo: 'img/logo-alliance.png',
  pathFavicon: 'img/favicon-alliance.png',
  appClass: 'app-de',
}
/**************************** BUILD NEED REVIEW *********************************************************/ 

const AppProject = process.env.REACT_APP_CUSTOM_BUILD=="de"?AppDe:AppSolidDev; //Change APP here
const AppBuild = window._ConfigApp!=null?window._ConfigApp:AppProject;
const IsDevMode = (window._ConfigApp!=null&& window._ConfigApp.isDevMode===true)?true:false;         //Dev or Production
/**************************** ********************y*************************************/
const Config = {
  isDevMode: IsDevMode,
  baseUrl: IsDevMode==true?AppBuild.BaseUrl_dev:AppBuild.BaseUrl_pro,
  showLoginWithUserPassword: AppBuild.showLoginWithUserPassword!=null? AppBuild.showLoginWithUserPassword : true,
  home_url: AppBuild.home_url!=null? AppBuild.home_url : '/welcome',
  footer: AppBuild.footer!=null? AppBuild.footer : null,
  title: AppBuild.title!=null? AppBuild.title : '',
  pathLogo: AppBuild.pathLogo || 'img/logo.png',
  pathFavicon: AppBuild.pathFavicon,
  listFunctions: AppBuild.listFunctions!=null?AppBuild.listFunctions:[],
  GAId: AppBuild.GAId || null,
  tableCellShowClear: AppBuild.tableCellShowClear!=null?AppBuild.tableCellShowClear:false,
  appClass: AppBuild.appClass!=null?AppBuild.appClass:'',
  typeHeader: AppBuild.typeHeader!=null?AppBuild.typeHeader:null,
  appName: "alliancedev",
  // isCheckInvalidProjectId: true,
  // ignoreNoProjectId: false,  
  Functions:{
    SolidNotify: 'SolidNotify',
    PrintWatermark: 'PrintWatermark',// Show watermark when print
  },
  DefaultValues:{// default cac config    
    tableHover: AppBuild.tableHover!=null?AppBuild.tableHover:true,
    tableStriped: AppBuild.tableStriped!=null?AppBuild.tableStriped:true,
    detailHeaderOnlyButtonList: false,
    expandSideMenu: AppBuild.expandSideMenu!=null?AppBuild.expandSideMenu:false,
    // showWatermarkWhenPrint: AppBuild.showWatermarkWhenPrint!=null?AppBuild.showWatermarkWhenPrint:false,
  },
  hasFunction(name){
    console.warn("hasFunction:",name,Config.listFunctions);
    if(Config.listFunctions && Config.listFunctions.indexOf(name)>-1){
      return true;
    }
    return false;
  },
  initFromLocal({ConfigBuild}={}){
    if(ConfigBuild){
      if(ConfigBuild.ConfigApp){
        HCF.setCFMultiObj(ConfigBuild.ConfigApp)
        HUtils.Obj.addDataToObj(Config,ConfigBuild.ConfigApp);
      }
      if(ConfigBuild.Routers){
        Config.Routers = ConfigBuild.Routers
      }
      if(ConfigBuild.fnList){
        HUtils.Obj.addDataToObj(Config.fnList,ConfigBuild.fnList);
      }
      if(window._ConfigApp){
        HCF.setCFMultiObj(Config.configAppExt)
        HUtils.Obj.addDataToObj(Config,window._ConfigApp);
        if(!window._ConfigApp.hasOwnProperty('baseUrl')){
          console.error("Chú ý: file config ko có config baseUrl")
        }
      }
      else{//for v3 - overide AppBuild
        HUtils.Obj.addDataToObj(AppBuild,ConfigBuild.ConfigApp);
      }
    }
    
    initI18n()
    HDev.initDev(Config);
    HConstant.initConstant(Config);
    HGA.initialize(Config);
    HUI.initUI(Config);
    Api.initApi(Config);
    HAuth.initAuth({HConfig:Config});
    HAuth.checkLastLogin();
    HGlobal.initGlobal(Config);   
    HGlobal.History.getInstance();     
    HLink.getInstance();
    HFormat.initFormat(Config);
    
    
    let _configsString= localStorage.getItem(HConstant.LocalKey.CONFIGS);
    if(_configsString!=null && _configsString.length>0){
      this.parseFromServer(_configsString);
    }
    // if(Config.AppBuild && Config.AppBuild.appClass=="app-reduce"){
    //   require("../apps/reduce/styles.css");
    // }

    console.warn("initFromLocal",Config);
    if(Config.fnList.getConfig('hasPushNotifyChrome')){
      HPushNotifyChrome.init(Config,{publicKey:Config.fnList.getConfig('publicKeyPushNotify')});
    }

    HGlobalSetting.initFromLocal(Config);
    HGlobalSetting.requestGlobalSetting();

    //modules
    HModules.initModules(ConfigBuild.modules,{},Config)
        
    //run afterInit
    HUtils.runFnList(Config.fnList,"afterInit",this,[Config]);
  },
  parseFromServer(configsString){
    if(configsString!=null){
      try {
        let _config = JSON.parse(configsString);
        if(_config!=null){
          if(_config['home_url']!=null){
            this.home_url = _config['home_url'];
          }          
          // console.log('Update Config:',_config);
        }
      } catch (error) {
        console.warn('Parse JSON Config Error');
      }
    }
  },
  fileUploadSupport: "image/jpeg, image/png, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/acad, image/vnd.dwg, image/x-dwg, .dwg, .xls, .xlsx, .skp",
  dateFormat: AppBuild.dateFormat!=null?AppBuild.dateFormat:"DD-MMMYY",//momentJS : https://momentjs.com/docs/#/displaying/format/
  dateFormatJQ: AppBuild.dateFormatJQ!=null?AppBuild.dateFormatJQ:"dd-My",//DateFormat JQuery: https://github.com/phstc/jquery-dateFormat
  dateFormatRDP: AppBuild.dateFormatRDP!=null?AppBuild.dateFormatRDP:"dd-MMMYY",// ReactDatePicker: https://github.com/Hacker0x01/react-datepicker  
  defaultNewWindowCellLink: AppBuild.defaultNewWindowCellLink!=null?AppBuild.defaultNewWindowCellLink:true,// Link  trong cell link, co tu dong mo cua so moi hay khong  
  numberFormat_ReadonlyNumber: AppBuild.numberFormat_ReadonlyNumber!=null?AppBuild.numberFormat_ReadonlyNumber:'0,0.[0]',  
  InitApp: {},
  UIAppConfig:{
    numeralFormat: '0,0.[0]',
    numeralFormatMoney: '0,0.[00]',
    isCheckInvalidProjectId: true,
    ignoreNoProjectId: false,
    timeFormat: 'HH:mm',
    datetimeFormat: AppBuild.datetimeFormat!=null?AppBuild.datetimeFormat:"DD-MMMYY HH:mm",//MomentJS
    widthMobile: 1000,
    symbolDecimal: ',',
    symbolThousand: '.',
    // readonly_datetime_format_cell: "DD-MMMYY HH:mm",//momentJS//Chua can xai rieng
    expandBgColor: "#e4e5e6",//#93d4e3
  },
  mtf_datefromto_showRange: true,
  UIAppConfigFromServer:{},
  fnList:{
    getConfig(key){
      if(Config){
        if(Config.UIAppConfigFromServer && Config.UIAppConfigFromServer[key]){
          return Config.UIAppConfigFromServer[key];
        }
        else if( Config.UIAppConfig && Config.UIAppConfig[key]){
          return Config.UIAppConfig[key];
        }
        else if( AppBuild && AppBuild[key]!=null){
          return AppBuild[key];
        }
        else if(Config[key]){
          return Config[key];
        }
        else if(Config.DefaultValues[key]){//for old version
          return Config.DefaultValues[key];
        }
      }      
    },
    getIsDevMode(){
      return HDev.isDebug;
    },
    getIsMobileByWidth(){
      if(window.document.body.offsetWidth<Config.fnList.getConfig(HConstant.AppConfig.widthMobile)){
        return true;
      }
      return false;
    },
    checkValidConfigApp(){
      let _valid = false;
      if(Config.fnList.getIsDevMode()==true || window._ConfigApp!=null){
        _valid = true;
      }
      console.warn("checkValidConfigApp:",_valid);
      return _valid;
    },
    updateUIAppConfig(obj){
      if(Config.UIAppConfigFromServer && obj){
        Config.UIAppConfigFromServer = Object.assign(Config.UIAppConfigFromServer,obj);

        //sau khi update UIAppConfigFromServer
        HFormat.updateConfig();//Update lai cac format
      }
    },
    getCurrentUserName(){
      return HAuth.getName();
    },
    getCurrentUserAvatar(){
      return HAuth.getImgUrl();
    },
    getGlobalHistory(){
      return HGlobal.History.history;
    },
    getAuthIsLogged(){
      return HAuth.getIsLogged();
    },
    getAppLogo(){
      return HAuth.getAppLogo();
    },
    getGlobalSettingIsFirst(){
      return HGlobalSetting.getIsFirst()
    },
    setGlobalSettingIsNeedReloadAfterRequestFirstSetting(v){
      return HGlobalSetting.setIsNeedReloadAfterRequestFirstSetting(v)
    },
    logout(isForce){
      HAuth.logout(isForce);
    },
    apiUnAuth(isForce){
      HAuth.logout(isForce,'/login');      
    },
    trackingPageView: HGA.trackingPageView,
    trackingEvent: HGA.trackingEvent,
  }
}

document.title = Config.title;
if(Config.pathFavicon){
  var _docFavicon = document.getElementById('link-favicon');
  if(_docFavicon){
    _docFavicon.href = Config.pathFavicon;
  }
}

if(Config && Config.appClass){
  let _appClassLower = Config.appClass.toLocaleLowerCase();
  if(_appClassLower.indexOf('solid')>-1){
    import('../apps/solid/Init').then((v)=>{
      Config.InitApp = v.default;
      Config.InitApp.init();
      console.warn("Init App SL");
    })
  }  
}
window.HConfig = Config;
export default Config;