// @flow
import * as React from 'react';

export const ManagerReferenceNodeContext = React.createContext();
export const ManagerReferenceNodeSetterContext = React.createContext();

export function Manager({ children }) {
  const [referenceNode, setReferenceNode] = React.useState(null);

  React.useEffect(
    () => () => {
      setReferenceNode(null);
    },
    [setReferenceNode]
  );

  return (
    <ManagerReferenceNodeContext.Provider value={referenceNode}>
      <ManagerReferenceNodeSetterContext.Provider value={setReferenceNode}>
        {children}
      </ManagerReferenceNodeSetterContext.Provider>
    </ManagerReferenceNodeContext.Provider>
  );
}
