import moment from "moment";
import HText from './Text'
const HDate = {
  dateStringWithFormat(time,format){
    if(time==null || time==0 || time == ''){
      return "";
    }
    let _d = new Date(time);
    if(format!=null && format.length>0){
      return moment(time).format(format);
    }
    return _d.toISOString();
  },
  fromNow(time){
    if(time==null || time==0 || time == ''){
      return "";
    }
    return moment(time).fromNow(); 
  },
  getRanges(showRanges){
    let _ranges = {
      [HText.get('date_today')]: [moment().startOf("day"), moment().endOf("day")],
      [HText.get('date_yesterday')]: [moment().subtract(1, 'days').startOf("day"), moment().subtract(1, 'days').endOf("day")],
      // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      [HText.get('date_thisweek')]: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      [HText.get('date_thismonth')]: [moment().startOf('month'), moment().endOf('month')],
      [HText.get('date_thisyear')]: [moment().startOf('year'), moment().endOf('year')],
      [HText.get('date_lastmonth')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
    if(Array.isArray(showRanges)==true){
      let _obj = {};
      for(let i=0;i<showRanges.length;i++){
        if(_ranges.hasOwnProperty(showRanges[i])){
          _obj[showRanges[i]] = _ranges[showRanges[i]];
        }
      }
      // console.warn("Custom range:",_obj);
      return _obj;
    }
    if(showRanges===true){
      return _ranges;
    }    
  }
}

export default HDate;