//Dung chia se function source solid va source mobile
import React, { Component } from 'react';
import MyModal from '../components/MyModal/MyModal'
import MyModalPhoto from '../components/MyModal/MyModalPhoto'
import HText from '../helpers/Text';
import ModalPhotoViewer from '../components/ModalPhotoViewer/index'

const Hub = {
  showFilesToUpload({row,cell,fieldName,screenCode,canEdit,moreSourceSolid}){
    MyModalPhoto.Helper.show(row,fieldName,screenCode,{
      // fID: _more.fID,
      // fFN: _more.fFN,
      canUpload: canEdit===false?false:true,
      // projectId: _projectId,
      // cbSuccess: (data)=>{
      //   console.log('cbSuccess:',data,row,_fieldName);
      //   if(data && row[_fieldName]){
      //     for(let k of Object.keys(row[_fieldName])){
      //       row[_fieldName][k] = data[k];
      //     }
      //     this.forceUpdate();
      //   }
      // },
      // data: cell,
      // ..._configFile
      ...moreSourceSolid
    })
  },
  showFilesToUploadV2({opts,canEdit,fnList,propsMyUI,optsMyModal,customType}){
    MyModal.Helpers.showMyUI(customType||'files_manager_v2',{
      needRequestPhotos: (opts&&opts.needRequestPhotos!=null)?opts.needRequestPhotos:true,
      canUpload: canEdit,
      fnList: fnList,
      opts: opts,      
      ...propsMyUI
    },
    {
      title: HText.get('tab_allfiles'),
      hideHeader:true,
      showMiniBtnClose: false,
      ...optsMyModal
    });
  },
  showFilesGalleryV1(files,index){
    ModalPhotoViewer.Helper.show(files,index);
  },
}

export default Hub;