import React from 'react';
import fumigation_certificate from '../MyForm/MyForm_FormTypes/fumigation_certificate';
import meeting_minute from '../MyForm/MyForm_FormTypes/meeting_minute';
import solid_maintenance_addnew from './MyForm_FormTypes/solid_maintenance_addnew';


const MyFormTypes = {
  addType(key,data){
    if(MyFormTypes.Types[key]==null){
      MyFormTypes.Types[key] = data;
    }
  },
  Types:{
    fumigation_certificate: fumigation_certificate, 
    meeting_minute:meeting_minute,
    solid_maintenance_addnew: solid_maintenance_addnew,
  }
}

export default MyFormTypes;