import HUtils from './Utils'

const Compare = {
  getValueFieldOfObjValue(value,objValue={}){
    if(objValue){
      if(typeof value=="string" && value.indexOf("[")>-1){
        return HUtils.SquareBracket.getValueOfObj(value,objValue);
      }
      else{
        return value;
      }
    }else{
      return value;
    }
  },
  getObjOfCondition(obj,conditionJson={},objValue){
    if(conditionJson.obj!=null){
      return Compare.getValueFieldOfObjValue(conditionJson.obj,objValue);
    }
    return Compare.getValueFieldOfObjValue(obj,objValue);
  },
  getValueOfCondition(obj,conditionJson={},objValue){
    return Compare.getValueFieldOfObjValue(conditionJson.value,objValue);
  },
  groupMatch(obj,conditionJson={},objValue,opts){
    let _value = Compare.getValueOfCondition(conditionJson,objValue);
    if(Array.isArray(_value)){
      let _resAll = null;
      for(let i=0;i<_value.length;i++){
        let _item = _value[i];
        let _result = Compare.isMatch(obj,_item,objValue,opts);
        if(i==0){
          _resAll =_result;
        }
        else{
          if(conditionJson.opr=='||'){
            _resAll = ( _resAll || _result );
            if(_resAll === true){
              return true;
            }
          }
          else if(conditionJson.opr=='&&'){
            _resAll = ( _resAll && _result );
            if(_resAll === false){
              return false;
            }
          }
        }
      }
      return _resAll;
    }
    else{
      console.warn("Compare group need array!!!");
    }
    return false;
  },
  isMatch(obj,conditionJson={},objValue,opts){
    if(conditionJson.opr){
      let _obj = Compare.getObjOfCondition(obj,conditionJson,objValue);
      let _value = Compare.getValueOfCondition(obj,conditionJson,objValue);
      // console.warn("isMatch:",_obj,_value,conditionJson.opr);
      switch(conditionJson.opr) {
        case '==': return _obj == _value;
        case '!=': return _obj != _value;
        case '>': return _obj > _value;
        case '>=': return _obj >= _value;
        case '<': return _obj < _value;
        case '<=': return _obj <= _value;
        case '||': return Compare.groupMatch(obj,conditionJson,objValue,opts);
        case '&&': return Compare.groupMatch(obj,conditionJson,objValue,opts);
        default:
          // code block
      }
    }
    return false;
  }
} 

export default Compare;