import React from 'react';
/**
 * MyModalTypes - Kiểu định dạng cho một button
 * **Tiến độ thực hiện**
 * - [ ] default 
 * 
 * @class MyModalTypes
 * @static
 * @namespace MyModalTypes
 * 
 */

function ComponentLoading(props) {
  return (
    <div></div>
  )
}

const Default = React.lazy(() => import('./Types/_default'));
const ListCheck = React.lazy(() => import('./Types/list_check'));
const ListCheckApi = React.lazy(() => import('./Types/list_check_api'));
const JsonEditor = React.lazy(() => import('./Types/json_editor'));
const CellExpandTable = React.lazy(() => import('./Types/cell_expand_table'));
const CellExpandForm = React.lazy(() => import('./Types/cell_expand_form'));
const PrintBill = React.lazy(() => import('./Types/print_bill'));
const PrintBill2 = React.lazy(() => import('./Types/print_bill').then(module=>({default:module.MyModalType_PrintBill2})));
const MyForm = React.lazy(() => import('./Types/_default').then(module=>({default:module.MyModalType_MyForm})));
const HTMLEditor = React.lazy(() => import('./Types/html_editor'));
const WithMyUI = React.lazy(() => import('./Types/with_myui'));
const PrintWithMyUI = React.lazy(() => import('./Types/print_with_myui'));

const MyModalTypes = {
  addTypes(key,fnUI){
    if(MyModalTypes.UI[key]==null){
      MyModalTypes.UI[key] = fnUI;
    }
    else{
      console.warn('addTypes exist:',key)
    }
  },
  UI:{
    _default:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <Default {...props}/>
        </React.Suspense>
      )
    },    
    list_check:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <ListCheck {...props}/> </React.Suspense> ) },
    list_check_api:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <ListCheckApi {...props}/> </React.Suspense> ) },
    json_editor:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <JsonEditor {...props}/>
        </React.Suspense>
      )
    },
    cell_expand_table:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <CellExpandTable {...props}/>
        </React.Suspense>
      )
    },
    cell_expand_form:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <CellExpandForm {...props}/>
        </React.Suspense>
      )
    },
    print_bill:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <PrintBill {...props}/>
        </React.Suspense>
      )
    },
    print_bill2:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <PrintBill2 {...props}/>
        </React.Suspense>
      )
    },
    myform:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <MyForm {...props}/>
        </React.Suspense>
      )
    },
    html_editor:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <HTMLEditor {...props}/>
        </React.Suspense>
      )
    },
    with_myui:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <WithMyUI {...props}/>
        </React.Suspense>
      )
    },
    print_with_myui:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <PrintWithMyUI {...props}/>
        </React.Suspense>
      )
    },
  }
}
window.MyModalTypes=MyModalTypes.UI;
export default MyModalTypes;