import classnames from 'classnames';
import React from 'react';
import {
  Input
} from '../../../_importComponent';
import { CellHelper } from '../Helpers';
/**
 * Cell Type: text.
 *
 * @class text
 * @static
 * @namespace text
 * @memberof CellType
 * 
 * @example
 * Url: /config-color
 * bgF: ColorCode // Dùng làm màu nền của ô
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    const {cell,row,extra} = this.props;
    this.state={
        value:'',
    };
    if (cell){
      this.state.value = cell
    }
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.text
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component
   * @param {String} [placeHolder] placeHolder của Input
   * 
  */
  more() {
  }

  componentWillReceiveProps(nextProps){
    let _needUpdate = CellHelper.isNeedUpdateValueAfterWillReceiveProps(this.props,nextProps,"");
    if(_needUpdate.needUpdate==true){
      this.setState({
        value: _needUpdate.value
      })
    }
  }

  render() {
    const {cell,row,extra} = this.props;
    // console.warn('celltype text',this.props);
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap celltype-text",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _fnList = CellHelper.getFnList({extra,row});
    let _styleInput = Object.assign({height:'100%'},_style);
    let _placeHolder = _more.placeHolder;
    let _disabled = !CellHelper.getCanEdit({extra,row});
    // console.warn('_disabled:',_disabled,extra,row);
    return (
      <div className={CellHelper.getClassNameContainer({...this.props,more:_more})} style={CellHelper.getStyleContainer({...this.props,more:_more})}>
        {
          CellHelper.renderExpandArea({extra,row,more:_more,component:this})
        }
        <Input
          className={_className}
          style={_styleInput}
          type="text"
          value={this.state.value} 
          placeholder = {_placeHolder}
          disabled={_disabled}
          onBlur={(ev)=>{
            // this._onChange(ev, this.state.value);
            CellHelper.callRequestUpdate({extra:extra,row:row,fieldName:extra.fieldName,newValue:this.state.value});
          }}
          onChange={ (ev) => { 
            // console.warn('onChange',ev,ev.currentTarget.value,this.state.value);
            this.setState({ 
              value: ev.currentTarget.value 
            });
          }}>
        </Input>
      </div>
    )
  }
}

export default Z;