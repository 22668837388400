import HText from '../helpers/Text';
import HColor from '../helpers/Color';
import HConfig from '../helpers/Config';
import HConstant from '../helpers/Constant';
import HOptions from '../helpers/Options';
import HUI from '../helpers/UI';
import HLink from '../helpers/Link';
import HUtils from '../helpers/Utils';
import {Error as HError} from '../helpers/Error';
import HFormat from '../helpers/Format';
import HTable from '../helpers/Table';
import HLocalStorage from '../helpers/LocalStorage';
import HDate from '../helpers/Date';
import HFilter from '../helpers/Filter';
import HCompare from '../helpers/Compare';

const _test = {
    HText,
    HColor,
    HConfig,
    HConstant,
    HOptions,
    HUI,
    HLink,
    HError,
    HUtils,
    HFormat,
    HTable,
    HLocalStorage,
    HFilter,
    HDate,
    HCompare,
}
let _keys = Object.keys(_test);
for(let k of _keys){
    if(_test[k]==null){
        console.warn("import app/Helpers null:",k);
    }
}

export {
    HText,
    HColor,
    HConfig,
    HConstant,
    HOptions,
    HUI,
    HLink,
    HError,
    HUtils,
    HFormat,
    HTable,
    HLocalStorage,
    HFilter,
    HDate,
    HCompare,
}