import React from "react";
import HUtils from '../../../helpers/Utils';
import MyLayout from '../../../components2/MyLayout/MyLayout';
import MyCard from "../../../components2/MyCard/MyCard";

class CellType_ComponentCustomIUCell extends React.Component {
  _getMyLayoutProps = (cell, config) => {
    const myLayout = HUtils.Obj.get(config, "myLayout");
    let _pr = {
      type: myLayout.type,
      data: cell,
      config: {
        ...myLayout.config,
      },
    };
    return _pr;
  };
  _getMyCardProps = (config) => {
    const myCard = HUtils.Obj.get(config, "myCard");
    let _pr = {
      type: myCard.type,
      config: {
        ...myCard.config,
      },
    };
    return _pr;
  };
  render() {
    const { cell, row, extra, config, fnList } = this.props;
    return <div className="celltype_customui">
        <MyLayout {...this._getMyLayoutProps(cell, config)} renderItems={(item,index)=>{
              return (
                <MyCard key={index} {...this._getMyCardProps(config)} dataSource={item} fnList={fnList}/>
              )
            }}/>
    </div>;
  }
}
export default CellType_ComponentCustomIUCell;
