const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

/**
 * Init:
 * TTFB: 3.18, FCP: 112, LCP: 128.31, CLS: 0
 * 
 * Update font:
 * FCP: 632, TTFB: 320, CLS: 0.000034, LCP: 700
 */

 /**
  * 
  * Update welcome: 3.77MB
  * table, sidebar: 6.25MB
  * 24/02/2021: JS: 36.1, big chunk js:10.8 va 4.1
  */
export default reportWebVitals;
