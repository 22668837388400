import {FHelper} from '../libs/Firebase';

const Error = {
  createDivError(id,content){
    if(id){
      var div = document.createElement('div');
      div.id = id;
      div.innerHTML = content;
      div.style.display = 'none';
      document.body.appendChild(div);
    }    
  },
  errorApi(error,objLog,{path}={}){
    let _msg = "";
    let _path = path || "error_api";
    if(objLog){
      if(objLog.error_message){
        _msg = objLog.error_message;
      }
      if(objLog.response && objLog.response.Msg){
        _msg = objLog.response.Msg;
      }
    }
    Error.createDivError('error_api',_msg);
    if(objLog){
      FHelper.log(_path,objLog);
    }
  },
  errorUI(error,objLog){
    Error.createDivError('error_ui','Error UI!!!');
    if(objLog){
      FHelper.log('error_ui',objLog);
    }
  },
  removeDivErrorApi(){
    let _div = document.getElementById('error_api');
    if(_div){
      _div.parentNode.removeChild(_div);
      let _div2 = document.getElementById('error_api');
      if(_div2){
        Error.removeDivErrorApi();
      }
    }        
  },
  removeDivErrorUI(){
    let _div = document.getElementById('error_ui');
    if(_div){
      _div.parentNode.removeChild(_div);
      let _div2 = document.getElementById('error_ui');
      if(_div2){
        Error.removeDivErrorApi();
      }
    }        
  }
}

export {
  Error
}