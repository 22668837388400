import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import HStore from '../helpers/StoreRedux'
import HConfig from '../v1/helpers/Config';
import {ConfigApp} from './ConfigBuild';

import MyErrorScreen from '../v1/components/MyLoading/MyErrorScreen';
import MyLoadingScreen from '../v1/components/MyLoading/MyLoadingScreen';
import * as MLib from "@macashipo/mlib"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
window["MLib"] = MLib
console.warn("run index.js",ConfigApp)
HConfig.initFromLocal({
  ConfigBuild: ConfigApp
})

let _isFirst = HConfig.fnList.getGlobalSettingIsFirst();
if(!HConfig.fnList.checkValidConfigApp()){
  ReactDOM.render((
    <div className={`root_app ${HConfig.appClass}`}>
      <MyErrorScreen msg={"Missing Config App!!!"}/>
    </div>
  ), document.getElementById('root'));
}
else if(_isFirst){
  HConfig.fnList.setGlobalSettingIsNeedReloadAfterRequestFirstSetting(true);
  ReactDOM.render((
    <div className={`root_app ${HConfig.appClass}`}>
      <MyLoadingScreen />
    </div>
  ), document.getElementById('root'));
}
ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={HStore.myStore}>
      <App />
      {/* <div>Hello Worlddddddd</div> */}
    </Provider>    
    </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
reportWebVitals(console.log);