import React from 'react';
import {Input} from '../../../_importComponent';
import classnames from 'classnames';
import PropTypes from 'prop-types'
import {CellHelper} from '../Helpers';
/**
 * Cell Type: textarea.
 *
 * @class textarea
 * @static
 * @namespace textarea
 * @memberof CellType
 * 
 * @example 
 * Url: /config-staff
 * Col: FirstName    
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    const {cell,row,extra} = this.props;
    this.state={
        value: ''
    };
    if (cell){
        this.state.value = cell;
    }
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.readonly
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component
   * @param {String} [placeHolder] placeHolder của Input
   *
  */
  more() {
  }

  componentWillReceiveProps(nextProps){
    let _needUpdate = CellHelper.isNeedUpdateValueAfterWillReceiveProps(this.props,nextProps,"");
    if(_needUpdate.needUpdate==true){
      this.setState({
        value: _needUpdate.value
      })
    }
  }

  render() {
    const {cell,row,extra,} = this.props;
    // console.warn('extra', extra);
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap celltype-textarea",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _fnList = CellHelper.getFnList({extra,row});
    let _styleInput = Object.assign({height:'100%',display:'flex', alignItems:'center'},_style);
    let _placeHolder = _more.placeHolder;
    let _disabled = !CellHelper.getCanEdit({extra,row});
    let _rowInput = _more.rows || 2;
    return (
      <div>
        <Input 
          className={_className}
          style={_styleInput}
          type='textarea'
          rows={_rowInput}
          value={this.state.value} 
          placeholder = { _placeHolder }
          disabled={_disabled}
          onBlur={ (ev) => {
            // this._onChange(ev, this.state.value)
            CellHelper.callRequestUpdate({extra:extra,row:row,fieldName:extra.fieldName,newValue:this.state.value,component:this});
          }
          }
          onChange={ (ev) => { 
            this.setState({ 
              value: ev.currentTarget.value 
            });
          }}>
        </Input>
        {
          CellHelper.renderStatus({cell,extra,row,component:this,more:_more})
        }
      </div>
      )
  }
}

export default Z;