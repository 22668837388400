import React from 'react'
import CSuppense from '../MyCore/CSuspense'
import HUtils from '../../helpers/Utils'
const MCCard_onlytitle = React.lazy(() => import('./Types/card_onlytitle'));
const MCCard_withlayout = React.lazy(() => import('./Types/card_withlayout'));
const MCCard_1 = React.lazy(() => import('./Types/card_1'));
const MCCard_020 = React.lazy(() => import('./Types/card_020'));
const MCCard_021 = React.lazy(() => import('./Types/card_021'));
const MCCard_022 = React.lazy(() => import('./Types/card_022'));
const MCCard_023 = React.lazy(() => import('./Types/card_023'));
const MCCard_024 = React.lazy(() => import('./Types/card_024'));
const MCCard_025 = React.lazy(() => import('./Types/card_025'));
const MCCard_030 = React.lazy(() => import('./Types/card_030'));


const MCCard_square = React.lazy(() => import('./Types/card_square'));

const MCCard_standard_1 = React.lazy(() => import('./Types/card_standard_1'));
const MCCard_standard_2 = React.lazy(() => import('./Types/card_standard_2'));
const MCCard_standard_3 = React.lazy(() => import('./Types/card_standard_3'));
const MCCard_standard_4 = React.lazy(() => import('./Types/card_standard_4'));

const MyCardTypes = {
  addType(key,fnUI){
    if(MyCardTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyCardTypes.Types[key]=fnUI;
    }
  },
  Types:{   
    card_onlytitle: (props)=>{ return CSuppense(props,MCCard_onlytitle) },
    card_withlayout: (props)=>{ return CSuppense(props,MCCard_withlayout) },
    card_1: (props)=>{ return CSuppense(props,MCCard_1) },
    card_020: (props)=>{ return CSuppense(props,MCCard_020) },
    card_021: (props)=>{ return CSuppense(props,MCCard_021) },card_skeleton: (props)=>{ return CSuppense(props,MCCard_021) },//alt card_021    
    card_022: (props)=>{ return CSuppense(props,MCCard_022) },//card standard, dang stack img
    card_023: (props)=>{ return CSuppense(props,MCCard_023) },//card standard, co img, userAvatar, userName
    card_024: (props)=>{ return CSuppense(props,MCCard_024) },//card simple ext card_030, kieu tag co bo tron
    card_025: (props)=>{ return CSuppense(props,MCCard_025) },//card co img, title, des
    card_030: (props)=>{ return CSuppense(props,MCCard_030) },//card simple, title center card
    
    card_square: (props)=>{ return CSuppense(props,MCCard_square) },
    card_standard_1: (props)=>{ return CSuppense(props,MCCard_standard_1) },//using card_023
    card_standard_2: (props)=>{ return CSuppense(props,MCCard_standard_2) },//using card_022
    card_standard_3: (props)=>{ return CSuppense(props,MCCard_standard_3) },//using card_025
    card_standard_4: (props)=>{ return CSuppense(props,MCCard_standard_4) },//using card_024
  }
}
if(window._M){
  window._M.MyCardTypes = MyCardTypes;
}
export default MyCardTypes;