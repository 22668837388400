const StyleCell = {
  // width:'250px',
  flex:1,
  display:'flex',
  flexDirection: 'column',
  marginRight: '10px',
  marginLeft: '10px',
  marginTop: '10px',
  minWidth: '200px',
}
const StyleLabel = {
  fontWeight:'inherit',
  textAlign:'center',
  width:'100%',
  marginLeft:'5px',
}
const StyleControl = {
  width: '100%',
  // fontWeight:'bold',
  height: '50px',
}
const Form={       
  Title: 'ABC',
  Styles:{
    Form:{
      padding: '0px',
      c_padding: '0px',
      border:'0px solid',
    },
    Label:{ 
    },
    Cell:{
      marginBottom: '0px', 
    }
  },
  Data: [
    {
      Type: 'select2',
      // Label: 'CodeL1',
      FieldName: 'CodeL1',  
      Source: 'WPGGroupList',
      Props:{
        RelateComponents: ['ConfigMaintenanceItemId','SpaceId'],
        placeholder: 'CodeL1',
        more:{
          clearable: true
        }  
      },
      Styles:{
        Label:StyleLabel,
        Control:StyleControl,
        Cell:StyleCell
      },                    
    },
    {
      Type: 'select2',
      // Label: 'Cái Gì?',
      FieldName: 'ConfigMaintenanceItemId',  
      Source: 'WPGGroupList.[CodeL1].MaintenanceItemList',//Source: 'MaintenanceItemList',
      Props:{
        RelateComponents: ['SpaceId'],
        placeholder: 'Hạng mục bị vấn đề',
        sourceWhenNoParent: "MaintenanceItemList",              
      },
      Styles:{
        Label:StyleLabel,
        Control:StyleControl,
        Cell:StyleCell
      },                    
    },
    {
      Type: 'textarea',
      // Label: 'Bị Vấn Đề Gì?',
      FieldName: 'Problem',
      Props:{
        placeholder: 'Mô tả vấn đề',
      },
      Styles:{
        Label:StyleLabel,
        Control:StyleControl,
        Cell:StyleCell
      },                    
    },
    {
      Type: 'select2',
      // Label: 'Ở Đâu?',
      FieldName: 'SpaceId',
      Source: 'MaintenanceItemList.[ConfigMaintenanceItemId].SpaceList',
      Props:{
        placeholder: 'Khu vực',
        autoPickOne: true,
        // sourceWhenNoParent: "MaintenanceScheduleList"
      },
      Styles:{
        Label:StyleLabel,
        Control:StyleControl,
        Cell:StyleCell
      },                    
    },
    {
      Type: 'files',
      // Label: 'Hình ảnh',
      FieldName: 'ReportFilesBefore',
      Props:{
        styled: 2,
        autoShowSelectFileWhenEmpty: true
      },
      Styles:{
        Label: {
          ...StyleLabel,
          textAlign: "left",
        },
        Control:{
          ...StyleControl,
          maxWidth: '800px',
        },
        Cell: {
          ...StyleCell,
          // flexBasis: '100%',
          // maxWidth: '200px',
        }
      },                    
    }, 
    {
      Type: 'submit',
      // Label: 'Hình ảnh',
      FieldName: '_Submit',
      Styles:{
        Label: {
          ...StyleLabel,
          textAlign: "left",
        },
        Control:{
          ...StyleControl,
          maxWidth: '800px',
        },
        Cell: {
          ...StyleCell,
          // flexBasis: '100%',
          // maxWidth: '200px',
        }
      },                    
    }, 
  ]
};

export default Form