import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types';
import HUtils from '../../../helpers/Utils'
import HConstant from '../../../helpers/Constant'
import MyUI_InputExt from './t_input_ext';
class MyUI_InputText extends MyUI_InputExt {
  constructor(props){
    super(props);
  }
  _onChange=(ev)=>{
    const {fnList,f} = this.props;
    let _v = ev.currentTarget.value;
    this.setState({
      value: _v
    },()=>{
      HUtils.runFnList(fnList,HConstant.UIFnCore.myChange,this,[f,_v,{...this.props}]);   
    })     
  } 
  _onBlur=(ev)=>{
    const {fnList,f} = this.props;
    let _v = ev.currentTarget.value;
    this.setState({
      value: _v
    },()=>{
      HUtils.runFnList(fnList,HConstant.UIFnCore.myBlur,this,[f,_v,{...this.props}]);   
    })     
  }    
  parseMore=()=>{
    let _more = {};
    return _more;
  }  
  render(){
    let {
      className,
      //
      innerRef,
      valid,
      invalid,
      custom,
      disabled,
      plaintext,
      size,
      m,
      ...attributes
    } = this.props;
  
    // render
    const classes = classnames(
      plaintext ? 'form-control-plaintext' : 'form-control',
      size && `form-control-${size}`,
      invalid && 'is-invalid',
      valid && 'is-valid',
      className
    )  
    
    return <input className={classes} type="text" disabled={disabled} value={this.getValue()} onChange={this._onChange} onBlur={this._onBlur} {...m} {...this.parseMore()} ref={innerRef}/>
  } 
}
MyUI_InputText.propTypes = {
  m: PropTypes.any,//more props 
  f: PropTypes.string,//field Name
  dfe: PropTypes.string,//default empty
  df: PropTypes.string,//default value
  v: PropTypes.string,// value
};
MyUI_InputText.defaultProps = {
  dfe: ""
}
export default MyUI_InputText