import Text_Vi from './Language/vi'
const kLanguage = '_Language_';
const Text = {
  language: "vi",
  ListLanguage:{
    default: Text_Vi,
    vi: Text_Vi,
    en: null
  },
  optionsLanguage:[
    {Value:'vi',Text:'Tiếng Việt',Flag:'cifVn'},
    {Value:'en',Text:'English',Flag:'cifGb'},
  ],
  onChangeLanguage(value){
    Text.language = value;
    localStorage.setItem(kLanguage,value);
  },
  initText(HConfig){
    let _defaultLang = HConfig.fnList.getConfig('language');
    let _localLang = localStorage.getItem(kLanguage);
    if(_localLang){
      _defaultLang = _localLang;
    }
    if(_defaultLang){
      Text.language = _defaultLang;
    }
    Text.Data = {...Text.ListLanguage[Text.language]};
  },
  Data: Text_Vi,
  Key:{
    btn_login: "btn_login",
    btn_login_gg: "btn_login_gg",
    btn_logout: "btn_logout",
    text_or: "text_or",
  },
  get(key){
    if(key && Text.Data[key]){
      return Text.Data[key];
    }
    else{
      console.warn('Text key not exist:',key);
    }
    return '';
  },
  getMulti(arrayKey){
    let _s = '';
    if(arrayKey){
      for(let i=0;i<arrayKey.length;i++){
        let _k = arrayKey[i];
        let _ss = Text.get(_k);
        if(_ss){
          if(i>0){
            _s += ' ';
          }
          _s += _ss;
        }        
      }
    }
    return _s;
  },
  getTextDisplaySettings(){
    let _list = [];
    //convert Data to list
    let _keys = Object.keys(Text.Data);
    for (let i in _keys){
      _list.push({
        Key:_keys[i],
        Value: Text.get(_keys[i])
      });
    }
    return _list;
  },
  add(key,value){
    if(key && value){
      if(Text.Data[key]==null){
        Text.Data[key] = value;
      }
    }    
  },
  update(key,value){
    if (key && value){
      Text.Data[key]=value;
    }
  },
  updateFromList(list){
    //Goi update tung object
    for (let i in list){
      let _item = list[i];
      Text.update(_item.Key, _item.Value);
    }
  },
  /**
   * 
   * @param {Object} opts Gồm list, fieldName, char
   * @param {Array} list mảng truyền vào
   * @param {String} fieldName tên field của Object trong mảng
   * @param {String} char Kí tự nối chuỗi
   */
  groupString: function({list, fieldName, char}){
    var _groupString = "";
    if(list && list.length>0){
      for(let i =0;i<list.length;i++){
        _groupString = _groupString + list[i][fieldName];
        if (i<list.length-1)
        {
          _groupString = _groupString + char;
        }
      }
    }
    return _groupString;
  },
  removeSpace: function(s){
    if(s!=null){
      s= s + "";//phai chac chan la chuoi
      return s.replace(/\s/g,'');
    }
    return '';
  },
  removeSpecialChars: function(s)
  {      
    if(s!=null){
      s= s + "";//phai chac chan la chuoi
      s = s.replace(/[;-=!@^&\/\\#,+()$~%.'":*?<>{}]/g, '');        
      return s;
    }
    return '';      
  },
  xoaDauInText: function(s,{removeSpace,removeSpecialChars,convertLower}={}){
    let str = s + "";//phai chac chan la chuoi
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    if(removeSpace!==false){
      str = str.replace(/\s/g,'');
    }    
    if(removeSpecialChars!==false){
      str = str.replace(/[^A-Za-z0-9\s!?.]/g,'');
    }    
    if(convertLower!==false){
      str = str.toLowerCase();
    }    
    return str;
  },
  buildTextForSearch: function(s){
    let _s = Text.removeSpace(s); 
    _s = Text.removeSpecialChars(_s);
    _s = Text.xoaDauInText(_s); 
    return _s;
  },
  buildTextForSearchFromObj: function(obj){
    let _keys = Object.keys(obj);
    let _s = "";
    if(_keys && _keys.length>0){
      for(let _k of _keys){
        let _v = obj[_k];
        if (_v && typeof _v === 'string'){
          _s += Text.buildTextForSearch(_v);
        }
      }
    }
    return _s;
  },
  compareTextForSearch: function(text, textSearch, opts = {}){
    if(text && textSearch){
      if(opts.searchKoDau){
        let _text = Text.buildTextForSearch(text);
        let _textSearch = Text.buildTextForSearch(textSearch);
        if(new RegExp(_textSearch,"gi").test(_text)===true){
          return true;
        }
      }
      if(new RegExp(textSearch,"gi").test(text)===true){
        return true;
      }
    }    
    return false;
  },
  addFieldForSearch(arrayRow){
    if(arrayRow && arrayRow.length>0){
      for(let i in arrayRow){
        let _item = arrayRow[i];
        let _s = Text.buildTextForSearchFromObj(_item);
        _item["_SearchOpt"] = _s;
        // console.warn('addFieldForSearch:',_item);
      }
    }
  },
}

if(window){
  window.HText = Text;
}
export default Text;