import classnames from 'classnames';
import React from 'react';
import { Button } from '../../../_importComponent';
import { CellHelper } from '../Helpers';

/**
 * Cell Type: expand_api.
 * Để sử dụng cần Config Options có: {"Table":{"hasExpand":true},"Detail":{"multi":true}}
 *
 * @class expand_api
 * @static
 * @namespace expand_api
 * @memberof CellType
 * 
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };

    this._configByKey = {};
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.expand_api
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component
   * @param {String} [controller] controller của detail
   * @param {Boolean} [showAll] 1 button show tất cả detail
   * @param {Array} [list] mảng các detail
   * @param {String} [titleBtn] title của nút expand
   * //không có trong more - Có trong TableDetailApi
   * @param {String} [title] title của nút detail
   * @param {String} [screenCode] screenCode của detail (mặc định: "ScreenCodeDetail")
   * @param {String} [bgColor] background của detail dạng hex vd: #ff00ff
   * @param {String} [bgF] tên field của row master, lấy giá trị đó ở row master để làm background của detail
   * @param {String} [key] dùng để phân biệt nếu có nhiều detail, mặc định sẽ dùng screenCode
   * @param {Object} [queryFromParent] query có thể bổ sung dành cho các request ở detail
   * @param {Boolean} [isRow] =true thì show theo dạng row, ngược lại show dạng column
   * @param {Boolean} [hideArrow] =true thì ẩn mũi tên bên trái table
   * //
   * 
  */
  more() {
  }

  _getCanShow=(keyScreen)=>{
    const {cell,row,extra} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _canShow = true;//Mac dinh la show
    let _canShowFromHelpers = CellHelper.getCanShow({extra,row});
    if(_canShowFromHelpers==false){
      _canShow = false;      
    }
    if(_canShow===true){
      if(row.UI_ConfigExpand && row.UI_ConfigExpand.Hide){//array string
        if(row.UI_ConfigExpand.Hide.indexOf(keyScreen)>-1){
          _canShow = false;
        }
      }
    }
    if(_canShow===true){
      if(row.UI_ConfigExpand && row.UI_ConfigExpand.Disable){//array string
        if(row.UI_ConfigExpand.Disable.indexOf(keyScreen)>-1){
          _canShow = false;
        }
      }
    }
    return _canShow;
  }

  _getDefaultShowExpand=(item)=>{
    const {cell,row,extra} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _defaultShowExpand = false;
    if(row.UI_DefaultShowExpand==true){
      _defaultShowExpand = true;
    }
    if(item && item.defaultShowExpand!=null){
      _defaultShowExpand = item.defaultShowExpand;
    }
    if(row.UI_ConfigExpand && row.UI_ConfigExpand.DefautShowExpand){
      if(row.UI_ConfigExpand.DefautShowExpand.indexOf(item.key || item.screenCode || 'nokey')>-1){
        _defaultShowExpand = true;
      }
    }
    return _defaultShowExpand;
  }

  _assignToRow=(more)=>{
    const {cell,row,extra} = this.props;    
    if(row['_isExpandMulti'] == null){
      row['_isExpandMulti'] = {};
    }
    if(row['_configMulti'] == null){
      row['_configMulti'] = {};
    }
    if(more){
      if(more.controller){
        let _key = more.key || more.screenCode || 'nokey';
        if(row['_configMulti'][_key]==null){
          row['_configMulti'][_key] = more;  
          row['_configMulti'][_key]._init = true;        
          row['_isExpandMulti'][_key] = this._getDefaultShowExpand(more);
        }        
        this._configByKey[_key] = more;
      }
      else if(more.list && more.list.length>0){
        for(let i=0;i<more.list.length;i++){
          let _item = more.list[i];
          let _key = _item.key || _item.screenCode || 'nokey';
          if(row['_configMulti'][_key]==null){
            row['_configMulti'][_key] = _item;
            row['_isExpandMulti'][_key] = this._getDefaultShowExpand(_item);
          }    
          this._configByKey[_key] = _item;      
        }
      }
    }     
  }
  
  _renderList=(more,fnList)=>{
    const {cell,row,extra} = this.props;
    let _ui = [];
    let _fnShowExpand = fnList.fnShowExpand;
    let _fnIsShowExpand = fnList.fnIsShowExpand;  
    // console.warn('_renderList more:',more);    
    if(more && more.controller){
      let _isShow = _fnIsShowExpand!=null?_fnIsShowExpand({row,item:more}):false;   
      // console.warn('_renderList _isShow:',_isShow);  
      if(this._getCanShow(more.key || more.screenCode || 'nokey')){
        _ui.push(
          <Button c={more.controller} key={"-1"} {...CellHelper.More.getMorePropsButton({extra,row,more,df:{
            color: 'link',
            outline: true,
            style: {
              padding:'0px',border:'0px',width:'30px',fontSize:'20px', position: "relative"
            }
          }})} onClick={()=>{
            if(_fnShowExpand){
              _fnShowExpand({row,cc: this,item:more});
            }
          }}>
            <i className={_isShow?"fa fa-chevron-circle-up":"fa fa-chevron-circle-down"}/>
            {
              CellHelper.renderBadge({cell,extra,row,more,count:0,component:this})
            }
          </Button>
        )
      }      
    }
    if (more && more.showAll && more.showAll==true){
      if(more.list && more.list.length>0){
        let _isShow = _fnIsShowExpand!=null?_fnIsShowExpand({row,item:more}):false;
        // console.warn('_isShow', _isShow);
        if(this._getCanShow(more.key || more.screenCode || 'nokey')){
          _ui.push(
            <Button key={'sob'} color="link" outline style={{padding:'0px',border:'0px',width:'30px',fontSize:'20px'}} onClick={()=>{
              if(_fnShowExpand){
                _fnShowExpand({row,cc: this,item:more});
              }
            }}><i className={_isShow?"fa fa-chevron-circle-up":"fa fa-chevron-circle-down"}/></Button>
          )
        }
      }
    }else{
      if(more && more.list && more.list.length>0){
        for(let i in more.list){
          let _item = more.list[i];
          let _isShow = _fnIsShowExpand!=null?_fnIsShowExpand({row,item:_item}):false;
          // console.warn('_renderList _isShow:',_isShow);
          let _expandKey = _item.key || _item.screenCode || 'nokey';
          if(this._getCanShow(_expandKey) && _item.hideButton!==true){
            _ui.push(
              <div key={i} style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',width:"50px",wordBreak:'break-word'}}>
                <div>{_item.titleBtn || ""}</div>
                <Button data-expand-key={_expandKey} color="link" outline style={{padding:'0px',border:'0px',width:'30px',fontSize:'20px'}} onClick={()=>{                  
                  if(_fnShowExpand){
                    if(_item.multi){
                      for(let k of _item.multi){
                        if(this._configByKey[k]){
                          _fnShowExpand({row,cc: this,item:this._configByKey[k]});
                        }
                      }
                    }
                    else{
                      _fnShowExpand({row,cc: this,item:_item});
                    }                    
                  }
                }}><i className={_isShow?"fa fa-chevron-circle-up":"fa fa-chevron-circle-down"}/></Button>
              </div>
            )
          }
        }
      }
    }
    return _ui;
  }

  render() {
    const {cell,row,extra} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("dFfdRaiCjcC",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _fnList = CellHelper.getFnList({extra,row});
    // let _fnShowExpand = _fnList.fnShowExpand;
    let _fnIsShowExpand = _fnList.fnIsShowExpand;
    // let _isShow = _fnIsShowExpand!=null?_fnIsShowExpand({row}):false;
    let _showBtn = true;
    if(row.UI_DefaultHideExpandIcon===true){
      _showBtn=false;
    }
    let _styleCell = Object.assign({},_style);
    this._assignToRow(_more);
    return (
      <div className={_className} style={_styleCell}>
        {
          cell!=null && _more.titleBtn !=null &&
          <div style={{flex:1}}>{cell||_more.titleBtn}</div>      
        }          
        {
          _showBtn &&
          <div style={{display:'flex',width:'100%',flexFlow:'wrap',justifyContent:'center'}}>
            {this._renderList(_more,_fnList)}
          </div>
        }
      </div>)
  }
}

export default Z;