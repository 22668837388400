import React from 'react';
import MyLoadingComponent from '../MyLoading/MyLoadingComponent'

const CSuppense = function(props,TagC){
  return (
    <React.Suspense fallback={<MyLoadingComponent />}>
      <TagC {...props}/>
    </React.Suspense>
  )
}

export default CSuppense;