import Api from './api';

const name_prefix = 'project_photo_';
export default {
  request:{
    get_photos:{
      type: name_prefix + 'get_photos',
      url: '/api/v1/ProjectPhoto/GetPhotos',
    },
    upload:{
      type: name_prefix + 'upload',
      url: '/api/v1/ProjectPhoto/Upload',
    },
    save_avatar:{
      type: name_prefix + 'save_avatar',
      url: '/api/v1/ProjectPhoto/SaveAvatar',
    },
    finish_upload:{
      type: name_prefix + 'finish_upload',
      url: '/api/v1/ProjectPhoto/FinishUpload',
    },
  },
  get_photos(ProjectId,ScreenCode,Id,FieldName,options={}){
    options.data = {
      ScreenCode: ScreenCode,
      Id: Id,
      FieldName: FieldName,
    };
    if(ProjectId){
      options.data.ProjectId = ProjectId;
    }
    Api.post(this.request.get_photos,options);
  },
  uploadMultifiles(ProjectId,ScreenCode,Id,FieldName,files,options={}){
    options.files = files;
    options.customHeader = {
      ScreenCode: ScreenCode,
      Id: Id,
      FieldName: FieldName,
    }
    if(options.moreQueryData){
      options.customHeader = Object.assign(options.customHeader,options.moreQueryData);
    }
    if(ProjectId){
      options.customHeader.ProjectId = ProjectId;
    }
    Api.uploadFile(this.request.upload,options)
  },
  upload(ProjectId,ScreenCode,Id,FieldName,file,options={}){
    options.file = file;
    options.customHeader = {
      ProjectId: ProjectId,
      ScreenCode: ScreenCode,
      Id: Id,
      FieldName: FieldName,      
    }
    if(options.moreQueryData){
      options.customHeader = Object.assign(options.customHeader,options.moreQueryData);
    }
    if(options.DontNeedResponse==true){
      options.customHeader.DontNeedResponse = "1";
    }
    Api.uploadFile(this.request.upload,options)
  },
  save_avatar(ProjectId,ScreenCode,Id,FieldName,FileUrl,options={}){
    options.data = {
      ProjectId: ProjectId,
      ScreenCode: ScreenCode,
      Id: Id,
      FieldName: FieldName,
      FileUrl:FileUrl,
    };
    Api.post(this.request.save_avatar,options);
  },
  finish_upload(ProjectId,ScreenCode,Id,FieldName,FileUrl,options={}){
    options.data = {
      ProjectId: ProjectId,
      ScreenCode: ScreenCode,
      Id: Id,
      FieldName: FieldName,
      FileUrl:FileUrl,
    };
    Api.post(this.request.finish_upload,options);
  },
}