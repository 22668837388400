import React from 'react'
import MyLayoutTypes from '../../../../v1/components2/MyLayout/MyLayoutTypes';
import CSuppense from '../../../../components/MyCore/CSuspense'

const Tab = React.lazy(() => import('./Types/tab'));

const AppMyLayoutTypes = {
  init(){    
    MyLayoutTypes.addType('tab',(props)=>{ return CSuppense( props, Tab ) });
  }
}
export default AppMyLayoutTypes;