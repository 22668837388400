import React, { Component } from 'react';
class MyErrorScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render() {
    // console.warn('render MyLoading:',this.props);
    return (
      <div className={"mlds"}>{this.props.msg}</div>
    )
  }
}

export default MyErrorScreen;
