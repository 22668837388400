import React, { Component } from 'react';
import {
  Button
} from '../_import';

class Button_Loading extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isLoading:false
    };
  }

  componentDidMount(){
  }

  onClick=()=>{
    const {onClick} = this.props;
    if(onClick){
      onClick();
    }
  }
  showLoading=()=>{
    this.setState({isLoading:true})
  }
  hideLoading=()=>{
    this.setState({isLoading:false})
  }

  render() {
    const {color,style,className,disabled,loading} = this.props;
    let _disabled = disabled;
    let _loading = this.state.isLoading || loading;
    if(_disabled!==true && _loading===true){
      _disabled = true;
    }

    return (
      <Button color={color} style={style} className={className} disabled={_disabled} onClick={this.onClick}>
        {
          _loading===true && 
          <i className="fa fa-spinner fa-spin" style={{marginRight:'5px'}}/>
        }
        {this.props.title}
        {this.props.children}
      </Button>
    )
  }
}

export default Button_Loading;