import Button from './Button';
import './Button.css';
import './Button.less';
// import ButtonGroup from './ButtonGroup';
// Object.defineProperty(Button, 'ButtonGroup', {
//   get() {
//     console.warn('Button.ButtonGroup is deprecated, use uxcore-button-group instead');
//     return ButtonGroup;
//   },
// });
export default Button;
