// @flow
import * as React from 'react';
import { ManagerReferenceNodeSetterContext } from './Manager';
import { safeInvoke, unwrapArray, setRef } from './utils';

export function Reference({ children, innerRef }) {
  const setReferenceNode = React.useContext(ManagerReferenceNodeSetterContext);

  const refHandler = React.useCallback(
    (node) => {
      setRef(innerRef, node);
      safeInvoke(setReferenceNode, node);
    },
    [innerRef, setReferenceNode]
  );

  // ran on unmount
  React.useEffect(() => () => setRef(innerRef, null));

  React.useEffect(() => {
  }, [setReferenceNode]);

  return unwrapArray(children)({ ref: refHandler });
}
