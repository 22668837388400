import React from 'react'
import {MyUIHelpers, CSuppense} from "@macashipo/mlib"

const MyUIDESearchResultJson= React.lazy(() => import('./Types/de_search_result_json'));
const MyUIDESearch= React.lazy(() => import('./Types/de_search'));
const MyUIDEJsonEditor= React.lazy(() => import('./Types/de_json_editor'));
const MyUIDETable= React.lazy(() => import('./Types/de_table'));
const MyUIDESearchForApi= React.lazy(() => import('./Types/de_search_for_api'));

/* Common Types*/
const InputText = React.lazy(() => import('../../../../v1/components2/MyUI/Types/input_text'));

const List = {
  de_search_result_json: MyUIDESearchResultJson,
  de_search: MyUIDESearch,
  de_json_editor: MyUIDEJsonEditor,
  de_table: MyUIDETable,
  input_text: InputText,
  de_search_for_api: MyUIDESearchForApi
}
const AppMyUI = {
    init(){
      for(let k of Object.keys(List)){
        MyUIHelpers.addTypes(k,(props)=>{return CSuppense(props,List[k])})
      }
    }
  }
  export default AppMyUI;