import React, { Component } from 'react';
import Button_Api from './TypesButton/button_api'
import Button_Loading from './TypesButton/button_loading'
import Button_Popover from './TypesButton/button_popover'

const MyUIButton = {
  api: Button_Api,
  loading: Button_Loading,
  popover: Button_Popover,
}

export default MyUIButton;
