import { createStore, combineReducers} from 'redux';
import core from '../store';

const StoreRedux = {
  myStore: null,
  HConfig: null,
  initStore(Config,CustomStore){
    StoreRedux.HConfig = Config;
    StoreRedux.myStore = createStore(combineReducers({
      core,
      ...CustomStore
    }));
  },
  getState(){
    if(StoreRedux.myStore){
      return StoreRedux.myStore.getState();
    }
  },
  dispatch(obj){
    console.warn("StoreRedux dispatch:",obj);
    if(StoreRedux.myStore){
      StoreRedux.myStore.dispatch(obj);
    }
  },
}

window.HStoreRedux = StoreRedux;
export default StoreRedux