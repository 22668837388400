import React, { Component } from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter
} from '../../_importComponent';
import {HText} from '../../_import'

class ModalPhotoViewer extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      isShow: false,
      arrPhoto: [],
      currentPhoto: {},
      index: -1,
      opts: {},
    };
    // console.log('ModalPhotoViewer constructor: ',props);
  }

  componentDidMount(){
    ModalPhotoViewer.Helper.component = this;
  }

  show=(arrPhoto,index=0,opts)=>{
    let _currentPhoto = {};
    if(arrPhoto!=null && arrPhoto.length>index && index>-1){
      _currentPhoto = arrPhoto[index]
    }
    this.setState({
      arrPhoto: arrPhoto,
      isShow: true,
      currentPhoto: _currentPhoto,
      index: index,
      opts: opts
    })
  }

  hide=()=>{
    this.setState({
      isShow:false,
    })
  }

  _onPrev=()=>{
    const {arrPhoto} = this.state;
    let _index = this.state.index;
    if(_index>0){
      let _photo = arrPhoto[_index-1];
      this.setState({
        index: _index-1,
        currentPhoto: _photo,
      })
    }
  }
  _onNext=()=>{
    const {arrPhoto} = this.state;
    let _index = this.state.index;
    if(_index<arrPhoto.length-1){
      let _photo = arrPhoto[_index+1];
      this.setState({
        index: _index+1,
        currentPhoto: _photo,
      })
    }
  }

  _showArrow=()=>{
    let _ui = [];
    _ui.push(
      <button key='arr_left' className="btn-arr-common btn-arr-left no-print" onClick={this._onPrev}/>
    )
    _ui.push(
      <button key='arr_right' className="btn-arr-common btn-arr-right no-print" onClick={this._onNext}/>
    )
    return _ui;
  }
  render() {
    let _opts = this.state.opts || {};
    return (
      <Modal fade={false} className="modal-lg" isOpen={this.state.isShow} toggle={()=>{this.setState({isShow:!this.state.isShow})}}>
        {/* <ModalHeader toggle={()=>{this.setState({isShow:!this.state.isShow})}}>
        <div>
          Xem ảnh đầy đủ
          <Button onClick={this._onPrev} style={{marginLeft:'10px',marginRight:'10px'}} disabled={this.state.index<=0?true:false}><i className="fa fa-angle-double-left"/> Trước</Button>
          <Button onClick={this._onNext} disabled={this.state.index>=this.state.arrPhoto.length-1?true:false}>Sau <i className="fa fa-angle-double-right"/></Button>
        </div>
        </ModalHeader> */}
        <ModalBody style={{minHeight:'200px'}}>
        {
          this.state.currentPhoto!=null && this.state.currentPhoto.url!=null &&
          <img style={{width:'100%',height:'100%',minHeight:'200px'}} src={this.state.currentPhoto.url}/>
        }
        {
          this._showArrow()
        }
        {
          this.state.currentPhoto!=null && _opts.showFullLink !==false &&
          <a href={this.state.currentPhoto.link || this.state.currentPhoto.url} target='_blank' className="dFfdRaiCjcCtaC" style={{position:'absolute',top:'0px',right:'0px',width:'20px',height:'20px'}}>
            <i className="fa fa-external-link"/>
          </a>
        }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={()=>{this.setState({isShow:!this.state.isShow})}}>{HText.get('btn_close')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalPhotoViewer.Helper = {
  component: null,
  show(arrPhoto,index,opts){
    if(ModalPhotoViewer.Helper.component){
      ModalPhotoViewer.Helper.component.show(arrPhoto,index,opts);
    }
  },
  hide(){
    if(ModalPhotoViewer.Helper.component){
      ModalPhotoViewer.Helper.component.hide();
    }
  },
}
export default ModalPhotoViewer;
