import Api from './api';
import HConstant from '../helpers/Constant';
export default {
  request:{
    login:{
      type: 'account_login',
      url: '/oauth2/token',
    },
    logout:{
      type: 'account_logout',
      url: '/api/v1/Account/Logout',
    },
    login_social:{
      type: 'account_login_social',
      url: '/api/v1/Account/SocialLogin',
    },
    register_social:{
      type: 'account_register_social',
      url: '/api/v1/Account/SocialRegister',
    },
  },
  login(u,p,options={}){
    options.data = {
      grant_type: 'password',
      username: u,
      password: p,
      app_name: HConstant.gc(HConstant.AppConfig.appName)
    }
    Api.postForm(this.request.login,options);
  },
  login_social(data,options={}){
    options.data = data;
    Api.post(this.request.login_social,options)
  },
  login_google(acesss_token,profile,options={}){
    options.data = {
      // SocialDisplay: "",
      SocialId: "google",
      SocialToken: acesss_token,
      // SocialUserId: "",
      SocialUsername: profile.email,
      // SocialUrlId: "",
      // SocialEmail: "",
      // UserLoginId: "",
      // AppName: Constant.AppName,
      // DeviceNumber: "",
      // SocialTokenSecret: ""
    };
    // options.data = {
    //   SocialId: "google",
    //   SocialToken: 'ya29.GlyDBT_4I-TUZZNJic24jMerekMGjRkXD1Qk0P5UD5OfOIESx-SHHtEqmxWOmtV3h_wiH9-7-JNIwb24lQcXaVbTFZnz6AdpsnUH97Mr9Jj9FV74vAgvBNFpnRtpyQ',
    //   SocialUsername: 'vutt@solidinterior.vn',
    //   AppName: Constant.AppName,
    // };
    Api.post(this.request.login_social,options);
  },
  logout(){
    Api.setAuthrozieKey(null,null);
  }
}