import React, { Component } from 'react';
import {HText,HUI,MyDialog,SApiGeneric} from '../_import'
import Button_Loading from './button_loading'
class Button_Api extends Button_Loading {
  componentDidMount(){
  }
  onClick=()=>{
    const {config} = this.props;
    if(config && config.ConfirmMsg){
      HUI.Alert.showMsgQuestion(HText.get('btn_confirm'),config.ConfirmMsg,({hide,disableAll})=>{
        disableAll();
        this._onRequest(()=>{
          hide();
        });
      },()=>{},false);
    }   
    else{
      this._onRequest();    
    } 
  }
  _onRequest=()=>{
    const {queryData,config,reloadTable,apiPathAndName,fnList} = this.props;
    this.setState({
      isLoading:true
    },()=>{
      let _queryData = queryData;
      let _url = `/api/v1/${apiPathAndName}`;
      if(fnList){
        if(fnList.getRequestData){
          _queryData = Object.assign({},queryData,fnList.getRequestData());
        }
        if(fnList.getApiUrl){
          _url = fnList.getApiUrl();
        }
      }
      SApiGeneric.generic({
        request:{
          method: 'POST',
          url: _url
        },
        data: _queryData,
        successCallBack:(response)=>{
          let _msg = response.Msg;
          let _data = response.Data;
          if(_msg){
            HUI.Toast.showSuccess(_msg);
          }
          if(fnList && fnList.onSuccess){
            fnList.onSuccess(response);
          }
          if(config && config.NeedReload==true){
            window.location.reload();
          }
          else if(config && config.NeedReloadList==true && reloadTable!=null){
            reloadTable();
          }        
          if(_data){
            if(_data.FullPath){
              MyDialog.Helper.show({
                title: 'Link',
                msg: (<div style={{wordWrap:'break-word'}}><a href={_data.FullPath} target="_blank">{_data.FullPath}</a></div>)});
            }
          }
          this.setState({isLoading:false});
        },
        errorCallBack:(error,response)=>{
          this.setState({isLoading:false});
        }
      })
    })
  }
}

export default Button_Api;