import classnames from 'classnames';
import React from 'react';
import {
  Button
} from '../../../_importComponent';
import ModalPhotoViewer from '../../../components/ModalPhotoViewer';
import { CellHelper } from '../Helpers';
import {HUtils} from '../../../_import'

/**
 * Cell Type: link.
 * link lay tu Type.link hoac cell
 *
 * @class link
 * @static
 * @namespace link
 * @memberof CellType
 * 
 */
class CellTypeLink extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.link
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component   
   * @param {String} [linkTitle] title của link (Tên hiển thị của link - mặc định là cell)
   * @param {String} [showPhoto] hiển thị hình ảnh thay title
   * @param {String} [fPhoto] field chứa hình ảnh
   * @param {String} [urlPhoto] url của hình ảnh
   * @param {Number} [maxHeight] maxWidth của hình
   * @param {Number} [maxWidth] maxHeight của hình
   * @param {Boolean} [noScale] hình ảnh có scale không
   * @param {Object} styleImg style của Img khi showPhoto
   * @param {Boolean} showPreview ở chế độ showPhoto có show dạng ModalPhoto hay không
   * @param {Boolean} newWindow có mở cửa sổ mới hay không
   * @param {String} separatorChar kí tự phân cách mảng link
   * 
  */
  more() {
  }

  _renderArray=()=>{
    const {cell,row,extra,index} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _separatorChar = CellHelper.More.getSeparatorChar({extra,row,more:_more});
    let _target = CellHelper.More.getNewLinkType({extra,row,more:_more});
    let _ui = [];
    for(let i=0;i<cell.length;i++){
      let _item = cell[i];
      let _link = _item.Link;
      let _isExternal = true;
      if(_link){
        if(_link.startsWith('http')){
          _isExternal = true;
        }
        else if(!_link.startsWith('#/')){
          _link = `#${_link.startsWith('/')?_link:'/'+_link}`;
        }
        _ui.push(
          <div key={i}>
            <a href={_link} target={_target} title={_item.Tooltip}>{_item.Name||_link}</a>
          </div>
        )
        if(_separatorChar && i<cell.length-1){
          _ui.push(
            <span key={`${i}_sep`}>{_separatorChar}</span>
          )
        }
      }  
    }
    let _styleWrap = {};
    if(_separatorChar && _style){
      _styleWrap.display = 'flex';
      _styleWrap.flexDirection = 'row';
    }
    return (
      <div className={_className} style={_style}>
        <div style={_styleWrap}>
          { _ui }
        </div>
      </div>
    )
  }

  render() {
    const {cell,row,extra,index} = this.props;
    if(cell && Array.isArray(cell)){
      return this._renderArray();
    }
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _icon = CellHelper.renderIcon({extra,row,more:_more,dfIconClassName:"fa fa-external-link"});
    let _target = CellHelper.More.getNewLinkType({extra,row,more:_more});

    // console.warn('link:',_link,this.props);
    let _title = cell;
    if(_icon!=null){
      _title = _icon;
    }
    else if(_more.linkTitle){
      _title = _more.linkTitle;
    }
    else if(_more.fLinkTitle && row[_more.fLinkTitle]){
      _title = row[_more.fLinkTitle];
    }

    let _link = extra.Type!=null?extra.Type.link:'';
    if(!_link){
      _link = cell;
    }          
    let _linkAvaiable = true;
    if(_link && _link.indexOf('[')>-1){
      let _extract = HUtils.SquareBracket.extract(_link);
      if(_extract!=null && _extract.length>0){
        for(let _e of _extract){
          if(row[_e]!=null){
            let _rg = new RegExp('\\['+_e+'\\]','g');
            // console.log(_rg,_link,row[_e]);
            _link = _link.replace(_rg, row[_e]);
          }
          else{
            _linkAvaiable = false;
          }
        }
      }
    }

    if(_link==null || _link.length==0){
      _linkAvaiable = false;
    }    

    if(_linkAvaiable==false){
      return (
        <div className={_className} style={_style}>
          {cell}
          {
            CellHelper.renderStatus({cell,extra,row,more:_more,component:this})
          }
        </div> 
      )
    }

    let _isExternal = false;
    if(_link){
      if(_link.startsWith('http')){
        _isExternal = true;
      }
      else if(!_link.startsWith('#/')){
        _link = `#${_link.startsWith('/')?_link:'/'+_link}`;
      }
    }
    
    if(_more.showPhoto==true){
      let _urlPhoto = _link;
      if(_more.fPhoto!=null){
        _urlPhoto = row[_more.fPhoto];
      }
      if(_more.urlPhoto!=null){
        _urlPhoto = _more.urlPhoto;
      }
      let _styleBtn = {
        position:'relative',width:'100%',height:'100%',padding:'0px'        
      };
      let _maxHeight = '120px';
      let _maxWidth = '120px';
      if (_more.maxHeight){
        _maxHeight = _more.maxHeight;
      }
      if (_more.maxWidth){
        _maxWidth = _more.maxWidth;
      }
      let _styleImg = {width:'100%',height:'100%',maxHeight:_maxHeight};
      if (_more.noScale == true){
        _styleImg = {maxHeight:_maxHeight,display:'block', maxWidth:_maxWidth, width:'auto', height:'auto'};
      }
      if(_more.styleImg){
        _styleImg = Object.assign(_styleImg,_more.styleImg);
      }
      return (
        <div className="dFaiCjcC" style={{position:'relative'}}>
          <Button 
            className="dFaiCjcC"
            color={"link"}          
            style={_styleBtn}
            onClick={()=>{
              // window.open(_link);
              let _fLinkPreview = "FileUrl_FullSize";
              let _fName = "FileName"
              if(_more.fLinkPreview && row[_more.fLinkPreview]){
                _fLinkPreview = _more.fLinkPreview
              }
              if(_more.fName && row[_more.fName]){
                _fName = _more.fName
              }
              if (_more.showPreview && _more.showPreview==true){
                ModalPhotoViewer.Helper.show([{
                  title: row[_fName],
                  url: row[_fLinkPreview],
                }],0);       
              }else{
                window.open(_link);
              }   
          }}>
          <img src={_urlPhoto} style={_styleImg}/>
          </Button>
        </div>
      )
    }    
    return (
      <div className={_className} style={_style}>
        {
          _isExternal?
          <a href={_link} target={_target}>{_title}</a>
          :
          <a href={_link} target={_target}>{_title}</a>
        }       
        {
          CellHelper.renderStatus({cell,extra,row,more:_more,component:this})
        } 
      </div>
    )
  }
}

export default CellTypeLink;