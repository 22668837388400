import React from 'react';
import HUtils from '../../helpers/Utils'
class CLoading extends React.Component {
  render(){
    return (
        <div className="sk-spinner sk-spinner-pulse"></div>
    )
  }
}
class CFade extends React.Component {
  render(){
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}
class CErrorBoundary extends React.Component {
  constructor(props) {
      super(props);
      this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn("error UI:",error,errorInfo,this.props);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div></div>;
    }
    return this.props.children; 
  }
}
const CSuppense = function(props,TagC){
  return (
    <React.Suspense fallback={<CLoading />}>
      <TagC {...props}/>
    </React.Suspense>
  )
}
class CEmpty extends React.Component {
  render(){
    console.warn("render CEmpty");
    return (
        <div></div>
    )
  }
}
const Types = {
  addType(type,list,fnUI,opts={}){
    HUtils.addTypeComponent(type,list,fnUI,opts);
  },
  addTypeByTag(type,list,TagC,opts={}){
    HUtils.addTypeComponent(type,list,(props)=>{
      return CSuppense(props,TagC)
    },opts);
  },
  Components:{
      CErrorBoundary: CErrorBoundary,
      CSuppense: CSuppense,
      CEmpty: CEmpty,
      CLoading: CLoading,
      CFade: CFade,
      Button: CEmpty,
  },
  Libs:{
  },
}

export default Types;