import React from 'react';
import { Button } from '../../../_importComponent';
import { HConstant, HText } from '../../../apps/Helpers';
import { Popover } from '../../../mreact/core';
import { CellHelper } from '../Helpers';
import {MyUI} from "@macashipo/mlib"
import MyTableCellHelpersV2 from '../../../components2/MyTableCell/MyTableCellHelpers'
/**
 * Cell Type: delete.
 *
 * @class delete
 * @static
 * @namespace delete
 * @memberof CellType
 * 
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    const {cell,row,extra} = this.props;
    this.state={
        value:'',
    };
    if (cell){
      this.state.value = cell
    }
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.delete
   * 
  */
  more() {
  }

  render() {
    const {cell,row,extra} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _fnList = CellHelper.getFnList({extra,row});
    let _title = CellHelper.getTextFromTableConfigApplyRow({extra,row,key:HConstant.TableConfig.deleteTitle,defaultValue:HText.get("btn_delete")});
    let _content = CellHelper.getTextFromTableConfigApplyRow({extra,row,key:HConstant.TableConfig.deleteContent,defaultValue:HText.get("confirm_delete")});
    let _contentHtml = CellHelper.getTableConfig({extra,row,more:_more,key:HConstant.TableConfig.deleteContentHtml});
    if(_contentHtml && _contentHtml.length>0){
      _content = (
        <MyUI type="html" html={_contentHtml} data={row}/>
      )
    }
    let _fCanDelete = CellHelper.getTableConfig({extra,row,more:_more,fnList:_fnList,key:HConstant.TableConfig.fCanDelete});
    // console.warn("fCanDelete",_fCanDelete,_fnList);
    let _canDelete = true;
    if(row.IsLocked===true){
      _canDelete = false;
    }
    if(_canDelete && _fCanDelete && row[_fCanDelete]===false){//WEBAPI-6181
      _canDelete = false;
    }
    if(_canDelete){
      return (
        <div className="a_del">
          <Popover placement="topRight" title={_title}
            overlay={<div>{_content}</div>} trigger="click" showButton okText={HText.get("btn_ok")} cancelText={HText.get("btn_close")} 
            onOk={()=>{
              MyTableCellHelpersV2.runFnRequestDelete(this,{fnList:_fnList,row:row});
            }} 
            onCancel={()=>{console.log('Cancel')}}>
            <Button color="danger" outline style={{border:'0px',overflow:"visible"}} onKeyPress={(ev)=>{//bo sung overflow:"visible" vi css moi lam co them ...
                if(ev.key === 'Enter'){
                  MyTableCellHelpersV2.runFnRequestDelete(this,{fnList:_fnList,row:row});
                }
              }}><i className="fa fa-trash"/></Button>
          </Popover>
        </div>
      )
    }
    return(
      <div></div>
    )
  }
}

export default Z;