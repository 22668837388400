import React from 'react'
import CSuppense from '../MyCore/CSuspense'
import HUtils from '../../helpers/Utils'

const MLList_1 = React.lazy(() => import('./Types/list_1'));
const MLList_row = React.lazy(() => import('./Types/list_row'));
const MLGrid_1 = React.lazy(() => import('./Types/grid_1'));
const MLSlide_banner = React.lazy(() => import('./Types/slide_banner'));
const MLSlide_card = React.lazy(() => import('./Types/slide_card'));
const MLMansonryCss = React.lazy(() => import('./Types/mansonrycss'));
const MLTab_2= React.lazy(() => import('./Types/tab_2'));
const MLMainRight= React.lazy(() => import('./Types/main_right'));
const MLTreeView= React.lazy(() => import('./Types/treeview'));
const MLColumnResizer= React.lazy(() => import('./Types/column_resizer'));
const MLGanttChart= React.lazy(() => import('./Types/ganttchart'));

const MyLayoutTypes = {
  addType(key,fnUI){
    if(MyLayoutTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
        MyLayoutTypes.Types[key]=fnUI;
    }
  },
  Types:{   
    list: (props)=>{ return CSuppense(props,MLList_1) },
    list_1: (props)=>{ return CSuppense(props,MLList_1) },
    list_row: (props)=>{ return CSuppense(props,MLList_row) },
    grid: (props)=>{ return CSuppense(props,MLGrid_1) },
    grid_1: (props)=>{ return CSuppense(props,MLGrid_1) },
    slide_banner: (props)=>{ return CSuppense(props,MLSlide_banner) },
    slide_card: (props)=>{ return CSuppense(props,MLSlide_card) },
    mansonrycss: (props)=>{ return CSuppense(props,MLMansonryCss) },
    tab_2: (props)=>{ return CSuppense(props,MLTab_2) },
    main_right: (props)=>{ return CSuppense(props,MLMainRight) },
    treeview: (props)=>{ return CSuppense(props,MLTreeView) },
    column_resizer: (props)=>{ return CSuppense(props,MLColumnResizer) },
    ganttchart: (props)=>{ return CSuppense(props,MLGanttChart) },
  }
}
if(window._M){
  window._M.MyLayoutTypes = MyLayoutTypes;
}
export default MyLayoutTypes;