import React from 'react'
import MyCardTypes from './MyCardTypes';
import HUtils from '../../helpers/Utils'
class MyCard extends React.Component {    
  render(){
    const {type} = this.props;
    if(type && MyCardTypes.Types[type]){
      return MyCardTypes.Types[type](this.props);
    }
    HUtils.logExistType(type);
    return (
      <div></div>
    )
  } 
}

export default MyCard