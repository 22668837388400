import i18next from "i18next"
import Lang_en from './Language/en.json'
import Lang_vi from './Language/vi.json'
export const initI18n = (config) => {
  i18next.init(config || {
    lng: "en",
    defaultNS: "common",
    resources: {
      en: {
        common: Lang_en,
      },
      vi: {
        common: Lang_vi,
      },
    },
  })
}
