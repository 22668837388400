import {Tooltip} from '../../../libs/RCTooltip/V5';

export default class RCTooltip extends Tooltip {
    static displayName = 'm-tooltip'
    static propTypes = {
        ...Tooltip.propTypes,
    }
    static defaultProps = {
        ...Tooltip.defaultProps,
        prefixCls: 'm-tooltip',
        transitionName: 'tip-slide',
    }
}
