import React from 'react';
import {
  Input,
} from '../../../_importComponent';
import classnames from 'classnames';
import PropTypes from 'prop-types'
import {CellHelper} from '../Helpers';
import OptionsHelper from '../../../helpers/App/OptionsHelper';

/**
 * Cell Type: select.
 * Hiển thị dạng select theo một mảng truyền vào
 * 
 * @class select
 * @static
 * @namespace select
 * @memberof CellType
 * 
 * @example
 * Url: /config-staff
 * Col: DepartmentId
 */
class MyCellType_Select extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    const {cell,row,extra} = this.props;
    this.state={
      value:'',
    };
    if(cell){
      this.state.value = cell;
    }
  }

  componentWillReceiveProps(nextProps){
    // console.warn('componentWillReceiveProps',this.props,nextProps);
    // if(nextProps.cell!=this.props.cell){
    //   this.setState({value:nextProps.cell||''});
    // }
    let _needUpdate = CellHelper.isNeedUpdateValueAfterWillReceiveProps(this.props,nextProps,"");
    if(_needUpdate.needUpdate==true){
      this.setState({
        value: _needUpdate.value
      })
    }
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.select
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component
   * @param {String} [placeHolder] placeHolder của Input
   * 
  */
  more() {
  }

  render() {
    const {cell,row,extra} = this.props;
    console.warn('cell select:',cell,row,extra);
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    // let _fnList = CellHelper.getFnList({extra,row});
    let _options = CellHelper.getSourceSelect({extra,row});
    let _uiOptions = OptionsHelper.createListForSelect({
      sourceList: _options||[], //de luon co sourceList
      itemEmpty: '', 
      currentValue: this.state.value, 
    });
    let _styleInput = Object.assign({height:'100%'},_style);
    let _placeHolder = _more.placeHolder;
    let _disabled = !CellHelper.getCanEdit({extra,row});
    return (
      <div>
        <Input 
          className={_className}
          style={_styleInput}
          type="select"
          // dir={dir}
          value={this.state.value} 
          placeholder = {_placeHolder}
          disabled={_disabled}
          onChange={ (ev) => { 
            // console.log('ONCHANGE: ',ev,ev.currentTarget);
            // console.log('currentTarget.Value: ', ev.currentTarget.value);
            let _newValue = ev.currentTarget.value;
            this.setState(
              { 
                value: _newValue 
              },()=>{
                CellHelper.callRequestUpdate({extra,row,fieldName:extra.fieldName,newValue: _newValue,component:this});
                // this._onChange(ev,this.state.value);
              }
            );
          }}>
          {
            _uiOptions!=null && _uiOptions
          }
        </Input>
        {
          CellHelper.renderAddable({disabled:_disabled,cell,extra,row,more:_more,component:this,forceShowAddable:false})
        }
        {
          CellHelper.renderStatus({cell,extra,row,component:this})
        }
      </div>
    )
  }
}

export default MyCellType_Select;