// import axios from 'axios';
// import JSFileDonwnload from '../libs/JSFileDownload'
import Api from './api';
export default {
  PATH:{
    Account: 'Account',
    ConfigMenu: 'ConfigMenu',
    ConfigTask: 'ConfigTask',
    CRMContact: 'CRMContact',
    CRMOpty: 'CRMOpty',
    CRMQualifiedDoc: 'CRMQualifiedDoc',
    CRMTask: 'CRMTask',
    CRMTaskReportDetail: 'CRMTaskReportDetail',
    CRMUncoverNeed: 'CRMUncoverNeed',
    PlanWeekReportDetail: 'PlanWeekReportDetail',
    Project: 'Project',
    ProjectPhoto: 'ProjectPhoto',
    ProjectSearch: 'ProjectSearch',
    Staff: 'Staff',
    StaffLeadStaff: 'StaffLeadStaff',
    UserRole: 'UserRole',
    Upload: 'Upload',
    EmailQueue: 'EmailQueue',
  },
  NAME:{
    Add: 'Add',
    AddMulti: 'AddMulti',
    AddNewTask: 'AddNewTask',
    AddOptyLead: 'AddOptyLead',
    AddRole: 'AddRole',
    AddTask: 'AddTask',
    Approve: 'Approve',
    Detail: 'Detail',
    Delete: 'Delete',
    GetPhotoReport: 'GetPhotoReport',
    GetDocsReport: 'GetDocsReport',
    GetPhotos: 'GetPhotos',
    List: 'List',
    Options: 'Options',
    UnApprove: 'UnApprove',
    UpdateFields: 'UpdateFields',
    CategoryScreenList: 'CategoryScreenList',
    RemoveRole: 'RemoveRole',
    UpdateFields_Staff: 'UpdateFields_Staff',
    UpdateFields_Screen: 'UpdateFields_Screen',
    UserInfo: 'UserInfo',
    ProjectLogo: 'Project/Logo',
    UserMyAvatar: 'User/MyAvatar',
    ChangePassword: 'ChangePassword',
  },
  generic({request,...more}){
    // console.warn('generic:',request,more);
    let _request = {
      type: `${request.path}/${request.name}`,
      url: `/api/v1/${request.path}/${request.name}`,
    };
    if(request.url!=null){
      _request.type = request.url;
      _request.url = request.url;
    }
    if(_request.url && _request.url.startsWith("/")==false){//Bo sung / neu url quen co dau / dang truoc
      _request.url = `/${_request.url}`;
    }
    if(request.method=='POST'){
      Api.post(_request,more);
    }
    else if(request.method=='GET'){
      Api.get(_request,more);
    }
    else if(request.method=='UPLOAD'){
      Api.uploadFile(_request,more)
    }
  },
  getOptions(path,{...more}){
    let _pwn = `${path}/Options`;
    if(path.indexOf('/')>-1){
      _pwn = path;
    }
    let _request = {
      type: `${_pwn}`,
      url: `/api/v1/${_pwn}`,
    }
    let _data = more.data || {};
    if(!_data.Url){
      _data.Url = window.location.href;
    }
    more.data = _data;
    Api.post(_request,more);
  },
  getList(path,{...more}){
    let _pwn = `${path}/List`;
    if(path.indexOf('/')>-1){
      _pwn = path;
    }
    let _request = {
      type: `${_pwn}`,
      url: `/api/v1/${_pwn}`,
    }
    let _data = more.data || {};
    if(!_data.Url){
      _data.Url = window.location.href;
    }
    more.data = _data;
    Api.post(_request,more);
  },
  delete(path,{...more}){
    let _pwn = `${path}/Delete`;
    if(path.indexOf('/')>-1){
      _pwn = path;
    }
    let _request = {
      type: `${_pwn}`,
      url: `/api/v1/${_pwn}`,
    }
    let _data = more.data || {};
    if(!_data.Url){
      _data.Url = window.location.href;
    }
    more.data = _data;
    Api.post(_request,more);
  },
  updateFields(path,row,values,{...more}){
    let _pwn = `${path}/UpdateFields`;
    if(path.indexOf('/')>-1){
      _pwn = path;
    }
    let _request = {
      type: `${_pwn}`,
      url: `/api/v1/${_pwn}`,
    }
    let _data = more.data || {};
    if(!_data.Id){
      _data.Id = row.Id;
    }
    if(!_data.Values){
      _data.Values = values;
    }
    if(!_data.Url){
      _data.Url = window.location.href;
    }    
    more.data = _data;
    Api.post(_request,more);
  },
  // resolve({request,...more}){
  //   console.log('generic:',request,more);
  //   let _request = {
  //     type: `${request.path}/${request.name}`,
  //     url: `/api/v1/${request.path}/${request.name}`,
  //   };
  //   if(request.method=='POST'){
  //     var p = new Promise(
  //       function (resolve, reject) {
  //         var r = Api.post(_request,more).then(response=>{
  //           resolve(response);
  //         });
  //         // resolve({a:'1',r:r});
  //         // reject(reason);    
  //     });
  //     return p;
  //   }
  // },
  // download(link,name){
  //   axios({
  //     url: link,
  //     method: "GET",
  //     responseType: 'blob'
  //   }).then((response)=>{
  //     JSFileDonwnload(response.data,name||'download.txt')
  //   })
  // }
}