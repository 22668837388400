import React from 'react';
import { Button } from '../../../_importComponent';
import MyForm from '../../../components/MyForm/MyForm';
import MyUI from '../../../components/MyUI/MyUI';
import HText from '../../../helpers/Text';
import HUI from '../../../helpers/UI';
import { Popover } from '../../../mreact/core';
import InputControl from '../../../views/Components/InputEditor/CustomControl';

class CellType_ComponentAddable extends React.Component {
  constructor(props) {
    super(props);
    this.state={
    };
  }

  _renderDefautForm=()=>{
    return (
      <div>
        <div>Nhập Tên:</div>
        <InputControl
          ref={r=>{this._cInputNew=r;}}
          type={'text'} 
        />
      </div>
    )
  }

  _renderForm=()=>{
    const {configAddable,fnList} = this.props;
    if(configAddable && configAddable.form){
      return (
        <MyForm 
          ref={r=>{this._cForm=r;}} 
          getOptionOfKey={(key)=>{    
            if(fnList && fnList.fnGetOptions){
              let _options = fnList.fnGetOptions();
              if(_options[key]){
                return _options[key];
              }
            }                
            return [];
          }}
          config={configAddable.form}/>
      )
    }
    else if(configAddable && configAddable.myui && configAddable.myui.type){
      return (
        <MyUI type={configAddable.myui.type} fnList={fnList}/>
      )
    }
    else{
      return this._renderDefautForm();
    }
  }

  _onSubmit=(hideCallback)=>{
    const {configAddable,onSubmit,fName} = this.props;
    if(configAddable && configAddable.form){
      hideCallback && hideCallback();    
      if(this._cForm){
        let _values = this._cForm.getValues();
        onSubmit && onSubmit(_values,"");
      }  
    }
    else{
      if(this._cInputNew!=null){
        let _newValue = this._cInputNew.getValue();
        if(_newValue==null||_newValue.length==0){
          HUI.Toast.showWarn('Vui lòng nhập tên trước khi đồng ý thêm mới!');
        }
        else{
          hideCallback && hideCallback();
          let _data = {};
          _data[fName||"Name"] = _newValue;     
          onSubmit && onSubmit(_data,_newValue);
        }
      }
    }
  }

  render() {
    const me = this;
    let _titlePopover = this.props.title || HText.get('text_addnew');
    return (
      <Popover placement="left" title={_titlePopover} onVisibleChange={(visible)=>{
        // console.log("visible",visible);
        if(visible){
          setTimeout(() => {
            if(me && me._cInputNew){
              me._cInputNew.focus();
            }
          }, 200);
        }        
      }} overlay={
        me._renderForm()
        } trigger="click" showButton okText={HText.get('btn_ok')} cancelText={HText.get('btn_close')} onOk={
          (hideCallback)=>{            
            me._onSubmit(hideCallback);
          }
        } onCancel={()=>{console.log('Cancel')}}>
        <Button className="tbpj_menu_add no-border" color="link" ><i className="fa fa-plus-circle"/></Button>
      </Popover>
    )
  }
}
CellType_ComponentAddable.defaultProps={
  configAddable: {}
}
export default CellType_ComponentAddable;