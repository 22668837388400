import HConstant from './helpers/Constant'//need: HUtils
import HUtils from './helpers/Utils'
import HText from './helpers/Text'
import HLocalStorage from './helpers/LocalStorage'
import HDate from './helpers/Date'//need: moment
import HOptions from './helpers/Options'//need: HColor, react, tinyColor, M
import HUI from './helpers/UI'//need: toast, MyModalAlert, HText, HConfig
import HConfig from './helpers/Config'
import HLink from './helpers/Link'//need:history/createHashHistory
import HEncode from './helpers/Encode'
import HStore from './helpers/Store'
import {Auth as HAuth} from './helpers/Auth'
import HColor from './helpers/Color'//need:Mv1, tinyColor
import HNumber from './helpers/Number'//need: NumeralJs
import HFormat from './helpers/Format'//need: momment
import HEE from './helpers/EventEmitter'
import HAsset from './helpers/Asset'
import HA from './helpers/A'
import {Error as HError} from './helpers/Error';
const _test = {
    HConstant,
    HUtils,
    HText,
    HLocalStorage,
    HDate,
    HOptions,
    HUI,
    HConfig,
    HLink,
    HEncode,
    HStore,
    HAuth,
    HColor,
    HNumber,
    HFormat,
    HEE,
    HAsset,
    HError,
  }
  let _keys = Object.keys(_test);
  for(let k of _keys){
    if(_test[k]==null){
      console.warn("import H null:",k);
    }
  }

export {
    HConstant,
    HUtils,
    HText,
    HLocalStorage,
    HDate,
    HOptions,
    HUI,
    HConfig,
    HLink,
    HEncode,
    HStore,
    HAuth,
    HColor,
    HNumber,
    HFormat,
    HEE,
    HAsset,
    HError,
    HA,
}