import React, { Component } from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter
} from '../../_importComponent';
import HText from '../../helpers/Text'
class MyModalAlert extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      title:'Solid',
      msg: 'Hello',
      arrButton: [],
      isShow: false,
      disableAll: false,
      opts: null,
    };    
  }

  componentDidMount(){
    MyModalAlert.Helper.component = this;
  }

  show=(title,msg,arrButton,opts)=>{
    this.setState({
      title: title,
      msg: msg,
      arrButton: arrButton,
      isShow:true,
      disableAll: false,
      opts: opts
    })
  }

  hide=()=>{
    this.setState({
      isShow:false,
    })
  }

  disableAll=()=>{
    this.setState({
      disableAll: true,
    })
  }
  activeAll=()=>{
    this.setState({
      disableAll: false,
    })
  }

  _renderButton=()=>{
    const {arrButton} = this.state;
    if(arrButton!=null && arrButton.length>0){
      let _ui = [];
      for(let i=0;i<arrButton.length;i++){
        let _item = arrButton[i];
        let _color = _item.color||'link';
        let _autoClose = _item.autoClose!=null?_item.autoClose:true;
        _ui.push(
          <Button disabled={this.state.disableAll} key={i} color={_color} style={{marginLeft:'5px'}} onClick={()=>{
            console.log('click :',_autoClose,_item);
            if(_autoClose==true){
              this.setState({isShow:!this.state.isShow},()=>{
                if(_item.callback!=null){_item.callback({hide:this.hide,disableAll:this.disableAll});}
              })
            }
            else{
              if(_item.callback!=null){_item.callback({hide:this.hide,disableAll:this.disableAll});}
            }
          }}>{_item.title}</Button>
        )
      }
      return (
        <div>
          {
            _ui
          }
        </div>
      )
    }
    else{
      return (
        <Button color="link" onClick={()=>{this.setState({isShow:!this.state.isShow})}}>{HText.get('btn_close')}</Button>
      )
    }
  }

  render() {
    let _opts = this.state.opts || {};
    let _type = _opts.type;
    let _className = "modal-alert";
    let _icon;
    if(_type){
      _className += ` modal-alert-ui modal-alert-${_type}`;
      if(_type=="success"){
        _icon = "fa fa-check-circle"
      }
      else if(_type.indexOf('warn')>-1){
        _icon = "fa fa-exclamation-triangle"
      }
      else if(_type.indexOf('error')>-1 || _type.indexOf('danger')>-1){
        _icon = "fa fa-times-circle"
      }
    }
    // console.warn("modal-alert:",this.state,_opts,_type);
    return (
      <Modal className={_className} fade={false} isOpen={this.state.isShow} toggle={()=>{this.setState({isShow:!this.state.isShow})}}>
        {/* <ModalHeader toggle={()=>{this.setState({isShow:!this.state.isShow})}}>{this.state.title}</ModalHeader> */}
        <div className="modal-header">
          {
            _icon!=null &&
            <div className="modal-icon">
              <i className={_icon} style={{fontSize:'80px'}}/>
            </div>
          }
          
          <h5>{this.state.title}</h5>
          <Button className="close" onClick={this.hide}><span aria-hidden="true">×</span></Button>
        </div>
        <ModalBody>
          {
            this.state.msg
          }
        </ModalBody>
        <ModalFooter>
          {
            this._renderButton()
          }          
        </ModalFooter>
      </Modal>
    )
  }
}

MyModalAlert.Helper = {
  component: null,
  show(title,msg,arrButton,opts){
    if(MyModalAlert.Helper.component){
      MyModalAlert.Helper.component.show(title,msg,arrButton,opts);
    }
  },
  hide(){
    if(MyModalAlert.Helper.component){
      MyModalAlert.Helper.component.hide();
    }
  },
}

export default MyModalAlert;
