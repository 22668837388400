const Dev = {
  isDebug: false,
  initDev(HConfig){
    Dev.isDebug = Dev.getIsDev();
    if(Dev.isDebug!=true){
      console.log = function(){};
      console.warn = function(){};
    }else{
      let _localBaseUrl = localStorage.getItem('_localBaseUrl');
      let _isUsingLocalBaseUrl = localStorage.getItem('_usingLocalBaseUrl');
    
      if(_localBaseUrl && _localBaseUrl.length>1){
        if(_isUsingLocalBaseUrl && _isUsingLocalBaseUrl==="true"){
          HConfig.baseUrl = _localBaseUrl;
          if(window._ConfigApp){
            window._ConfigApp.baseUrl = _localBaseUrl;
          }
          else{
            if(HConfig.UIAppConfig){
              HConfig.UIAppConfig.baseUrl = _localBaseUrl
            }
          }
    
          //add warning
          var d1 = document.getElementById('root');
          if(d1){
            d1.insertAdjacentHTML('afterend', `<div id="warning-using-local-baseurl">API Local: ${_localBaseUrl}</div>`);
          }      
        }    
      }
      else{
        localStorage.setItem("_localBaseUrl","http://localhost:12391");
      }
    }
  },
  getIsDev(){
    if(window.location.href.startsWith('http://localhost')){
      return true;
    }
    else if(window.location.hostname.indexOf("dev")>-1){
      return true;
    }
    if(localStorage && localStorage.getItem('_DEBUG_')=="true"){
      return true;
    }
    return false;
  },
  getDevMode(){
    return Dev.getIsDev()?true:false;
  },
  setDevMode(value){
    localStorage.setItem('_DEBUG_',value==true?"true":"false");
  },
  getBaseUrl(){
    return localStorage.getItem('_localBaseUrl') || localStorage.getItem('_DEBUG_BaseURL_');
  },
  setBaseUrl(value){
    localStorage.setItem('_DEBUG_BaseURL_',value);
    localStorage.setItem('_localBaseUrl',value);
  },
  getIsUsingBaseUrl(){
    return localStorage.getItem('_usingLocalBaseUrl')=="true"?true:false;
  },
  setIsUsingBaseUrl(value){
    localStorage.setItem('_usingLocalBaseUrl',value);
  }
}
window.HDev = Dev;
export default Dev;