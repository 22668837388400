import React from 'react';
import { MyFormControlHelpers } from "@macashipo/mlib"
import MyLoading from '../MyLoading/MyLoading';

const MyDefault = React.lazy(() => import('./Types/_default'));
const Readonly = React.lazy(() => import('./Types/readonly'));
const Text = React.lazy(() => import('./Types/text'));
const Textarea = React.lazy(() => import('./Types/textarea'));
const Date = React.lazy(() => import('./Types/date'));
const Checkbox = React.lazy(() => import('./Types/checkbox'));
const Select = React.lazy(() => import('./Types/select'));
const Select2 = React.lazy(() => import('./Types/select2'));
const Select2color = React.lazy(() => import('./Types/select2color'));
const Rate = React.lazy(() => import('./Types/rate'));
const Selectlist = React.lazy(() => import('./Types/selectlist'));
const DatePicker = React.lazy(() => import('./Types/datepicker'));
const Currency = React.lazy(() => import('./Types/currency'));
const Quality = React.lazy(() => import('./Types/quality'));
const Password = React.lazy(() => import('./Types/password'));
const ListCheckbox = React.lazy(() => import('./Types/listcheckbox'));
const ListRadio = React.lazy(() => import('./Types/listradio'));
const Table = React.lazy(() => import('./Types/table'));
const SelectKanban = React.lazy(() => import('./Types/select_kanban'));
const HtmlEditor = React.lazy(() => import('./Types/html_editor'));
const Files = React.lazy(() => import('./Types/files'));
const Submit = React.lazy(() => import('./Types/submit'));
const Score = React.lazy(() => import('./Types/score'));
const Alert = React.lazy(() => import('./Types/alert'));
const GridRadio = React.lazy(() => import('./Types/gridradio'));
const GridCheckbox = React.lazy(() => import('./Types/gridcheckbox'));
const Linear = React.lazy(() => import('./Types/linear'));
const Select_kanban_working = React.lazy(() => import('./Types/select_kanban_working'));
const Segment = React.lazy(() => import('./Types/segment'));
const SelectIcon = React.lazy(() => import('./Types/select_icon'));
const DateTime = React.lazy(() => import('./Types/datetime'));
const MyPage = React.lazy(() => import('./Types/mypage'));
const Select2Box = React.lazy(() => import('./Types/select2box'));
const WithMyUI = React.lazy(() => import('./Types/with_myui'));
const WithMyUI_number_decimal = React.lazy(() => import('./Types/number_decimal'));
const WithMyUI_number_percent = React.lazy(() => import('./Types/number_percent'));

const MyFormTypes = {
  addType(key,fnComponent){
    if(MyFormTypes.Types[key]==null){
      MyFormTypes.Types[key] = fnComponent;
    }
  },
  Types:{
    _default(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <MyDefault config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    readonly(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Readonly config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    text(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Text config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    textarea(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Textarea config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    date(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Date config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    checkbox(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Checkbox config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    select(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Select config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    select2(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Select2 config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    select2color(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Select2color config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    rate(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Rate config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    selectlist(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Selectlist config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    datepicker(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <DatePicker config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },   
    currency(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Currency config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },    
    quality(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Quality config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },   
    password(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Password config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },  
    listcheckbox(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <ListCheckbox config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },  
    listradio(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <ListRadio config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },  
    table(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Table config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },  
    select_kanban(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <SelectKanban config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    html_editor(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <HtmlEditor config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    files(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Files config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    submit(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Submit config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    score(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Score config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    alert(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Alert config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    gridradio(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <GridRadio config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    gridcheckbox(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <GridCheckbox config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    linear(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Linear config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    select_kanban_working(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Select_kanban_working config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    segment(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Segment config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    select_icon(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <SelectIcon config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    datetime(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <DateTime config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    mypage(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <MyPage config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    select2box(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Select2Box config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    },
    with_myui(config,opts,moreProps){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <WithMyUI config={config} opts={opts} {...moreProps}/>
        </React.Suspense>
      )
    }
  }
}
window.MyFormTypes = MyFormTypes.Types;

//chua biet xu ly sao vi kieu truyen vao khac nhau
const List = {
  number_decimal: WithMyUI_number_decimal,
  number_percent: WithMyUI_number_percent
}

//add type from List với các type mới làm cho theo cấu trúc mới
if(Object.keys(List).length>0){
  for(let k of Object.keys(List)){
    if(MyFormTypes.Types[k]==null){
      MyFormTypes.Types[k] = function(config,opts,moreProps){
        const Tag = List[k]
        return(
          <React.Suspense fallback={<MyLoading />}>
            <Tag config={config} opts={opts} {...moreProps}/>
          </React.Suspense>
        )
      }
    }    
  }
}

MyFormControlHelpers.addTypesFromListLazy(List)
export default MyFormTypes;