import React from 'react'
import MyPageTypes from '../../../../components/MyPage/MyPageTypes';
import CSuppense from '../../../../components/MyCore/CSuspense';

const PageDESearch = React.lazy(() => import('./Types/de_search'));
const PageSolidv3Table= React.lazy(() => import('../../../solid_v3/components/MyPage/Types/solidv3_table'));
const PageDESearchForApi = React.lazy(() => import('./Types/de_search_for_api'));


const AppMyPage = {
    init(){
      MyPageTypes.addTypes('de_search',(props)=>{ return  CSuppense(props,PageDESearch) });
      MyPageTypes.addTypes('table',(props)=>{ return  CSuppense(props,PageSolidv3Table) });
      MyPageTypes.addTypes('de_search_for_api',(props)=>{ return  CSuppense(props,PageDESearchForApi) });
    }
  }
export default AppMyPage;