import React from 'react';
import { Button, Popover } from '../_import';
import Button_Loading from './button_loading';
class Button_Popover extends Button_Loading {
  componentDidMount(){
  }
  hidePopover=()=>{
    if(this._cPopover && this._cPopover.hide){
      this._cPopover.hide();
    }
  }
  render() {
    const {color,style,className,disabled,loading,overlay} = this.props;
    let _disabled = disabled;
    let _loading = this.state.isLoading || loading;
    if(_disabled!==true && _loading===true){
      _disabled = true;
    }
    return (
      <Popover ref={r=>{this._cPopover=r;}} overlay={overlay} trigger="click" placement="bottom">
        <Button color={color} style={style} className={className} disabled={_disabled} onClick={this.onClick}>
          {
            _loading===true && 
            <i className="fa fa-spinner fa-spin" style={{marginRight:'5px'}}/>
          }
          {this.props.title}
          {this.props.children}
        </Button>
      </Popover>
      
    )
  }
}

export default Button_Popover;