import MyFormTypes from './MyFormTypes';
import HConfig from '../../helpers/Config';
import HCompare from '../../helpers/Compare';

const MyFormHelpers = {
  Cached:{
    data:{},
    get(key){
      return MyFormHelpers.Cached.data[key];
    },
    clear(key){
      delete MyFormHelpers.Cached.data[key];
    },
    save(key,value){
      MyFormHelpers.Cached.data[key] = value;
    }
  },
  addType(key,fnComponent){
    if(MyFormTypes.Types[key]==null){
      MyFormTypes.Types[key] = fnComponent;
    }
  },  
  getStylesInFormFromConfig: function(config){
    if(config && config._StylesObj){
      return config._StylesObj;
    }
    let _style = {};
    if(config && config.Styles){
      if(typeof config.Styles == "object"){
        _style = Object.assign(_style,config.Styles);
        config._StylesObj = _style;
      }
      else if(typeof config.Styles == "string"){
        try {
          _style = Object.assign(_style,JSON.parse(config.Styles));
          config._StylesObj = _style;
        } catch (error) {
          console.warn('Parse Json Style error',config,error);
        }
      }
    }
    return _style;
  },
  getStylesInForm: function(item,opts){
    if(opts.configForm && opts.configForm._StylesObj){
      return opts.configForm._StylesObj;
    }
    let _style = MyFormHelpers.getStylesInFormFromConfig(opts.configForm);
    return _style;
  },
  getStylesInControl: function(item,opts){
    if(item && item._StylesObj){
      return item._StylesObj;
    }
    let _style = {};
    if(item.Styles){
      if(typeof item.Styles == "object"){
        _style = Object.assign(_style,item.Styles);
        item._StylesObj = _style;
      }
      else if(typeof item.Styles == "string"){
        try {
          _style = Object.assign(_style,JSON.parse(item.Styles));
          item._StylesObj = _style;
        } catch (error) {
          console.warn('Parse Json Style error',item,opts,error);
        }
      }
    }
    return _style;
  },
  getStyleOfLabel(item,opts){
    if(item._StyleLabel){
      return item._StyleLabel;
    }
    let _stylesForm = MyFormHelpers.getStylesInForm(item,opts);
    let _stylesControl = MyFormHelpers.getStylesInControl(item,opts);
    let _style = {};
    if(_stylesForm.Label){
      _style = Object.assign(_style,_stylesForm.Label);
    }
    if(_stylesControl.Label){
      _style = Object.assign(_style,_stylesControl.Label);
    }
    let _moreOfCell = MyFormHelpers.getMoreOfCell({configCell:item});
    if(_moreOfCell && _moreOfCell.styleLabel){
      _style = Object.assign(_style,_moreOfCell.styleLabel);
    }
    item._StyleLabel = _style;
    return _style;
  },
  getStyleOfControl(item,opts, defaultStyle){
    if(item._StyleControl){
      return item._StyleControl;
    }
    let _stylesForm = MyFormHelpers.getStylesInForm(item,opts);
    let _stylesControl = MyFormHelpers.getStylesInControl(item,opts);
    let _style = {};
    if(_stylesForm.Control){
      _style = Object.assign(_style,_stylesForm.Control);
    }
    if(_stylesControl.Control){
      _style = Object.assign(_style,_stylesControl.Control);
    }
    if (defaultStyle){
      _style = Object.assign(_style, defaultStyle);
    }
    item._StyleControl = _style;
    return _style;
  },
  getStyleOfCell(item,opts){
    if(item._StyleCell){
      return item._StyleCell;
    }
    let _stylesForm = MyFormHelpers.getStylesInForm(item,opts);
    let _stylesControl = MyFormHelpers.getStylesInControl(item,opts);
    let _style = {};
    if(_stylesForm.Cell){
      _style = Object.assign(_style,_stylesForm.Cell);
    }
    if(_stylesControl.Cell){
      _style = Object.assign(_style,_stylesControl.Cell);
    }
    item._StyleCell = _style;
    return _style;
  },
  getStyleOfForm(config){
    if(config._StyleForm){
      return config._StyleForm;
    }
    let _stylesForm = MyFormHelpers.getStylesInFormFromConfig(config);
    let _style = {};
    if(_stylesForm.Form){
      _style = Object.assign(_style,_stylesForm.Form);
    }
    config._StyleForm = _style;
    return _style;
  },
  getStyleOfFormContainer(config){
    if(config._StyleFormContainer){
      return config._StyleFormContainer;
    }
    let _stylesForm = MyFormHelpers.getStylesInFormFromConfig(config);
    let _style = {};
    if(_stylesForm.FormContainer){
      _style = Object.assign(_style,_stylesForm.FormContainer);    
    }
    else if(_stylesForm.Form){
      let _keys = Object.keys(_stylesForm.Form);
      // console.warn('_keys:',_keys)
      if(_keys && _keys.length>0){
        for(let k of _keys){
          if(k.startsWith('c_')){
            let _newK = k.replace('c_','');
            _style[_newK] = _stylesForm.Form[k];
          }
        }
      }      
    }
    config._StyleFormContainer = _style;
    return _style;
  },
  getStyleOfGroup(item,opts){
    if(item._StyleGroup){
      return item._StyleGroup;
    }
    let _stylesForm = MyFormHelpers.getStylesInForm(item,opts);
    let _stylesControl = MyFormHelpers.getStylesInControl(item,opts);
    let _style = {};
    if(_stylesForm){
      _style = Object.assign(_style,_stylesForm);
    }
    if(_stylesControl){
      _style = Object.assign(_style,_stylesControl);
    }
    item._StyleGroup = _style;
    return _style;
  },
  getDirectionOfControl(item,opts){
    if(item._StyleDirection){
      return item._StyleDirection;
    }
    let _stylesForm = MyFormHelpers.getStylesInForm(item,opts);
    let _stylesControl = MyFormHelpers.getStylesInControl(item,opts);
    let _direction = "row";
    if(_stylesForm.Direction){
      _direction = _stylesForm.Direction;
    }
    if(_stylesControl.Direction){
      _direction = _stylesControl.Direction;
    }
    item._StyleDirection = _direction;
    return _direction;
  },
  getClassDirection: function(item,opts){//Khong nen xai nua -> getClassDirectionOfCell
    let _direction = MyFormHelpers.getDirectionOfControl(item,opts);
    // console.warn("getClassDirection:",item,_direction);
    if(_direction=="row"){
      return `mf-d-row`;
    }
    return `mf-d-col`;
  },
  getClassDirectionOfCell({item,opts,props,df}={}){
    let _direction = MyFormHelpers.getDirectionOfControl(item,opts);
    let _props = props || MyFormHelpers.getPropsControl(item,opts);
    if(_props.directionCell){ _direction = _props.directionCell; }
    else{
      let _moreOfForm = MyFormHelpers.getMoreOfForm({opts});
      if(_moreOfForm.directionCell){ _direction = _moreOfForm.directionCell; }
    }    
    if(_direction=="row"){
      return `mf-d-row`;
    }
    return `mf-d-col`;
  },
  getClassOfCell: function({item,opts,props,df}={}){
    let _class = df || '';
    let _props = props || MyFormHelpers.getPropsControl(item,opts);
    if(_props.classCell){ return _props.classCell; }
    let _moreOfForm = MyFormHelpers.getMoreOfForm({opts});
    if(_moreOfForm.classCell){ return _moreOfForm.classCell; }
    return _class;
  },
  getRightLabel: function({item,opts,props,df}={}){
    let _props = props || MyFormHelpers.getPropsControl(item,opts);
    if(_props.rightLabel){ return _props.rightLabel; }
    return df;
  },
  getClassOfForm: function({configForm,df}={}){
    let _class = df || '';
    let _moreOfForm = MyFormHelpers.getMoreOfForm({configForm});
    if(_moreOfForm.classForm){ return _moreOfForm.classForm; }
    return _class;
  },
  getDefaultValue: function(item,opts){
    let _v;
    let _fieldName = item.FieldName;
    if(item.DefaultValues){
      _v = item.DefaultValues;
    }
    else if(opts && opts.defaultValues && opts.defaultValues[_fieldName]!=null){
      _v = opts.defaultValues[_fieldName];
    }
    // console.warn('getDefaultValue:',item,opts,_v);
    return _v;
  },
  getSourceGrid: function(item,opts){
    // console.warn('get _sourceGrid', item,opts);
    let _sourceGrid;
    if(item && item.SourceGrid){
      _sourceGrid = item.SourceGrid;
    }
    return _sourceGrid;
  },
  getOptionsSource: function(item,opts){
    // console.warn('getOptionsSource',item,opts);
    let _options = [];
    if(item.SourceList){
      _options = item.SourceList;
    }
    else if(item.Props && item.Props.sourceList){
      _options = item.Props.sourceList;
    }
    else if(item && item.Source!=null){
      // console.warn('x',item,opts);
      if(item.Source.indexOf(".")>-1){
        let _arr = item.Source.split(".");
        let _temp = [];
        let _valuesForm = {};
        if(opts && opts.getValues){
          _valuesForm = opts.getValues();
        }
        for(let i =0;i<_arr.length;i++){                   
          let _part = _arr[i];
          if(i==0){//Lay SourceList ban dau ra
            if(opts && opts.getOptionOfKey!=null){
              _temp = opts.getOptionOfKey(_part);
            }
          }
          else{//Sau do
            if(_part.indexOf("[")>-1){//Neu co dau []
              let _fieldGetValue = _part.replace("[","");
              _fieldGetValue = _fieldGetValue.replace("]","");//Lay ten field
              // console.warn("GetOptions:",item.Props,_valuesForm);
              if(_valuesForm && _valuesForm[_fieldGetValue]){//Lay value field                                
                if(_temp && Array.isArray(_temp) && _temp.length>0){
                  for(let _itemOfTemp of _temp){
                    if(_itemOfTemp.Value==_valuesForm[_fieldGetValue]){
                      _temp = _itemOfTemp;//Gan temp la object duoc chon cua parent
                      break;
                    }
                  }
                }
              }
              //Neu chua co du lieu va co Props.sourceWhenNoParent thi lay source sourceWhenNoParent tu Options
              else if(item.Props && item.Props.sourceWhenNoParent!=null && (_valuesForm[_fieldGetValue]==null||_valuesForm[_fieldGetValue]=="")){
                _temp = opts.getOptionOfKey(item.Props.sourceWhenNoParent);
                // console.warn("GetOptions 2:",_temp);
                break;
              }
              if(Array.isArray(_temp)){//Neu ko co gia tri temp la mang rong vi neu co gia tri thi _temp la object
                _temp = [];
                break;
              }
            }
            else{//Sau do, temp duoc gan voi field trong object duoc chon
              if(_temp && _temp[_part]){
                _temp = _temp[_part];
              }
            }
          }          
        }
        if(Array.isArray(_temp)){
          _options = _temp;
        }
      }
      else if(opts && opts.getOptionOfKey!=null){
        _options = opts.getOptionOfKey(item.Source);
      }            
    }
    else if(opts && opts.sourceLists){
      _options = opts.sourceLists[item.FieldName];
    }
    
    // console.warn('getOptionsSource result',_options);
    return _options;
  },
  getPropsControl: function(item,opts){
    let _props = {};
    if(item.Props){
      if(typeof item.Props == "object"){
        _props = Object.assign(_props,item.Props);
      }
      else if(typeof item.Props == "string"){
        try {
          _props = Object.assign(_props,JSON.parse(item.Props));
        } catch (error) {
          console.warn('Parse Json Props Control error',item,opts,error);
        }
      }
    }
    return _props;
  },
  getMoreOfForm({configForm,opts}={}){
    let _more = {};
    let _configForm = configForm;
    if(opts && opts.configForm){
      _configForm = opts.configForm;
    }
    if(_configForm && _configForm.More){
      if(typeof _configForm.More == "object"){
        _more = _configForm.More;
      }
      else if(typeof _configForm.More == "string"){
        try {
          _more = JSON.parse(_configForm.More);
        } catch (error) {
          console.warn('Parse Json Props Control error',opts,error);
        }
      }
    }
    return _more;
  },
  getMoreOfCell({configCell}={}){    
    let _more = {};
    let _moreTemp = null;
    if(configCell){
      if(configCell._MoreObj){
        return configCell._MoreObj;
      }
      if(configCell.Props){
        _moreTemp = configCell.Props;
      }
      else if(configCell.More){
        _moreTemp = configCell.More;
      }
      if(_moreTemp){
        if(typeof _moreTemp == "object"){
          _more = _moreTemp;
        }
        else if(typeof _moreTemp == "string"){
          try {
            _more = JSON.parse(_moreTemp);
          } catch (error) {
            console.warn('Parse Json Props Cell error',error);
          }
        }
      }
      configCell._MoreObj = _more;
    }    
    // console.warn("getMoreOfCell:",_more,configCell);
    return _more;
  },
  getDateFormat({item,opts,props,df}={}){
    let _props = props || MyFormHelpers.getPropsControl(item,opts);
    let _format = df || HConfig.dateFormat;
    if(_props.dateFormat){
      _format = _props.dateFormat;
    }
    else if(_props.format){
      _format = _props.format;
    }
    return _format;
  },
  getHintOfCell({item,opts,props,df}={}){
    let _props = props || MyFormHelpers.getPropsControl(item,opts);
    let _hint = df;
    if(_props.hint){
      _hint = _props.hint;
    }
    else if(item.Hint){
      _hint = item.Hint;
    }
    return _hint;
  },
  getSubLabelOfCell({item,opts,props,df}={}){
    let _props = props || MyFormHelpers.getPropsControl(item,opts);
    let _subLabel = df;
    if(_props.sublabel){
      _subLabel = _props.sublabel;
    }
    return _subLabel;
  },
  getCanShowCell({item,opts,props,cForm,df}={}){
    let _props = props || MyFormHelpers.getPropsControl(item,opts);
    let _show = true;
    if(_props.hide==true){
      return false;
    }
    if(_props.hideCondition && cForm && cForm.getValuesWithDefaultValues){      
      let _valuesWithDefaultValues = cForm.getValuesWithDefaultValues();
      // console.warn("getCanShowCell hideCondition:",_props,_valuesWithDefaultValues);
      let _result = HCompare.isMatch("",_props.hideCondition,_valuesWithDefaultValues);
      if(_result==true){
        return false;
      }
    }        
    return _show;
  },
}

export default MyFormHelpers;